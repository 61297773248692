<template>
  <div style="height: 100%; display; relative">
    <v-card
      outlined
      class="admin_bottom_main_v_card"
      style="height: 93%; overflow-y: auto; padding: 100px 80px 60px; 80px;"
    >
      <v-form>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          dense
          filled
          rounded
          id=""
          style="min-width: 150px; max-width: 151px"
        ></v-text-field>
      </v-form>

      <div
        v-if="!modify_btn_group_exist"
        style="position: absolute; right: 40px; top: 30px"
      >
        <v-btn
          v-if="!modify_btn"
          icon
          color="#5a7fff"
          @click="(modify_btn = !modify_btn), personal_leave_settings_before_values()"
          class="mr-5"
        >
          취소
        </v-btn>
        <v-btn
          v-if="modify_btn"
          color="#5a7fff"
          @click="modify_btn = !modify_btn"
          style="font-size: 14px; font-family: NotoSansBold; color: #ffffff; width: 165px; height: 40px;"
        >
          연차 수정 하기
        </v-btn>
        <v-btn
          v-else
          color="#5a7fff"
          :disabled="save_button_flag"
          @click="(modify_btn = !modify_btn), request_save_user_all_leave()"
          style="font-size: 14px; font-family: NotoSansBold; color: #ffffff; width: 165px; height: 40px;"
        >저장
        </v-btn>
      </div>
      <v-data-table
        hide-default-footer
        :loading="loading"
        :headers="headers"
        :items="this.items_table"
        :search="search"
        :footer-props="{
          itemsPerPageOptions: [-1],
        }"
        disable-sort
        :item-class="return_someting(admin_bottom_row)"
        :no-data-text="no_data_text"
        @click:row="onUserClick"
      >
        <template v-slot:item.annualLeaveCnt="{ item }">
          <div v-if="modify_btn">
            {{ item.annualLeaveCnt }}
          </div>
          <v-text-field
            v-else
            v-model="item.annualLeaveCnt"
            flat
            background-color="#ededed"
            class="admin_row_item"
            hide-details="true"
          ></v-text-field>
        </template>

        <template v-slot:item.delete="{ item }">
          <v-switch
            v-model="item.delete"
            disabled="true"
          />
        </template>
        <!-- <template v-slot:item.usedLeaveCnt="{ item }">
          <div v-if="modify_btn">
            {{ item.usedLeaveCnt }}
          </div>
          <v-text-field
            v-else
            v-model="item.usedLeaveCnt"
            flat
            :rules="[(v) => used_leave_cnt_rule(v, item.annualLeaveCnt) || '잘못된 값']"
            background-color="#ededed"
            class="admin_row_item"
          ></v-text-field>
        </template> -->
        <!-- <template v-slot:item.leftLeaveCnt="{ item }">
          {{ item.leftLeaveCnt }}
        </template> -->
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card
        style="padding: 20px"
      >
        <v-card-title class="text-h5">
          사용자 정보 수정
        </v-card-title>
        <v-text-field
          v-model="editUserName"
          :rules="[rules.required]"
        />
        <v-text-field
          v-model="editUserMac"
          :rules="[rules.macAddress]"
        />
        <v-switch
          v-model="editDeleted"
          :label="`삭제여부`"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            취소
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="applyUserMacUpdate"
          >
            저장
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
@media (max-width: 600px) {
  .admin_bottom_main_v_card {
    padding: 100px 30px 30px 30px !important;
  }
}

/* :rules="[(v)=> parseInt(v) <= parseInt(item.annualLeaveCnt) || ('오오류류')]" */
/* admin bottom v-data-table text-field 영역 밑 가운데 정렬 시작*/
.admin_row_item {
  max-width: 50px;
  margin: 0 auto;
  transform: translateY(-5px);
}

.admin_row_item input {
  text-align: center !important;
}
/* 끝*/
</style>

<script>
// TODO
// 1. '연차 수정 하기' 버튼을 눌렀을때 '총 연차일수'를 v-text-field로 바꾸었으나, '저장' 버튼을 눌렀을때
// 동작하는 로직을 아직 만들어두지 않아서, 해당 action을 하나 추가해주어야 한다.
import Vue from "vue";
import Axios from "axios";

export default {
  props: ["items"],
  data() {
    return {
      search: "",
      dialog: false,
      editUserName: null,
      editUserIndex: null,
      editUserMac: null,
      editMacErrorMessage: null,
      editDeleted: null,
      modify_btn_group_exist: Vue.prototype.$adminDialog,
      modify_btn: true,
      url: Vue.prototype.$serverUrl,
      headers: [
        { text: "이름", value: "name", align: "center", width: "25%" },
        { text: "총 연차 일수", value: "annualLeaveCnt", align: "center", width: "25%" },
        { text: "사용 연차", value: "usedLeaveCnt", align: "center", width: "25%" },
        { text: "남은 연차", value: "leftLeaveCnt", align: "center", width: "25%" },
        { text: "기기주소", value: "mac", align: "center", width: "25%" },
        { text: "삭제여부", value: "delete", align: "center", width: "25%" },
      ],
      items_table: [],
      temp_items: [],
      loading: false,
      update_flag: false,
      save_button_flag: false,
      no_data_text: "관리자 로그인시 확인 가능합니다.",
      rules: {
        required: value => value.length > 0 || "값을 입력해주세요",
        macAddress: value => {
          const macSplit = value.split(":")
          const macFieldNot2 = macSplit.find(it => it.length != 2)
          if( macSplit.length == 6 && macFieldNot2 == null){
            return true;
          }
          else {
            return 'Mac 형식이 올바르지 않습니다';
          }
        },
      },
    }
  },
  methods: {

    used_leave_cnt_rule(v, i) {
      if (v <= i) {
        this.save_button_flag = false;
        return true;
      } else {
        this.save_button_flag = true;
        console.log("updated");
        return false;
      }
    },
    onUserClick(event, {item}) {
      if(!this.modify_btn){
        return;
      }
      this.editUserName = item.name;
      this.editUserIndex = item.index;
      this.editUserMac = item.mac;
      this.editDeleted = item.delete;
      this.dialog = true;
    },
    applyUserMacUpdate() {
      const index = this.editUserIndex;
      const mac = this.editUserMac;
      const name = this.editUserName;
      const deleted = this.editDeleted;
      if( this.rules.macAddress(mac) === true && index != null){
        this.editUserName = null;
        this.editUserIndex = null;
        this.editUserMac = null
        this.editDeleted = null
        this.dialog = false;
        this.requestUserMacUpdate(index, mac, name, deleted);
      }
    },
    requestUserMacUpdate(index, mac, name, deleted) {
      this.loading = true;
      Axios({
        method: "post",
        url: this.$getBlueDogUrl() + `/updateUser?index=${index}&mac=${mac}&name=${name}&delete=${deleted}`,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        this.loading = false;
        this.getUserList();
        // 저장 버튼을 누르면, 부모에게 변화된 데이터값을 전한다.
        // this.emit_to_parent("changed_data_arr", JSON.parse(JSON.stringify(response.data)))
        // console.log("response: ",response)
        // this.items_table = JSON.parse(JSON.stringify(response.data));
        // this.temp_items = JSON.parse(JSON.stringify(response.data));
        // this.loading = false;

      }).catch(function (error) {
        console.log("error : ", error);
        this.loading = false;
        this.getUserList();
      });
    },
    request_save_user_all_leave() {
      this.loading = true;
      const json = JSON.stringify(this.items_table);
      Axios({
        method: "post",
        url: this.$getBlueDogUrl() + "/requestSaveSetting_all_user",
        data: { json },
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      }).then((response) => {
        // 저장 버튼을 누르면, 부모에게 변화된 데이터값을 전한다.
        this.emit_to_parent("changed_data_arr", JSON.parse(JSON.stringify(response.data)))
        console.log("response: ",response)
        this.items_table = JSON.parse(JSON.stringify(response.data));
        this.temp_items = JSON.parse(JSON.stringify(response.data));
        this.loading = false;
      }).catch(function (error) {
        console.log("error : ", error);
        this.loading = false;
      });
    },
    getUserList: function () {
      Axios.get(this.$getBlueDogUrl() + '/getUserList').then((response) => {
        this.items = response.data
      })
    },
    personal_leave_settings_before_values() {
      this.items_table = JSON.parse(JSON.stringify(this.temp_items));
    },
    return_someting(something) {
      return something;
    },
    admin_bottom_row() {
      return "admin_bottom_row";
    },
    emit_to_parent(name, value) {
      this.$emit(name, value);
    },
  },
  // vue 생명주기에서 데이터가 새로 들어올때 실행된다.
  updated() {

  },

  mounted() {
    let arr = this.items;
    this.items_table = JSON.parse(JSON.stringify(arr));
    this.temp_items = JSON.parse(JSON.stringify(arr));
  },

  watch: {
    items: {
      handler(after, before){
        // desktop 모드에서는 `bottomCard` 부분까지 보임으로, mounted가 선언이 될때
        // 데이터를 제대로 받아오지 못하게 된다. 따라서, 처음에 desktop mode로 볼떄를 가정하여
        // watch로 데이터가 들어오는것을 확인하여 처리하여 준다.
        let arr = this.items;
        this.items_table = JSON.parse(JSON.stringify(arr));
        this.temp_items = JSON.parse(JSON.stringify(arr));
        this.modify_btn_group_exist = false
      }
    }
  },
};
</script>

<style></style>
