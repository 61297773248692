<template>
    <div style="display: flex; flex-flow: column;">
      <div style="line-height: 100%; margin-bottom: 15px; font-family: NotoSansBold">
          {{kindof_title}}
      </div>
      <div style="display: flex; align-items: center; flex-wrap: wrap;">
        <div style="font-family: NotoSansBold; font-size: 26px; margin-right: 10px;">
          {{main_data}}
        </div>
        <div>
          {{aver_data}}
        </div>
      </div>
      <div :style="`color:`+compare_color+';font-family: NotoSansBold; line-height: 100%; margin-top: 7px;'">
          {{compare_data}}
      </div>
    </div>
</template>

<script>
export default {
    props: ['kindof_title', 'main_data', 'aver_data', 'compare_data', 'compare_color'],
    data() {
        return {
            
        }
    },
    
}
</script>

<style>

</style>