
export default {
  install (Vue, options) {
    const methods = {
      // global css에서 선언한 --** 색상 값을 가져옴
      getCSSVar (color) {
        return getComputedStyle(document.documentElement).getPropertyValue(color)
      },
      getBlueDogUrl(){
        console.log(window.location.pathname);
        console.log(window.location.pathname.length);
        if (window.location.pathname.length > 1)  {
          return process.env.VUE_APP_URL + window.location.pathname.substring(0, window.location.pathname.length-1);
        } else {
          return process.env.VUE_APP_URL;
        }
      }
    }
    Vue.prototype.$getCSSVar = methods.getCSSVar
    Vue.prototype.$adminDialog = true
    Vue.prototype.$serverAccessFailed = "서버와의 연결에 실패했습니다."
    Vue.prototype.$serverAccessFailedTableNoResultText = "서버에서 데이터를 제대로 받아오지 못했습니다."
    Vue.prototype.$loadingTextDefault = "잠시만 기다려주세요."
    Vue.prototype.$urlYmdFormat = function (date) {
      const dateWhen =
                date.getFullYear().toString() +
                (date.getMonth() + 1).toString().padStart(2, '0') +
                date.getDate().toString().padStart(2, '0')
      return dateWhen
    }
    Vue.prototype.$getBlueDogUrl = methods.getBlueDogUrl;
  }
}
