<template>
  <v-container 
    class="pa-0"
    style="height: 100%;"
  >
      <v-row
        no-gutters
        style="height: 9%;"
      >
        <v-tabs
            id="vTabs_mobile_top_tabs"
            v-model="model"
            height="100%"
            :slider-size="2"
            background-color="#f6f7fa"
            slider-color="#3b46ff"
            color="#3b46ff"
        >
            <v-tab
                v-for="(title, index) in title_arr"
                :key="index"
                :href="`#dash-m-`+index"
                class="pa-0"
            >
                <div>
                {{title}}

                </div>
            </v-tab>
        </v-tabs>
      </v-row>
      <v-row
        no-gutters
        style="height: 91%; width: 100%;"
      >
          <v-card outlined style="width: 100%; height: 100%;" >
            <v-tabs-items
                v-model="model"
                style="height: 100%;"
            >
                <v-tab-item value="dash-m-0">
                    <dash-board-donut-bar-year-chart
                        :donut_items="day_chart_data"
                        :donut_updateTime="day_chart_sync_time"
                        :bar_data="month_chart_data" 
                        :bar_windowWidth="window_width"
                        :progress_yeardata="year_chart_data"
                        :loading="loading"
                    />
                </v-tab-item>
                <v-tab-item value="dash-m-1">
                    <dash-board-live-guentae 
                        :liveGuentae="live_guentae"
                        :loading="loading"
                        :error="error"
                        :windowWidth="window_width"
                        />
                </v-tab-item>
            </v-tabs-items>
          </v-card>
      </v-row>
  </v-container>
</template>

<script>
import DashBoardDonutBarYearChart from './DashBoardDonutBarYearChart.vue'
import DashBoardLiveGuentae from './DashBoardLiveGuentae.vue'

export default {
    components: {DashBoardDonutBarYearChart, DashBoardLiveGuentae},
    props:['day_chart_data', 'day_chart_sync_time', 'month_chart_data', 'window_width', 'year_chart_data', 'live_guentae', 'loading', 'error', 'loading'],
    data() {
        return {
            model: null,
            title_arr: ['근무 현황', '실시간 근태']
        }
    },
    mounted() {
    },
    watch:{
    }
}
</script>

<style>
#vTabs_mobile_top_tabs .v-tab:not(.v-tab--active) > div{
  color: black;
}
</style>