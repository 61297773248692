<template>
  <v-container
   class="pa-0"
   fluid
  >
    <alert-api
      :accessed="error_flag"
    ></alert-api>
    <v-row
      style="padding: 30px 0 20px;"
      no-gutters
    >
      <div
        style="display: flex; align-items: center; flex-wrap: wrap; max-width: 450px; width: 100%; justify-content: space-between;"
      >
        <div style="font-family: NotoSansBold; font-size: 26px; line-height: 100%;">
          {{topTitle}}
        </div>
        <div
          style="display: flex; align-items: center;"
        >
          <div
            style="width: 25px; height: 25px; margin-right: 10px;"
          >
            <img
            src="/asset_day/calendar-line-blue.svg"
            style="width: 25px; height: 25px;"/>
          </div>
          <v-btn
            icon
            v-on:click="sub_year(date)"
          >
            <img src="/asset_day/arrow-left-s-line.svg" />
          </v-btn>
          <v-select
            :items="items"
            v-model="dateText"
            class="select_year"
            hide-details
          >
          </v-select>
          <v-btn
            icon
            v-on:click="add_year(date)"
          >
            <img src="/asset_day/arrow-right-s-line.svg" />
          </v-btn>
        </div>
      </div>
      <v-switch
        v-model="showDeleted"
        :label="`삭제 사용자 보기`"
      />
    </v-row>
    <v-row
      no-gutters
    >
      <statistics-gantt-year-table @error_flag="get_child_data" :selectedDate="date" :show-deleted="showDeleted" />
    </v-row>
  </v-container>
</template>

<style>
.select_year{
  padding: 0;
  margin: 0;
}

.select_year .v-input__append-inner {
  display: none;
}

.select_year .v-select__selections div {
  min-width: 90px;
  padding: 0;
  text-align: center;
  transform: translateY(-9%);
}

.select_year .v-select__selections input {
  display: none;
}

.v-select-list {
  min-width: 200px;
}

.select_year .v-input__slot::after {
  display: none;
}
</style>

<script>
import AlertApi from '../customapi/alertApi.vue';
import StatisticsGanttYearTable from "./statisticsGanttYearTable.vue";

export default {
  components: { StatisticsGanttYearTable, AlertApi },

  props: ["title"],
  data() {
    return {
      topTitle: this.title,
      date: new Date(),
      showDeleted: false,
      dateText: "",
      menu: false,
      items: [],
      handler_count: 0,
      error_flag: false
    };
  },
  methods: {
    get_child_data(child_data){
      this.error_flag = child_data
    },
    // 5, 15 의미 없는 숫자(달의 중간쯤을 표시)
    add_year(date) {
      this.dateText = date.getFullYear() + 1 + "년";
    },
    sub_year(date) {
      this.dateText = date.getFullYear() - 1 + "년";
    },
    fill_with_years(date) {
      this.dateText = date.getFullYear() + "년";
      this.items = [];

      let temp = new Date(date.getFullYear() - 15, 5, 15);

      for (let i = 0; i < 30; i++) {
        temp = new Date(temp.getFullYear() + 1, 5, 15);
        this.items.push(temp.getFullYear() + "년");
      }

    },
  },
  mounted() {
    this.fill_with_years(this.date);
  },
  watch: {
    // v-select에 값을 표기하기 위해 mounted로 dateText를 업로드하면서
    // 그것을 보고 있는 watch가 this.date = .. 로 date를 변경하게 되니
    // 자식 component 에서 prop을 보고 있다 변경되어 axios를 호출하게 되어
    // 결국 2번 호출하게 되는 불상사가 생기게 되었다.
    // 그래서 아래와같이 조건을 두어 처음에는 변경되지 않게끔 한다.
    dateText: {
      handler() {
        if (this.handler_count != 0) {
          this.date = new Date(this.dateText.substr(0, 4), 5, 15);
        }
        this.handler_count++;
      },
    },
    topTitle: {
      handler() {
        topTitle = this.title;
      },
    },
  },
};
</script>

<style>
</style>
