<template>
  <div class="dash-board-live-guentae-div-wrapper" style="height: 100%; padding: 30px 25px 0 35px">
    <v-text-field
      v-model="search"
      label="직원 이름"
      single-line
      filled
      dense
      rounded
      hide-details
      height="10%"
      style="margin-bottom: 1%;"
    >
      <template v-slot:append>
        <v-fade-transition leave-absolute>
          <v-progress-circular
            v-if="loading"
            size="24"
            color="info"
            indeterminate
          ></v-progress-circular>
          <v-img
            v-else
            width="24"
            height="24"
            src="/basic_icons/search-line.svg"
          />
        </v-fade-transition>
      </template>
    </v-text-field>

    <v-data-table
      v-if="windowWidth>1612"
      id="liveGuentae"
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="search"
      hide-default-footer hide-default-header
      :footer-props="{
        itemsPerPageOptions: [-1],
      }"
      :no-data-text="no_data_text"
      style="height: 85%; overflow-y: auto;"
    >
      <template v-slot:item.name="{ item }">
        <div style="position: relative">
          <div
            style="
              display: inline-block;
              vertical-align: middle;
              position: absolute;
              left: 10px;
              transform: translateY(-35%);
            "
          >
            <img :src="item.state" />
          </div>
          <div
            style="
              display: inline-block;
              vertical-align: middle;
              position: absolute;
              left: 80px;
              transform: translateY(-25%);
            "
          >
            {{ item.name }}
          </div>
          <div
            style="
              display: inline-block;
              vertical-align: middle;
              position: absolute;
              right: 10px;
              transform: translateY(-25%);
            "
          >
            {{ item.endTime }}
          </div>
        </div>
      </template>
    </v-data-table>

    <v-data-table
      v-if="windowWidth<=1612 && windowWidth>600"
      id="liveGuentae"
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="search"
      hide-default-footer hide-default-header
      :footer-props="{
        itemsPerPageOptions: [-1],
      }"
      :no-data-text="no_data_text"
      style="height: 75%; overflow-y: auto;"
    >
      <template v-slot:item.name="{ item }">
        <div style="position: relative">
          <div
            style="
              display: inline-block;
              vertical-align: middle;
              position: absolute;
              left: 10px;
              transform: translateY(-35%);
            "
          >
            <img :src="item.state" />
          </div>
          <div
            style="
              display: inline-block;
              vertical-align: middle;
              position: absolute;
              left: 80px;
              transform: translateY(-25%);
            "
          >
            {{ item.name }}
          </div>
          <div
            style="
              display: inline-block;
              vertical-align: middle;
              position: absolute;
              right: 10px;
              transform: translateY(-25%);
            "
          >
            {{ item.endTime }}
          </div>
        </div>
      </template>
    </v-data-table>
    <v-data-table
      v-if="windowWidth<=600"
      id="liveGuentae"
      mobile-breakpoint="0"
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="search"
      hide-default-footer hide-default-header
      :footer-props="{
        itemsPerPageOptions: [-1],
      }"
      :no-data-text="no_data_text"
      style="height: 75%; overflow-y: auto;"
    >
      <template v-slot:item.name="{ item }">
        <div style="position: relative">
          <div
            style="
              display: inline-block;
              vertical-align: middle;
              position: absolute;
              left: 10px;
              transform: translateY(-35%);
            "
          >
            <img :src="item.state" />
          </div>
          <div
            style="
              display: inline-block;
              vertical-align: middle;
              position: absolute;
              left: 80px;
              transform: translateY(-25%);
            "
          >
            {{ item.name }}
          </div>
          <div
            style="
              display: inline-block;
              vertical-align: middle;
              position: absolute;
              right: 10px;
              transform: translateY(-25%);
            "
          >
            {{ item.endTime }}
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  props: ['liveGuentae', 'loading', 'error', 'windowWidth'],
  data () {
    return {
      search: '',
      items: [],
      headers: [
        {
          text: '',
          value: 'name',
          align: 'center'
        }
      ],
      no_data_text: "",
    }
  },
  watch: {
    liveGuentae: {
      handler () {
        // for (let i = 0; i < this.liveGuentae.length; i++) {
        //   this.liveGuentae[i].state = this.changeStateLogo(this.liveGuentae[i].state)
        //   this.liveGuentae[i].endTime = this.calc_time(this.liveGuentae[i].endTime)
        //   console.log(JSON.parse(JSON.stringify(this.liveGuentae[i])))
        //   this.items.push(JSON.parse(JSON.stringify(this.liveGuentae[i])) )
        // }
        for (let i = 0; i < this.liveGuentae.length; i++) {
          var json_data={}
          json_data.state = this.changeStateLogo(this.liveGuentae[i].state)
          json_data.endTime = this.calc_time(this.liveGuentae[i].endTime)
          json_data.name = this.liveGuentae[i].name
          this.items.push(json_data)
        }
      }
    },
    error: {
      handler(){
        if(this.error){
          this.no_data_text = Vue.prototype.$serverAccessFailedTableNoResultText
        }else{
          this.no_data_text = ""
        }
      }
    }
  },
  mounted() {
    if(this.liveGuentae !== null){
      for (let i = 0; i < this.liveGuentae.length; i++) {
        var json_data={}
        json_data.state = this.changeStateLogo(this.liveGuentae[i].state)
        json_data.endTime = this.calc_time(this.liveGuentae[i].endTime)
        json_data.name = this.liveGuentae[i].name
        this.items.push(json_data)
      }
    }
  },
  methods: {
    changeStateLogo (str) {
      switch (str) {
        case 'commute':
          return '/icon_guentae_state/state-working.svg'
        case 'noseat':
          return '/icon_guentae_state/state-empty-seat.svg'
        case 'overwork':
          return '/icon_guentae_state/state-over-working.svg'
        case 'tardiness':
          return '/icon_guentae_state/state-working-lage.svg'
        case 'outsidework':
          return '/icon_guentae_state/state-out-working.svg'
        case 'leave':
          return '/icon_guentae_state/state-vacation.svg'
        case 'absent':
          return '/icon_guentae_state/state-absent.svg'
        default:
          return '/icon_guentae_state/state-time-togo-home.svg'
      }
    },
    calc_time (_time) {
      if (_time == null || _time == 0) {
        return '-'
      }
      const today = new Date(_time)
      const apm = today.getHours() < 12 ? '오전' : '오후'
      let hour = (hour = today.getHours() % 12) ? hour : 12
      const minute = today.getMinutes().toString().padStart(2, '0')
      return apm + ' ' + hour + ':' + minute
    }
  }
}
</script>

<style>

@media (max-width: 600px) {
  .dash-board-live-guentae-div-wrapper{
    padding: 15px 15px 0 !important;
  }
}

#liveGuentae tr > td {
  border: none;
}
</style>
