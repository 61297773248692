<template>
  <v-container fluid pa-0>
    <v-row
      no-gutters
    >
      <v-col 
        v-if="windowWidth>960"
        class="pa-0"
      >
        <div
          class="pl-25 pb-6 pt-10"
          style="font-size: 26px; font-weight: bold"
        >
          오늘 일정
        </div>
      </v-col>
    </v-row>
    <v-row
      no-gutters
    >
      <v-col class="pa-0" id="schedule_col_1">
        <v-card
          v-if="windowWidth>960"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="date"
            :loading="loading"
            class="elevation-0 text-center px-10"
            id="schedule"
            hide-default-footer
            :elevation="0"
            style="font-size: 25px;"
            :no-data-text="alertFlag == true? no_data_text_err : no_data_text"
            :loading-text="loading_text"
          >
            <template v-slot:item.time="{ item }">
              <div 
                v-if="time_format(item.start, item.end).allday != null"
              >
                {{time_format(item.start, item.end).allday}}
              </div>
              <div
                v-else
                style="display: flex; flex-wrap: wrap; align-items: center; max-width: 180px; margin: 0 auto;"
              >
                <div>{{time_format(item.start, item.end).start_time}}</div>
                <div>~</div>
                <div>{{time_format(item.start, item.end).end_time}}</div>
              </div>
            </template>
            <template v-slot:item.title="{ item }">
              <span style="font-weight: bold">{{ item.title }}</span>
            </template>
            <template v-slot:item.place="{ item }">
              <span style="font-weight: bold">{{ item.place }}</span>
            </template>
            <template v-slot:item.memo="{ item }">
              <span style="font-weight: bold">{{ item.memo }}</span>
            </template>
          </v-data-table>
        </v-card>
        <!-- 데스크탑 -->

        <!-- 모바일 -->
        <v-card
          v-if="windowWidth<=960"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="date"
            :loading="loading"
            class="elevation-0 text-center px-5"
            id="schedule"
            hide-default-footer
            mobile-breakpoint="960"
            :style="`font-size: 25px; min-height:`+windowHeight+`px;`"
            :no-data-text="alertFlag==true?no_data_text_err:no_data_text"
            :loading-text="loading_text"
          >
            <template v-slot:item.time="{ item }">
              <div 
                v-if="time_format(item.start, item.end).allday != null"
                style="font-family: NotoSansBold;"
              >
                {{time_format(item.start, item.end).allday}}
              </div>
              <div
                v-else
                style="display: flex; flex-wrap: wrap; align-items: center; max-width: 180px; justify-content: flex-end;"
              >
                <div style="font-family: NotoSansBold;">{{time_format(item.start, item.end).start_time}}</div>
                <div style="font-family: NotoSansBold;">~</div>
                <div style="font-family: NotoSansBold;">{{time_format(item.start, item.end).end_time}}</div>
              </div>
            </template>
            <template v-slot:item.title="{ item }">
              <span style="font-weight: bold">{{ item.title }}</span>
            </template>
            <template v-slot:item.place="{ item }">
              <span style="font-weight: bold">{{ item.place }}</span>
            </template>
            <template v-slot:item.memo="{ item }">
              <span style="font-weight: bold">{{ item.memo }}</span>
            </template>
          </v-data-table>
        </v-card>
        <!-- v-datatable row 클릭시 이벤트 동작 -->
        <!-- @click:row="showAlert"

        <v-menu
          v-model="dialog"
          :close-on-content-click="true"
          content-class="dayExplain"
        >
          <v-card
            style="width: 500px; height: 350px; border-radius: 15px;"
            flat
            outlined
          >
            <div style="position:relative; padding: 30px 0 50px 41px; size: 16px; font-weight: bold;">
              <div
                style="display:inline-block; ">
                상세 내용
              </div>
              <v-btn icon text @click="dialog = false"
                style="position: absolute; right: 30px; top: 32px; width: 13px; height: 13px;">
                <img src="/asset_day/close.svg"/>
              </v-btn>
              <v-btn icon text @click="dialog = false"
                style="position: absolute; right: 50px; top: 295px; width: 13px; height: 13px;"> 
              </v-btn>
            </div>

            <div id="div_title">
              <span>{{ attrs.title}}</span>
            </div>

            <v-card-text id="vCardText">
              <span id="span_explain_title">시작 시간</span>
              <span id="span_content" v-if="dialog">{{ explain_start_end_parsing(attrs.start, attrs.end)[0] }}</span>
            </v-card-text>

            <v-card-text id="vCardText">
              <span id="span_explain_title">종료 시간</span>
              <span id="span_content" v-if="dialog">{{ explain_start_end_parsing(attrs.start, attrs.end)[1] }}</span>
            </v-card-text>

            <v-card-text id="vCardText">
              <span id="span_explain_title_extra">장소</span>
              <span id="span_content">{{ attrs.place }}</span>
            </v-card-text>

            <v-card-text id="vCardText">
              <span id="span_explain_title_extra">메모</span>
              <span id="span_content">{{ attrs.memo }}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-menu> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
#vCardText {
  padding: 0;
  padding-bottom: 22px;
}

#div_title {
  width: 430px;
  height: 52px;
  size: 15px;
  color: black;
  padding: 0 40px 30px 40px;
}

#span_explain_title {
  width: 59px;
  height: 22px;
  font-weight: bold;
  color: black;
  font-size: 15px;
  padding: 0 28px 0 40px;
}

#span_explain_title_extra {
  width: 59px;
  height: 22px;
  font-weight: bold;
  color: black;
  font-size: 15px;
  padding: 0 62px 0 40px;
}

#span_content {
  width: 300px;
  height: 22px;
  color: black;
  font-size: 15px;

}

@media (max-width: 960px) {
  #schedule tbody td:last-child{
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  } 
}

#schedule_col_1 table thead th {
  padding: 20px 0 20px;
  font-size: 16px;
  font-family: NotoSans;
  border-bottom: 1px solid #979797;
}

#schedule_col_1 table tbody tr:first-child td {
  padding-top: 24px;
  padding-bottom: 12px;
}

#schedule_col_1 table tbody td:first-child {
  font-size: 15px;
  font-family: NotoSansBold;
  padding-top: 12px;
  padding-bottom: 12px;
}

#schedule_col_1 table tbody td {
  font-size: 15px;
  font-family: NotoSans !important;
  border-bottom: none;
  padding-top: 12px;
  padding-bottom: 12px;
}

/* 아래와 같이 주지 않으면, font-family가 적용되지 않는다.
   그 이유는 아무래도 custom을 시도할때 span태그로 감싸두어서 그런듯 하다. */
#schedule_col_1 table tbody td span{
  font-weight: normal !important;
}

#schedule_col_1 table tbody td:first-child span{
  font-size: 15px;
  font-family: NotoSansBold;
  padding-top: 12px;
  padding-bottom: 12px;
}
/*Scroll Bar
   width */
#schedule_col_1 > div::-webkit-scrollbar {
  width: 5px;
}

/* Track */
#schedule_col_1 > div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
#schedule_col_1 > div::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 5px;
}

.dayExplain{
  left: 65% !important;
  top: 70% !important;
  border-radius: 15px;
}
</style>

<script>
import Vue from "vue"

export default {
  props: ['date', 'loading', 'alertFlag', 'windowWidth'],
  data () {
    return {
      loading_text: '잠시만 기다려주세요.',
      dialog: false,
      attrs: {},
      headers: [
        { text: '시간', value: 'time', align: 'center', width: '15%' },
        { text: '제목', value: 'title', align: 'center', width: '35%' },
        { text: '장소', value: 'place', align: 'center', width: '20%' },
        { text: '메모', value: 'memo', align: 'center', width: '30%' }
      ],
      no_data_text_err: Vue.prototype.$serverAccessFailedTableNoResultText,
      no_data_text: "오늘은 일정이 없습니다.",
      windowHeight: window.innerHeight - 174
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize(){
      this.windowHeight = window.innerHeight - 174
    },
    time_format (start, end) {
      let st = new Date(start)
      let en = new Date(end)

      let data_type = {
        start_time: null,
        end_time: null,
        allday: null,
      }

      if (en - st < 86400000) {
        data_type.start_time = this.calc_time(start)
        data_type.end_time = this.calc_time(end)
        return data_type
      } else if (en - st == 86400000) {
        data_type.allday = "종일"
        return data_type
      } else {
        // 1초(1000)을 빼주는 이유는 캘린더 api의 문제이다. 만약 종일로 설정해두면
        // 데이터를 받아들일때 당일 12시 즉, 다음날로 넘어가기 떄문에 임의로 1초를 빼준다.
        en = new Date(en - 1000)
        data_type.start_time = this.date_parsing_with_dot(st)
        data_type.end_time = this.date_parsing_with_dot(en)
        return data_type
      }
    },
    calc_time (_time) {
      const today = new Date(_time)
      const apm = today.getHours() < 12 ? '오전' : '오후'
      let hour = (hour = today.getHours() % 12) ? hour : 12
      const minute = today.getMinutes().toString().padStart(2, '0')
      return apm + ' ' + hour + ':' + minute
    },
    date_parsing (date) {
      const result =
        date.getFullYear().toString() +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        date.getDate().toString().padStart(2, '0')
      return result
    },
    date_parsing_with_dot (date) {
      const result =
        date.getFullYear().toString() + '. '+
        (date.getMonth() + 1).toString().padStart(2, '0') + '. '+
        date.getDate().toString().padStart(2, '0')
      return result
    },
    showAlert (value) {
      const open = () => {
        this.attrs = value
        setTimeout(() => {
          this.dialog = true
        }, 100)
      }

      if (this.dialog) {
        this.dialog = false
        setTimeout(open, 100)
      } else {
        open()
      }
    },
    explain_start_end_parsing (start, end) {
      const st = new Date(start)
      // 밑의 en 에서 1000을 더해준 이유는 getEvents함수를 통해 이벤트를 만들어줄때
      // 1000을 빼서 계산하는 것이 필수적이기 때문에
      // 위에서 해당 처리를 한뒤임으로 밑에서 1000을 더해준다.
      let en = new Date(end)
      if (en - st < 86400000) {
        return [st.toLocaleDateString() + ' ' + st.toLocaleTimeString().substr(0, 7),
          en.toLocaleDateString() + ' ' + en.toLocaleTimeString().substr(0, 7)]
      } else if (en - st === 86400000) {
        return [st.toLocaleDateString() + ' 종일',
          en.toLocaleDateString()]
      } else if (en - st > 86400000) {
        // 종일인지 알아보는 조건문
        // 일정을 종일로 설정해두면, 오전 12:00:00로 시간이 같게된다.
        if (st.toLocaleTimeString() === en.toLocaleTimeString()) {
          en = new Date(en - 1000)
          return [st.toLocaleDateString() + ' 종일',
            en.toLocaleDateString()]
        } else {
          return [st.toLocaleDateString() + ' ' + st.toLocaleTimeString().substr(0, 7),
            en.toLocaleDateString() + ' ' + en.toLocaleTimeString().substr(0, 7)]
        }
      }
    }
  }
}
</script>
