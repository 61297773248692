<template>
  <v-card
    outlined
    width="100%"
    height="200px">
    <div class="linear-activity" v-if="loading" style="border-radius: 15px;">
      <div class="indeterminate"></div>
    </div>
    <div
      style="width: 100%; height: 100%; display: flex; align-items: center;"
    >
      <div v-if="windowWidth>670" style="width: 100%; display: flex; justify-content: space-around; padding: 0 10px 0">
        <top-card-container  :kindof_title="title.workTime" :main_data="'총 '+considerationOfNullData.workTime.mainValue+'/인'" :aver_data="considerationOfNullData.workTime.meanValue" :compare_data="considerationOfNullData.workTime.compareValue" :compare_color="considerationOfNullData.workTime.compareColor"/>
        <div class="separator"></div>
        <top-card-container  :kindof_title="title.tardiness" :main_data="'총 '+considerationOfNullData.tardiness.mainValue+''" :aver_data="considerationOfNullData.tardiness.meanValue" :compare_data="considerationOfNullData.tardiness.compareValue" :compare_color="considerationOfNullData.tardiness.compareColor"/>
        <div class="separator"></div>
        <top-card-container  :kindof_title="title.leave" :main_data="considerationOfNullData.leave.mainValue+' '" :aver_data="considerationOfNullData.leave.meanValue" :compare_data="considerationOfNullData.leave.compareValue" :compare_color="considerationOfNullData.leave.compareColor"/>
        <div class="separator"></div>
        <top-card-container  :kindof_title="title.absent" :main_data="considerationOfNullData.absent.mainValue+' '" :aver_data="considerationOfNullData.absent.meanValue" :compare_data="considerationOfNullData.absent.compareValue" :compare_color="considerationOfNullData.absent.compareColor"/>
        <div class="separator"></div>
        <top-card-container  :kindof_title="title.overworkTime" :main_data="considerationOfNullData.overworkTime.mainValue+' '" :aver_data="considerationOfNullData.overworkTime.meanValue" :compare_data="considerationOfNullData.overworkTime.compareValue" :compare_color="considerationOfNullData.overworkTime.compareColor"/>
      </div>
      <div v-else style="height: inherit; width: 100%;">
        <v-carousel
          v-model="carousel"
          height="inherit"
          hide-delimiters
          light
        >
          <v-carousel-item>
            <div style="display: flex; align-items: center; justify-content: center; height: 200px;">
              <top-card-container :kindof_title="title.workTime" :main_data="'총 '+considerationOfNullData.workTime.mainValue+'/인'" :aver_data="considerationOfNullData.workTime.meanValue" :compare_data="considerationOfNullData.workTime.compareValue" :compare_color="considerationOfNullData.workTime.compareColor"/>
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div style="display: flex; align-items: center; justify-content: center; height: 200px;">
              <top-card-container  :kindof_title="title.tardiness" :main_data="'총 '+considerationOfNullData.tardiness.mainValue+''" :aver_data="considerationOfNullData.tardiness.meanValue" :compare_data="considerationOfNullData.tardiness.compareValue" :compare_color="considerationOfNullData.tardiness.compareColor"/>
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div style="display: flex; align-items: center; justify-content: center; height: 200px;">
              <top-card-container  :kindof_title="title.leave" :main_data="considerationOfNullData.leave.mainValue+' '" :aver_data="considerationOfNullData.leave.meanValue" :compare_data="considerationOfNullData.leave.compareValue" :compare_color="considerationOfNullData.leave.compareColor"/>
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div style="display: flex; align-items: center; justify-content: center; height: 200px;">
              <top-card-container  :kindof_title="title.absent" :main_data="considerationOfNullData.absent.mainValue+' '" :aver_data="considerationOfNullData.absent.meanValue" :compare_data="considerationOfNullData.absent.compareValue" :compare_color="considerationOfNullData.absent.compareColor"/>
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div style="display: flex; align-items: center; justify-content: center; height: 200px;">
              <top-card-container  :kindof_title="title.overworkTime" :main_data="considerationOfNullData.overworkTime.mainValue+' '" :aver_data="considerationOfNullData.overworkTime.meanValue" :compare_data="considerationOfNullData.overworkTime.compareValue" :compare_color="considerationOfNullData.overworkTime.compareColor"/>
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
    </div>
  </v-card>
  
</template>

<script>
import Vue from 'vue'
import Axios from 'axios'
import TopCardContainer from './statistics_util_comp/TopCardContainer'

export default {
  components: { TopCardContainer },
  props: ['this_week'],
  data () {
    return {
      carousel: 0,
      data: null,
      date: new Date(this.this_week),
      loading: true,
      this_month_string: '이번달',
      title: {workTime: '근무 시간',tardiness: '지각 인원',leave: '휴가 일수',absent: '결근 인원',overworkTime: '초과 근무',},
      no_data_text: Vue.prototype.$serverAccessFailedTableNoResultText,
      error_flag: false,
      windowWidth: window.innerWidth,
    }
  },
  methods: {
    readDataAPI () {
      Axios.get(this.$getBlueDogUrl() + '/checkStatisticsTopCard?when1=Month&when2=' + this.date_parsing(this.date))
      .then((response) => {

        this.loading = false
        this.data = response.data
      }).catch((error)=>{
        this.loading = false
        this.error_flag = true
        this.child_to_parent(this.error_flag)
      })
    },
    child_to_parent(child_data){
      this.$emit('error_flag', child_data)
    },
    date_parsing (date) {
      const result =
        date.getFullYear().toString() +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        date.getDate().toString().padStart(2, '0')
      return result
    },
    onResize(){
      this.windowWidth = window.innerWidth
    }
  },
  mounted () {
    this.readDataAPI()
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    considerationOfNullData: function(){
      if(this.data == null){
        let result = {
          workTime:{mainValue:'0h 0m', meanValue: '일 평균 0h 0m', compareValue: this.this_month_string+' 0h 0m', compareColor: '#000000'},
          tardiness:{mainValue:'0명', meanValue: '일 평균 0명', compareValue: this.this_month_string+' 0명', compareColor: '#000000'},
          leave:{mainValue:'0일', meanValue: '일 평균 0일', compareValue: this.this_month_string+' 0명', compareColor: '#000000'},
          absent:{mainValue:'0명', meanValue: '일 평균 0명', compareValue: this.this_month_string+' 0명', compareColor: '#000000'},
          overworkTime:{mainValue:'0h 0m', meanValue: '일 평균 0h 0m', compareValue: this.this_month_string+' 0h 0m', compareColor: '#000000'},
        }
        
        return result
      }else{
        return this.data
      }
    }
  },
  watch: {
    this_week: {
      handler () {
        this.date = new Date(this.this_week)
        this.loading = true
        this.readDataAPI()
      }
    }
  }
}
</script>

<style scoped>
.guentae_subtitle{
  font-family: NotoSansBold;
  font-size: 16px;
  padding-bottom: 25px;
}

.guentae_main_data{
  display: inline-block;
  font-family: NotoSansBold;
  font-size: 26px;
  padding-bottom: 9px;
}

.guentae_mean_data{
  display: inline-block;
  padding-left: 15px;
  font-family: NotoSansSans;
  font-size: 15px;
}

.guentae_compare{
  font-family: NotoSansBold;
  font-size: 15px;
}

</style>
