<template>
  <v-app id="day">
      <v-container
        class="ma-0 padding_size"
        fluid
        background-color="#f6f7fa">
        <day-geunTae />

      </v-container>
  </v-app>
</template>
<style scoped>

.padding_size {
  padding: 70px 98px 94px 102px;
}

@media screen and (max-width: 960px){
  .padding_size {
    padding: 0 20px 20px;
  }
}

</style>

<style>
</style>

<script>
import daySchedule from './daySchedule.vue'
import dayGeunTae from './dayGeunTae.vue'

export default {
  name: 'App',
  components: {
    daySchedule,
    dayGeunTae
  },
  data: () => ({

  }),
  mounted() {
    scrollTo(0,0)
  },
}
</script>
