<template>
  <v-btn
    @click="readDataApi()"
    color="#5a7fff"
    style="color: #ffffff;"
  >
    Excel 파일 저장
  </v-btn>
</template>

<script>
import Vue from "vue";
import Axios from "axios";
import XLSX from "xlsx";

export default {
  props: ["test1", 'showDeleted'],
  mounted: function () {
    console.log("22" + this.test1);
  },

  data() {
    return {
      url: Vue.prototype.$serverUrl,
      date: new Date(this.test1),
      yearText: "",
      excel_data: {
        year_data: [],
        year_mean_data: [],
      },
    };
  },
  methods: {
    readDataApi() {
      let year = this.date.getFullYear();
      Axios.all([
        Axios.get(this.$getBlueDogUrl() + "/ganttExcelButton?year=" + year + '&showDeleted=' + this.showDeleted),
        Axios.get(this.$getBlueDogUrl() + "/ganttExcelYearAvgMeanButton?year=" + year + '&showDeleted=' + this.showDeleted),
        Axios.get(this.$getBlueDogUrl() + "/ganttExcelYearMeanButton?year=" + year + '&showDeleted=' + this.showDeleted),
      ]).then(
        Axios.spread((obj1, obj2, obj3) => {
          (this.excel_data = {
            year_data: [],
            year_mean_data: [],
          }),
            (this.excel_data.year_data = obj1.data);
          this.excel_data.year_mean_data.push(obj2.data);
          this.excel_data.year_mean_data = this.excel_data.year_mean_data.concat(
            obj3.data
          );
          this.onexport();
        })
      );
    },
    // https://lovemewithoutall.github.io/it/json-to-excel/
    onexport() {
      // export json to Worksheet of Excel
      // only array possible
      var officeYear = XLSX.utils.json_to_sheet(this.excel_data.year_data);
      var officeMeanYear = XLSX.utils.json_to_sheet(
        this.excel_data.year_mean_data
      );

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, officeYear, "office_year"); // sheetAName is name of Worksheet
      XLSX.utils.book_append_sheet(wb, officeMeanYear, "office_mean_year"); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, "book.xlsx"); // name of the file is 'boo
    },
  },
  watch: {
    test1: {
      handler() {
        console.log(this.test1);
        this.date = new Date(this.test1);
      },
    },
  },
};
</script>

<style>
</style>
