<template>
  <v-container
    fluid
    class="pa-0"
    style="background: #f6f7fa;"
  >
    <alert-api
      :accessed="error_flag_work_time||error_flag_top_card||error_flag_sch">
    </alert-api>
    <v-row
      style="padding-top: 30px; padding-bottom: 20px; max-width: 800px;"
      no-gutters
    >
      <div
        style="width: 100%; display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap;"
      >
        <div
          class="font-title-m-0"
        >
          {{topTitle}}
        </div>
        <div style="display: flex; align-items: center;">
          <div
            style="width: 25px; height: 25px;"
          >
            <img
              src="/asset_day/calendar-line-blue.svg"
              style="width: 25px; height: 25px;"
            />
          </div>
          <v-btn
            icon
            v-on:click="sub_1_day()"
          >
            <img src="/asset_day/arrow-left-s-line.svg" />
          </v-btn>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                id="date_text_field"
                :value="date_text_range"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
                class="pa-0 ma-0"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="datepicker_date"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
          <v-btn
            icon
            v-on:click="add_1_day()"
          >
            <img src="/asset_day/arrow-right-s-line.svg" />
          </v-btn>
        </div>
        <v-btn
          disabled
          style="color: #333333;"
        >
          {{this.date.getWeek()+"주차"}}
        </v-btn>
      </div>
    </v-row>
    <v-row
      no-gutters
    >
      <statistics-top-card :this_week="datepicker_date" :show-deleted="showDeleted" @error_flag="get_child_data_top_card"/>
    </v-row>
    <v-row
      style="padding-top: 30px; padding-bottom: 20px;"
      no-gutters
    >
      <div class="font-title-m-0">
        주간 근무 시간
      </div>
      <v-switch
        v-model="showDeleted"
        :label="`삭제 사용자 보기`"
      />
    </v-row>
    <v-row
      no-gutters
    >
      <statistics-work-time :this_week="datepicker_date" :show-deleted="showDeleted"  @error_flag="get_child_data_work_time"/>
    </v-row>
    <v-row
      style="padding-top: 30px; padding-bottom: 20px;"
      no-gutters
    >
      <div class="font-title-m-0">주간 일정</div>
    </v-row>
    <v-row
      no-gutters
    >
      <statistics-sch :given_date="datepicker_date" @error_flag="get_child_data_sch"/>
    </v-row>
  </v-container>
</template>

<script>
import AlertApi from '../customapi/alertApi.vue'
import StatisticsSch from './statisticsSch.vue'
import statisticsTopCard from './statisticsTopCard.vue'
import StatisticsWorkTime from './statisticsWorkTime.vue'

export default {
  components: { statisticsTopCard, StatisticsWorkTime, StatisticsSch, AlertApi },
  props: ['title'],
  data () {
    return {
      topTitle: this.title,
      date: new Date(),
      week_count: '',
      date_text_range: '',
      menu: false,
      showDeleted: false,
      error_flag_top_card: false,
      error_flag_work_time: false,
      error_flag_sch: false,
    }
  },
  methods: {
    get_child_data_top_card(child_data){
      this.error_flag_top_card = child_data
    },
    get_child_data_work_time(child_data){
      this.error_flag_work_time = child_data
    },
    get_child_data_sch(child_data){
      this.error_flag_sch = child_data
    },
    add_1_day () {
      const add_date = new Date()
      add_date.setTime(this.date.getTime() + 7 * 86400000)

      this.date = add_date
    },
    sub_1_day () {
      const sub_date = new Date()
      sub_date.setTime(this.date.getTime() - 7 * 86400000)

      this.date = sub_date
    }
  },
  watch: {
    topTitle: {
      handler () {
        topTitle = this.title
      }
    },
    showDeleted: {
      handler () {

      }
    }
  },
  computed: {
    datepicker_date: {
      get: function () {
        const week_start = new Date(
          this.date.getTime() - this.date.getDay() * 86400000
        )

        const week_end = new Date(week_start.getTime() + 6 * 86400000)

        const week_range =
          week_start.getFullYear() +
          '.' +
          (week_start.getMonth() + 1) +
          '.' +
          week_start.getDate() +
          ' ~ ' +
          week_end.getFullYear() +
          '.' +
          (week_end.getMonth() + 1) +
          '.' +
          week_end.getDate()

        this.date_text_range = week_range

        const result =
          week_start.getFullYear() +
          '-' +
          (week_start.getMonth() + 1) +
          '-' +
          week_start.getDate()

        Date.prototype.getWeek = function (dowOffset) {
          /* getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

          dowOffset = typeof (dowOffset) === 'number' ? dowOffset : 0 // default dowOffset to zero
          var newYear = new Date(this.getFullYear(), 0, 1)
          var day = newYear.getDay() - dowOffset // the day of week the year begins on
          day = (day >= 0 ? day : day + 7)
          var daynum = Math.floor((this.getTime() - newYear.getTime() -
            (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1
          var weeknum
          // if the year starts before the middle of a week
          if (day < 4) {
            weeknum = Math.floor((daynum + day - 1) / 7) + 1
            if (weeknum > 52) {
              const nYear = new Date(this.getFullYear() + 1, 0, 1)
              let nday = nYear.getDay() - dowOffset
              nday = nday >= 0 ? nday : nday + 7
              /* if the next year starts before the middle of
                the week, it is week #1 of that year */
              weeknum = nday < 4 ? 1 : 53
            }
          } else {
            weeknum = Math.floor((daynum + day - 1) / 7)
          }
          return weeknum
        }

        return result
      },
      set: function (v) {
        const a = v.split('-')
        this.date = new Date(a[0], a[1] - 1, a[2])
      }
    }
  }
}
</script>

<style>
.statistics_guentae_subtitle{
  font-family: NotoSansBold;
  font-size: 26px
}
</style>
