<template>
  <v-container class="pa-0 mx-0" fluid style="width: 100%;">
    <alert-api
      :accessed="alert_flag">
    </alert-api>
    <!-- 모바일 -->
    <!-- tabs -->
    <div
      v-if="windowWidth <= 960"
      class="mx-0"
      no-gutters>
      <div>
        <v-tabs
          v-model="tab"
          background-color="#f6f7fa"
          height="30"
        >
          <v-tabs-slider color="#3b46ff"></v-tabs-slider>

          <v-tab
            v-for="i in 2"
            :key="i"
            :href="`#tab-guentae-${i}`"
            style="font-family: NotoSansBold; font-size: 15px; font-weight: bold;"
          >
            {{mobile_tab_titles[i]}}
          </v-tab>
        </v-tabs>
      </div>
      <div
        style="margin-bottom: 22px; padding-left: 12px;"
      >
        <img
          style="transform: translateY(20%)"
          src="/asset_day/calendar-line-blue.svg"
          width="24px"
          height="24px"
        />
        <v-btn
          icon
          style="transform: translateY(-4%); margin-left: 10px"
          width="24px"
          height="24px"
          v-on:click="sub_1_day()"
        >
          <img src="/asset_day/arrow-left-s-line.svg" />
        </v-btn>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          min-width="290px"
          xs-0
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              id="date_text_field"
              :value="data_text"
              readonly
              v-bind="attrs"
              v-on="on"
              style="display: inline-block; width: 160px"
              hide-details="true"
            >
            </v-text-field>
          </template>
          <v-date-picker v-model="datepicker_date" no-title scrollable>
          </v-date-picker>
        </v-menu>
        <v-btn
          icon
          style="transform: translateY(-4%)"
          width="24px"
          height="24px"
          v-on:click="add_1_day()"
        >
          <img src="/asset_day/arrow-right-s-line.svg" />
        </v-btn>
      </div>
    </div>
    <!-- tab items -->
    <div
      v-if="windowWidth<=960"
      style="width: 100%;"
    >
      <v-tabs-items
        v-model="tab"
        touchless
        style="width: 100%;"
      >
        <v-tab-item value="tab-guentae-1" style="width: 100%;">
          <v-row
            no-gutters
            width="100%"
          >
            <v-card
              outlined
              class="pl-7 pr-7"
              style="height:100%; overflow: auto; width: 100%;"
            >
              <v-data-table
                mobile-breakpoint="960"
                :headers="headers"
                :items="items"
                :loading="loading"
                class="mytable"
                id="geunTae"
                style="font-size: 25px"
                :custom-sort="customSort"
                sort-by="starttime"
                hide-default-footer
                elevation-0
                :footer-props="{
                    itemsPerPageOptions: [-1], // 모든 내용을 보여준다는 의미 all
                  }"
                :no-data-text="alert_flag==true?no_data_text:''"
                :loading-text="loading_text"
              >
                <template v-slot:item.state="{ item }">
                  <div
                    style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                  >
                    <div>
                      <v-img
                        :src="item.state"
                        width="30px"
                        height="30px"
                        style="transform: translateY(-12%)"
                      ></v-img>
                    </div>
                  </div>
                </template>
                <template v-slot:item.timeline="{ item }">
                  <div
                    style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                  >
                    <div>
                      <day-timeline @dom_generated_counts="sumEveryTimelineDomCounts" :data="item.data" />
                    </div>
                  </div>
                </template>
                <template v-slot:item.starttime="{ item }">
                  <div
                    style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                  >
                    <div>
                      <img
                        src="/asset_day/time-line.svg"
                        width="20px"
                        height="20px"
                        class="mr-3"
                        style="transform: translateY(25%)"
                      />
                    </div>
                    <div style="width: 41px; height: 22px">{{ item.starttime }}</div>
                  </div>
                </template>
                <template v-slot:item.endtime="{ item }">
                  <div
                    style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                  >
                    <div>
                      <img
                        src="/asset_day/time-line-night.svg"
                        width="20px"
                        height="20px"
                        class="mr-3"
                        style="transform: translateY(25%)"
                      />
                    </div>
                    <div style="width: 41px; height: 22px">{{ item.endtime }}</div>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-row>
        </v-tab-item>
        <v-tab-item value="tab-guentae-2">
          <v-row
            no-gutters
            width="100%"
          >
            <day-schedule
              :date="dateSchedule"
              :loading="loading"
              :alertFlag="alert_flag"
              :windowWidth="windowWidth" />
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <!-- 모바일 끝 -->
    <!-- 데스크탑 -->
    <!-- v-menu, v-calendar 영역까지 -->
    <v-row
      v-if="windowWidth>960"
      class="mx-0"
      no-gutters
    >
      <div
        style="transform: translateY(18%); margin-right: 74px;">
        <span class="subtitle_div_font">일일 근태</span>
      </div>
      <div>
        <img
          style="transform: translateY(20%)"
          src="/asset_day/calendar-line-blue.svg"
          width="24px"
          height="24px"
        />
        <v-btn
          icon
          style="transform: translateY(-4%); margin-left: 10px"
          width="24px"
          height="24px"
          v-on:click="sub_1_day()"
        >
          <img src="/asset_day/arrow-left-s-line.svg" />
        </v-btn>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          min-width="290px"
          xs-0
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              id="date_text_field"
              :value="data_text"
              readonly
              v-bind="attrs"
              v-on="on"
              style="display: inline-block; width: 160px"
            >
            </v-text-field>
          </template>
          <v-date-picker v-model="datepicker_date" no-title scrollable>
          </v-date-picker>
        </v-menu>
        <v-btn
          icon
          style="transform: translateY(-4%)"
          width="24px"
          height="24px"
          v-on:click="add_1_day()"
        >
          <img src="/asset_day/arrow-right-s-line.svg" />
        </v-btn>
      </div>
      <v-switch
        v-model="showDeleted"
        :label="`삭제 사용자 보기`"
      />
    </v-row>
    <!-- 일일 근태 data table -->
    <v-row v-if="windowWidth>960" no-gutters style="width: 100%;">

      <v-card
        outlined
        class="pl-7 pr-7"
        height="400px"
        width="100%"
        style="overflow: auto"
      >
        <v-data-table
          mobile-breakpoint="960"
          :headers="headers"
          :items="items"
          :loading="loading"
          class="mytable"
          id="geunTae"
          style="font-size: 25px; width: 100%;"
          :custom-sort="customSort"
          sort-by="starttime"
          hide-default-footer
          :footer-props="{
              itemsPerPageOptions: [-1], // 모든 내용을 보여준다는 의미 all
            }"
          :no-data-text="alert_flag==true?no_data_text:''"
          :loading-text="loading_text"
        >
          <template v-slot:item.state="{ item }">
            <div
              style="display: flex;justify-content: center;align-items: center;"
            >
              <div>
                <v-img
                  :src="item.state"
                  width="30px"
                  height="30px"
                  style="transform: translateY(-12%)"
                ></v-img>
              </div>
            </div>
          </template>
          <template v-slot:item.timeline="{ item }">
            <div
              style="display: flex; justify-content: center; align-items: center;"
            >
              <div>
                <day-timeline @dom_generated_counts="sumEveryTimelineDomCounts" :data="item.data" />
              </div>
            </div>
          </template>
          <template v-slot:item.starttime="{ item }">
            <div
              style="display: flex; justify-content: center; align-items: center; flex-wrap: wrap;"
            >
              <div>
                <img
                  src="/asset_day/time-line.svg"
                  width="20px"
                  height="20px"
                  class="mr-3"
                  style="transform: translateY(25%)"
                />
              </div>
              <div style="width: 41px; height: 22px">
                {{ item.starttime }}
              </div>
            </div>
          </template>
          <template v-slot:item.endtime="{ item }">
            <div
              style="display: flex; justify-content: center; align-items: center; flex-wrap: wrap;"
            >
              <div>
                <img
                  src="/asset_day/time-line-night.svg"
                  width="20px"
                  height="20px"
                  class="mr-3"
                  style="transform: translateY(25%)"
                />
              </div>
              <div style="width: 41px; height: 22px">
                {{ item.endtime }}
              </div>
            </div>
          </template>
        </v-data-table>
      </v-card>

    </v-row>
    <!-- 오늘 일정 data table -->
    <v-row v-if="windowWidth>960" no-gutters>

      <day-schedule
        :date="dateSchedule"
        :loading="loading"
        :alertFlag="alert_flag"
        :windowWidth="windowWidth" />

    </v-row>
    <!-- 데스크탑 끝 -->
  </v-container>
</template>

<style scoped>
.btn_dateChanger {
  transform: translateY(-14%);
}

.subtitle_div_font {
  font-family: NotoSans;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
</style>

<style>
.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}

#date_text_field {
  text-align: center;
}

#date_area > div > div::before {
  border: none;
}

#div_v_img {
  text-align: center;
}

#geunTae table thead th {
  padding: 20px 0 20px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #979797;
}

#geunTae table tbody tr:first-child td {
  padding-top: 24px;
  padding-bottom: 12px;
}

@media (max-width:960px) {
  #geunTae table tbody tr td:last-child {
    border-bottom: solid 1px #dfdfe0 !important;
  }
}

#geunTae table tbody td {
  font-size: 15px;
  border-bottom: none;
  padding-top: 12px;
  padding-bottom: 12px;
}

#geunTae table tbody td:first-child {
  font-weight: bold;
}

@media (max-width: 960px) {
  #geunTae .v-data-table__wrapper{
    overflow-x: hidden !important;
  }
}


</style>

<script>
import Vue from 'vue'
import axios from 'axios'
import daySchedule from './daySchedule'
import dayTimeline from './dayTimeline'
import alertApi from '../customapi/alertApi.vue'

export default {
  components: {
    daySchedule,
    dayTimeline,
    alertApi
  },
  data () {
    return {
      tab : null,
      url: Vue.prototype.$serverUrl,
      showDeleted: false,
      windowWidth: window.innerWidth,
      items: [],
      dateSchedule: [],
      menu: false,
      loading: true,
      days: [
        ' 일요일',
        ' 월요일',
        ' 화요일',
        ' 수요일',
        ' 목요일',
        ' 금요일',
        ' 토요일'
      ],
      mobile_tab_titles:[
        "", "일일 근태", "일일 일정"
      ],
      headers: [
        {
          text: '근무자 이름',
          value: 'name',
          align: 'center',
          width: '10%'
        },
        {
          text: '',
          value: 'state',
          align: 'center',
          width: '5%',
          sortable: false // 나중에 바꿀수도 있음
        },
        {
          text: '타임라인',
          value: 'timeline',
          align: 'center',
          width: '50%',
          sortable: false
        },
        {
          text: '출근 시간',
          value: 'starttime',
          align: 'center',
          width: '10%'
        },
        {
          text: '퇴근 시간',
          value: 'endtime',
          align: 'center',
          width: '10%'
        },
        {
          text: '총 근무시간',
          value: 'officetime',
          align: 'center',
          width: '15%'
        }
      ],
      date: new Date(),
      data_text: '',
      alert_flag: false,
      no_data_text: Vue.prototype.$serverAccessFailedTableNoResultText,
      loading_text: Vue.prototype.$loadingTextDefault,
      pos:{left: 0, x: 0},
      posTouch:{left: 0, x: 0},
      timelineDomCheckCount: 0,
    }
  },
  computed: {
    datepicker_date: {
      get: function () {

        let pre_result = this.url_ymd_formats_with_under_bar(this.date)

        this.data_text = pre_result + this.days[this.date.getDay()]

        return pre_result
      },
      set: function (v) {
        const a = v.split('-')
        this.date = new Date(a[0], a[1] - 1, a[2])
      }
    }
  },
  methods: {
    url_ymd_formats_with_under_bar(date){
      var result =
        date.getFullYear().toString()+'-'+
        (date.getMonth()+1).toString().padStart(2,'0')+'-'+
        date.getDate().toString().padStart(2,'0')
      return result
    },
    customSort (items, index, isDesc) {
      items.sort((a, b) => {
        if (index == 'starttime' || index == 'endtime') {
          const aVal = a[index]
          const bVal = b[index]

          if (!isDesc[0]) {
            if (!aVal) {
              return 1
            } else if (!bVal) {
              return -1
            } else if (bVal && aVal) {
              const calcT =
                parseInt(aVal.split(':').join('')) -
                parseInt(bVal.split(':').join(''))

              if (calcT > 0) return 1
              else if (calcT < 0) return -1
              else return 0
            } else {
              return -1
            }
          } else {
            if (!aVal) {
              return -1
            } else if (!bVal) {
              return 1
            } else if (bVal && aVal) {
              const calcT =
                parseInt(aVal.split(':').join('')) -
                parseInt(bVal.split(':').join(''))

              if (calcT > 0) return -1
              else if (calcT < 0) return 1
              else return 0
            } else {
              return -1
            }
          }
        } else {
          const aVal = a[index]
          const bVal = b[index]

          if (!isDesc[0]) {
            if (!aVal) {
              return 1
            } else if (!bVal) {
              return -1
            } else if (bVal && aVal) {
              // asc : b-a > 0
              if (bVal > aVal) {
                return 1
              } else if (aVal > bVal) {
                return -1
              } else {
                return 0
              }
            }
          } else {
            if (!aVal) {
              return -1
            } else if (!bVal) {
              return 1
            } else if (bVal && aVal) {
              // asc : b-a > 0
              if (bVal > aVal) {
                return -1
              } else if (aVal > bVal) {
                return 1
              } else {
                return 0
              }
            }
          }
        }
      })
      return items
    },
    readDataFromAPI () {

      this.timelineDomCheckCount = 0

      const date_when = Vue.prototype.$urlYmdFormat(this.date)

      this.loading = true

      console.log(this.$getBlueDogUrl())

      axios
        .all([
          axios.get(this.$getBlueDogUrl() + '/daily?when=' + date_when + '&showDeleted=' + this.showDeleted),
          axios.get(this.$getBlueDogUrl() + '/dateSchedule?ymd=' + date_when)
        ])
        .then(
          axios.spread((obj1, obj2) => {
            this.loading = false

            this.items = []
            this.dateSchedule = []

            for (let i = 0; i < obj1.data[0].length; i++) {
              const data = obj1.data[0][i]

              const item = {} // 이름, 포맷처리된 출퇴근시간, timelineData
              const timelineData = {} // 출근시간, 퇴근시간,

              // console.log(data);

              if (!data.break_time) {
                item.data = {
                  name: data.name,
                  index: data.index
                }
              }
              else {
                const { start_time, end_time, work_time } = this.commute_format(
                  data.commute_time.start_time,
                  data.commute_time.end_time,
                  data.commute_time.real_work_time,
                  data.commute_time.real_over_work_time,
                )

                timelineData.workTime = data.commute_time
                timelineData.breakTime = data.break_time
                timelineData.index = data.index

                item.data = timelineData
                item.starttime = start_time // 출근시간
                item.endtime = end_time // 퇴근시간
                item.officetime = work_time // 총 근무시간
              }

              item.name = data.name

              this.items.push(item)
            }
            for (let i = 0; i < obj1.data[1].length; i++) {
              for (let j = 0; j < this.items.length; j++) {
                if (this.items[j].name == obj1.data[1][i].name) {
                  this.items[j].state = this.changeStateLogo(obj1.data[1][i].state)
                }
              }
            }
            for (let i = 0; i < obj2.data.length; i++) {
              const data = obj2.data[i]

              const item = {}
              item.title = data.name // 제목
              item.start = data.start // 일정 시작일자
              item.end = data.end // 일정 끝나는일자
              item.memo = data.memo // 일정 메모
              item.place = data.place // 일정 장소

              this.dateSchedule.push(item)
            }
          })
        )
        .catch((response)=>{
          console.error(response)
          this.loading = false;
          this.alert_flag = true;
        })
    },
    // 통근시간에 출근시간과 퇴근시간을 받아 formatting
    commute_format (start_work, end_work, real_work_time, real_over_work_time) {
      const d_s = new Date(start_work)
      let hour_s = d_s.getHours()
      let minute_s = d_s.getMinutes()

      const d_e = new Date(end_work)
      let hour_e = d_e.getHours()
      let minute_e = d_e.getMinutes()

      hour_s = hour_s.toString().padStart(2, '0')
      minute_s = minute_s.toString().padStart(2, '0')

      hour_e = hour_e.toString().padStart(2, '0')
      minute_e = minute_e.toString().padStart(2, '0')

      return {
        start_time: [hour_s, minute_s].join(':'),
        end_time: [hour_e, minute_e].join(':'),
        work_time: this.real_work_time_calc(real_work_time/60000, real_over_work_time/60000)
      }
    },
    real_work_time_calc(work_time, over_work_time){
      return (work_time>60?this.real_over_60_work_time_format(work_time)+this.real_over_work_time_format(over_work_time)
        :this.real_work_time_format(work_time)+this.real_over_work_time_format(over_work_time))
    },
    // 60분 안넘었을때
    real_work_time_format(work_time){
      return (work_time+"").padStart(2,'0')+'m'
    },
    // 60분 넘었을때
    real_over_60_work_time_format(work_time){
      return (Math.floor(work_time/60)+"").padStart(2,'0')+'h '+ (work_time%60+"").padStart(2,'0')+'m'
    },
    // 초과근무 시간이 있을때 적용된다. 평소에는 0이라 "" 만 추가됨(즉, 아무 변경 없어짐)
    real_over_work_time_format(over_work_time){
      let format = "";
      // 초과 근무 없을때, 서버에서 0으로 결과값 돌려줌
      if(over_work_time==0){
        return "";
      }else if(over_work_time < 60) {
        return "/ 연장 "+this.real_work_time_format(over_work_time);
      }
      else{
        return "/ 연장 "+this.real_over_60_work_time_format(over_work_time);
      }
    },
    changeStateLogo (str) {
      switch (str) {
        case 'commute':
          return '/icon_guentae_state/state-working.svg'
        case 'noseat':
          return '/icon_guentae_state/state-empty-seat.svg'
        case 'overwork':
          return '/icon_guentae_state/state-over-working.svg'
        case 'tardiness':
          return '/icon_guentae_state/state-working-lage.svg'
        case 'outsidework':
          return '/icon_guentae_state/state-out-working.svg'
        case 'leave':
          return '/icon_guentae_state/state-vacation.svg'
        case 'absent':
          return '/icon_guentae_state/state-absent.svg'
        default:
          return '/icon_guentae_state/state-time-togo-home.svg'
      }
    },
    add_1_day () {
      const add_date = this.date.getTime() + 86400000
      this.date = new Date(add_date)
    },
    sub_1_day () {
      const sub_date = this.date.getTime() - 86400000
      this.date = new Date(sub_date)
    },
    onResize(){
      this.windowWidth = window.innerWidth;
    },
    addDragScroll(eleList){
      eleList.forEach(ele=>{
        ele.style.cursor = 'grab'

        let mouseDownHandler = (e) => {

          ele.style.cursor = 'grabbing'
          ele.style.userSelect = 'none'

          this.pos = {
            left: ele.scrollLeft,
            x: e.clientX
          }

          ele.addEventListener('mousemove', mouseMoveHandler)
          ele.addEventListener('mouseup', mouseUpHandler)

        }

        let mouseLeaveHandler = (e)=>{

          ele.style.cursor = 'grab'

          ele.removeEventListener('mousemove', mouseMoveHandler)
          ele.removeEventListener('mouseup', mouseUpHandler)
        }

        let mouseMoveHandler = (e) => {

          ele.style.cursor = 'grabbing'

          let dx = e.clientX - this.pos.x

          eleList.forEach(x=>{
            x.scrollLeft = this.pos.left - dx
          })

          ele.addEventListener('mouseleave', mouseLeaveHandler)
        }

        let mouseUpHandler = (e) => {
          ele.style.cursor = 'grab'
          ele.style.removeProperty('user-select')

          ele.removeEventListener('mousemove', mouseMoveHandler)
          ele.removeEventListener('mouseup', mouseUpHandler)
        }

        ele.addEventListener('mousedown', mouseDownHandler)
      })

    },

    addDragTouch(eleList){

      eleList.forEach((ele)=>{

        let touchStartHandler = (e) => {
          e.preventDefault()

          this.posTouch = {
            left: ele.scrollLeft,
            x: e.changedTouches[0].clientX
          }

          ele.addEventListener('touchmove', touchMoveHandler)
          ele.addEventListener('touchcancel', touchCancelHandler)
          ele.addEventListener('touchend', touchEndHandler)
        }

        let touchMoveHandler = (e) => {
          e.preventDefault()

          let dx = e.changedTouches[0].clientX - this.posTouch.x

          eleList.forEach((x_ele)=>{
            x_ele.scrollLeft = this.posTouch.left - dx
          })
        }

        let touchCancelHandler = (e) => {
          ele.removeEventListener('touchmove', touchMoveHandler)
        }

        let touchEndHandler = (e) => {
          ele.removeEventListener('touchmove', touchMoveHandler)
        }

        ele.addEventListener('touchstart', touchStartHandler)
      })


    },

    sumEveryTimelineDomCounts(value){
      if(value){
        this.timelineDomCheckCount++
      }
    }
  },
  mounted () {
    this.readDataFromAPI()
    window.addEventListener('resize', this.onResize)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    date: {
      handler () {
        this.readDataFromAPI()
      }
    },
    showDeleted: {
      handler () {
        this.readDataFromAPI()
      }
    },
    timelineDomCheckCount: {
      handler(){
        if(this.timelineDomCheckCount == this.items.length){
          this.addDragScroll(document.querySelectorAll('.dragTestClass'))
          this.addDragTouch(document.querySelectorAll('.dragTestClass'))
        }
      }
    },
    // windowWidth 960기준으로 mouse touch event 동시에 작동하는 게 풀리는 문제 해결방법
    // 1. v-if 로 windowWidth가 960기준으로 timeline component를 다시 호출하게 되는듯 하다.
    // 2. 그래서, timeline component에서 지속적으로 emit을 하기때문에 기존의 로직을 반영하지 못한다.
    // (기존로직 : parent에서 이벤트를 add 해주는데, dom이 로드되기를 기다려야 한다.)
    // 3. 생각한 방법은 아래와같이 v-if 기준으로 계속 반영되는 timelineDomCheckCount를 초기화 시켜준다.
    // 4. 이렇게 하면 스크롤 이벤트를 다시 묶어주기는 하지만 사용자 입장에서는 유지된다.
    windowWidth:{
      handler(){
        if(this.windowWidth > 960){
          this.timelineDomCheckCount = 0
        }
        else if(this.windowWidth < 960){
          this.timelineDomCheckCount = 0
        }
      }
    }
  },
}
</script>
