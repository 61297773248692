<template>
  <div class="mx-0" style="height: 80%;">
    <div class="linear-activity" v-if="loading" style="height: 2px;">
      <div class="indeterminate" style="height: 2px;"></div>
    </div>
    <div style="display: flex; justify-content: center;">
      <div style="width: 100%" class="px-11">
        <div style="position: relative">
          <div style="display: inline-block">출근</div>
          <div
            id="value_of_mean_start"
            style="display: inline-block; position: absolute; right: 0"
          >
            0%
          </div>
        </div>
        <div style="padding-bottom: 35px; padding-top: 14px; width: 100%;">
          <div class="progress">
            <div class="bar_start"></div>
          </div>
        </div>

      </div>
    </div>
    <div style="display: flex; justify-content: center">
      <div style="width: 100%" class="px-11">
        <div style="position: relative">
          <div style="display: inline-block">지각</div>
          <div
            id="value_of_mean_late"
            style="display: inline-block; position: absolute; right: 0"
          >
            0%
          </div>
        </div>
        <div style="padding-bottom: 35px; padding-top: 14px; width: 100%;">
          <div class="progress">
            <div class="bar_late"></div>
          </div>
        </div>

      </div>
    </div>
    <div style="display: flex; justify-content: center">
      <div style="width: 100%" class="px-11">
        <div style="position: relative">
          <div style="display: inline-block">초과근무</div>
          <div
            id="value_of_mean_over"
            style="display: inline-block; position: absolute; right: 0"
          >
            0%
          </div>
        </div>
        <div style="padding-bottom: 35px; padding-top: 14px; width: 100%;">
          <div class="progress">
            <div class="bar_over"></div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['yeardata', 'loading'],
  data: () => ({
    movement_start: null,
    movement_late: null,
    movement_end: null,
    date: new Date()
  }),
  watch: {
    yeardata: {
      handler () {
        this.updateProgress()
      }
    }
  },
  mounted() {
    // this.yeardata === undefined -> this.yeardata 는 observer type이다...
    // !this.yeardata -> 이미 observer 값을 가지고 있기 때문에 값이 있다고 판단되어서 false를 반환하게 된다
    // 만약 값이 들어와도 !this.yeardata는 false가 될것이다.
    if(!(this.yeardata.length === 0)){
      this.updateProgress()
    }
  },
  methods: {
    updateProgress () {
      const el_start = document.querySelector('div.bar_start')
      const el_late = document.querySelector('div.bar_late')
      const el_over = document.querySelector('div.bar_over')

      el_start.className = 'bar_start_1'
      el_start.style.width = this.yeardata.check_commute + '%'
      el_late.className = 'bar_late_1'
      el_late.style.width = this.yeardata.check_tardiness + '%'
      el_over.className = 'bar_over_1'
      el_over.style.width = this.yeardata.check_overwork + '%'

      const pg_start = document.getElementById('value_of_mean_start')
      const pg_late = document.getElementById('value_of_mean_late')
      const pg_over = document.getElementById('value_of_mean_over')

      pg_start.innerText = el_start.style.width
      pg_late.innerText = el_late.style.width
      pg_over.innerText = el_over.style.width
    }
  }
}
</script>

<style scoped>
.progress {
  width: 100%;
  height: 0.8em;
  border-radius: 20px;
  background-color: #efefef;
}

.bar_start {
  width: 0%;
  height: 0.8em;

  transition-property: width;
  transition-duration: 3s;
  transition-timing-function: ease;
  border-radius: 20px;
}

.bar_start_1 {
  width: 0%;
  height: 0.8em;
  background-color: #3b46ff;

  transition-property: width;
  transition-duration: 2s;
  transition-timing-function: ease;
  border-radius: 20px;
}
.bar_late {
  width: 0%;
  height: 0.8em;

  transition-property: width;
  transition-duration: 3s;
  transition-timing-function: ease;
  border-radius: 20px;
}

.bar_late_1 {
  width: 0%;
  height: 0.8em;
  background-color: #ffb260;

  transition-property: width;
  transition-duration: 2s;
  transition-timing-function: ease;
  border-radius: 20px;
}
.bar_over {
  width: 0%;
  height: 0.8em;

  transition-property: width;
  transition-duration: 3s;
  transition-timing-function: ease;
  border-radius: 20px;
}

.bar_over_1 {
  width: 0%;
  height: 0.8em;
  background-color: #ff7185;

  transition-property: width;
  transition-duration: 2s;
  transition-timing-function: ease;
  border-radius: 20px;
}

</style>
