<script>
import { Bar } from 'vue-chartjs'
// import ChartDataLabels from 'chartjs-plugin-datalabels'

export default {
  extends: Bar,
  props: ['data'],
  data () {
    return {
      labelArray: [],
      valueArray: [],
      colorArray: []
    }
  },
  watch: {
    data: {
      handler () {
        this.valueArray = this.data.map((it) => it.value)
        //   this.colorArray = this.data.map((it) => it.color.trim());
        this.colorArray = this.data.map((it) =>this.rgbHexToText(it.color.trim()))
        this.labelArray = this.data.map((it) => `${it.label}`)
        const data = {
          datasets: [
            {
              type: 'roundedBar',
              data: this.valueArray,
              backgroundColor: this.colorArray,
              barThickness: 35
            }
          ],
          labels: this.labelArray
        }
        const options = {
          barRoundness: 1,
          legend: {
            display: false
          },
          plugins: {
            // https://chartjs-plugin-datalabels.netlify.app/guide/options.html#scriptable-options
            datalabels: {
              color: '#FFFFFF',
              align: 'end',
              anchor: 'start',
              offset: 0,
              font: {
                family: 'NotoSansBold',
                size: 16
              },
              textStrokeColor: 'black',
              textStrokeWidth: 1
            }
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontFamily: 'NotoSans',
                  fontColor: 'black',
                  fontSize: 16
                },
                gridLines: {
                  display: false
                }
              }
            ],
            yAxes: [
              {
                ticks: {
                  display: false, // this will remove only the label
                  stepSize: 15
                },
                gridLines: {
                  borderDash: [8, 8],
                  color: '#cdd1df',
                  drawBorder: false // https://stackoverflow.com/questions/40522923/how-to-remove-the-line-rule-of-an-axis-in-chart-js
                }
              }
            ]
          },
          maintainAspectRatio: false
        }
        this.renderChart(data, options)
      }
    }
  },
  mounted () {
    // this.addPlugin(ChartDataLabels)
    
    this.addPlugin({
      id: 'rounded-bar',
      beforeInit: function (chart) {
        // from https://github.com/contactlutforrahman/chartjs-top-round-bar/blob/master/index.js
        // https://www.codeseek.co/jordanwillis/chartjs-bar-chart-with-rounded-tops-drawroundedtoprectangle-method-jBoppb

        // draws a rectangle with a rounded top
        Chart.helpers.drawRoundedTopRectangle = function (
          ctx,
          x,
          y,
          width,
          height,
          radius
        ) {
          ctx.beginPath()
          ctx.moveTo(x + radius, y)
          // top right corner
          ctx.lineTo(x + width - radius, y)
          ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
          // bottom right	corner
          ctx.lineTo(x + width, y + height)
          // bottom left corner
          ctx.lineTo(x, y + height)
          // top left
          ctx.lineTo(x, y + radius)
          ctx.quadraticCurveTo(x, y, x + radius, y)
          ctx.closePath()
        }

        Chart.elements.RoundedTopRectangle = Chart.elements.Rectangle.extend({
          draw: function () {
            var ctx = this._chart.ctx
            var vm = this._view
            var left, right, top, bottom, signX, signY, borderSkipped
            var borderWidth = vm.borderWidth

            if (!vm.horizontal) {
              // bar
              left = vm.x - vm.width / 2
              right = vm.x + vm.width / 2
              top = vm.y
              bottom = vm.base
              signX = 1
              signY = bottom > top ? 1 : -1
              borderSkipped = vm.borderSkipped || 'bottom'
            } else {
              // horizontal bar
              left = vm.base
              right = vm.x
              top = vm.y - vm.height / 2
              bottom = vm.y + vm.height / 2
              signX = right > left ? 1 : -1
              signY = 1
              borderSkipped = vm.borderSkipped || 'left'
            }

            // Canvas doesn't allow us to stroke inside the width so we can
            // adjust the sizes to fit if we're setting a stroke on the line
            if (borderWidth) {
              // borderWidth shold be less than bar width and bar height.
              var barSize = Math.min(
                Math.abs(left - right),
                Math.abs(top - bottom)
              )
              borderWidth = borderWidth > barSize ? barSize : borderWidth
              var halfStroke = borderWidth / 2
              // Adjust borderWidth when bar top position is near vm.base(zero).
              var borderLeft =
                left + (borderSkipped !== 'left' ? halfStroke * signX : 0)
              var borderRight =
                right + (borderSkipped !== 'right' ? -halfStroke * signX : 0)
              var borderTop =
                top + (borderSkipped !== 'top' ? halfStroke * signY : 0)
              var borderBottom =
                bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0)
              // not become a vertical line?
              if (borderLeft !== borderRight) {
                top = borderTop
                bottom = borderBottom
              }
              // not become a horizontal line?
              if (borderTop !== borderBottom) {
                left = borderLeft
                right = borderRight
              }
            }

            // calculate the bar width and roundess
            var barWidth = Math.abs(left - right)
            var roundness = this._chart.config.options.barRoundness || 0.5
            var radius = barWidth * roundness * 0.5

            // keep track of the original top of the bar
            var prevTop = top

            // move the top down so there is room to draw the rounded top
            top = prevTop + radius
            var barRadius = top - prevTop

            ctx.beginPath()
            ctx.fillStyle = vm.backgroundColor
            ctx.strokeStyle = vm.borderColor
            ctx.lineWidth = borderWidth

            // draw the rounded top rectangle
            Chart.helpers.drawRoundedTopRectangle(
              ctx,
              left,
              top - barRadius + 1,
              barWidth,
              bottom - prevTop,
              barRadius
            )

            ctx.fill()
            if (borderWidth) {
              ctx.stroke()
            }

            // restore the original top value so tooltips and scales still work
            top = prevTop
          }
        })

        Chart.defaults.roundedBar = Chart.helpers.clone(Chart.defaults.bar)

        Chart.controllers.roundedBar = Chart.controllers.bar.extend({
          dataElementType: Chart.elements.RoundedTopRectangle
        })
      }
    })
    if(this.plugins.length != 0){
      this.rendering()
    }
  },
  methods: {
    // #aabbcc -> {r:170, g:187, g:204}
    hexToRgb: function (hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
        : null
    },
    //
    rgbHexToText: function (hex) {
      const rgb = this.hexToRgb(hex)
      return rgb != null ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)` : null
    },
    rendering(){
      this.valueArray = this.data.map((it) => it.value)
      //   this.colorArray = this.data.map((it) => it.color.trim());
      this.colorArray = this.data.map((it) =>this.rgbHexToText(it.color.trim()))
      this.labelArray = this.data.map((it) => `${it.label}`)
      const data = {
        datasets: [
          {
            type: 'roundedBar',
            data: this.valueArray,
            backgroundColor: this.colorArray,
            barThickness: 35
          }
        ],
        labels: this.labelArray
      }
      const options = {
        barRoundness: 1,
        legend: {
          display: false
        },
        plugins: {
          // https://chartjs-plugin-datalabels.netlify.app/guide/options.html#scriptable-options
          datalabels: {
            color: '#FFFFFF',
            align: 'end',
            anchor: 'start',
            offset: 0,
            font: {
              family: 'NotoSansBold',
              size: 16
            },
            textStrokeColor: 'black',
            textStrokeWidth: 1
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontFamily: 'NotoSans',
                fontColor: 'black',
                fontSize: 16
              },
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                display: false, // this will remove only the label
                stepSize: 15
              },
              gridLines: {
                borderDash: [8, 8],
                color: '#cdd1df',
                drawBorder: false // https://stackoverflow.com/questions/40522923/how-to-remove-the-line-rule-of-an-axis-in-chart-js
              }
            }
          ]
        },
        maintainAspectRatio: false
      }
      this.renderChart(data, options)
    }
  }
}
</script>
