<template>
  <statistics-guentae :title="weekGuentae"/>
</template>

<script>
import StatisticsGuentae from './statisticsGuentae..vue'

export default {
  components: {
    StatisticsGuentae
  },
  props: ['title'],
  data () {
    return {
      weekGuentae: this.title
    }
  },
  methods: {}
}
</script>

<style>
</style>
