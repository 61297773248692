<template>
    <v-carousel
        v-model="model"
        height="100%"
        light
        hide-delimiters
    >
        <v-carousel-item
            style="height: 100%;"
        >
            <v-sheet
                height="100%"
                tile
            >
                <v-row
                    no-gutters
                    class="fill-height"
                    align="center"
                    justify="center"
                    style="display: unset;"
                >
                    <day-donut-container
                        :loading="loading"
                        :items="donut_items"
                        :updateTime="donut_updateTime"/>
                </v-row>
            </v-sheet>
        </v-carousel-item>
        <v-carousel-item>
            <v-sheet
                height="100%"
                tile
            >
                <v-row
                    no-gutters
                    class="fill-height"
                    align="center"
                    justify="center"
                    
                >
                    <month-column
                        :loading="loading"
                        :data="bar_data" 
                        :windowWidth="bar_windowWidth"/>             
                </v-row>
            </v-sheet>
        </v-carousel-item>
        <v-carousel-item>
            <v-sheet
                height="100%"
                tile
                class="px-2"
            >
                <v-row
                    no-gutters
                    class="fill-height"
                    align="center"
                    justify="center"
                    style="display: unset;"
                >
                    <div class="linear-activity" v-if="loading" style="margin-top: 20px; border-radius: 15px;">
                        <div class="indeterminate"></div>
                    </div>
                    <div style="font-family: NotoSansBold; height: 20%; padding-left: 30px; padding-top: 20px;">
                        {{date.getFullYear()}} 연 평균
                    </div>
                    <dash-board-year
                        :yeardata="progress_yeardata" />  
                </v-row>
            </v-sheet>
        </v-carousel-item>
    </v-carousel>
</template>

<script>
import DashBoardYear from './DashBoardYear.vue'
import DayDonutContainer from './DayDonutContainer.vue'
import MonthColumn from './MonthColumn.vue'
export default {
    components: { DayDonutContainer, MonthColumn, DashBoardYear },
    props: ['donut_items', 'donut_updateTime', 'bar_data', 'bar_windowWidth', 'progress_yeardata', 'loading'],
    data() {
        return {
            date: new Date(),
            model:0,
            v_tab_arr: ['donught']
        }
    },
    mounted() {
    },
}
</script>

<style>
</style>
