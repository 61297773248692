<template>
  <v-container
    fluid
    class="pa-0"
    style="background: #f6f7fa;"
  >
    <alert-api
      :accessed="error_flag_top_card||error_flag_work_time||error_flag_sch||error_flag_gantt">
    </alert-api>
    <v-row
      style="padding: 30px 0 20px; max-width: 800px;"
      no-gutters
    >
      <div
        style="width: 100%; display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap;"
      >
        <div
          class="font-title-m-0"
        >
          {{topTitle}}
        </div>
        <div style="display: flex; align-items: center;">
          <div
            style="width: 25px; height: 25px;"
          >
            <img
              src="/asset_day/calendar-line-blue.svg"
              style="width: 25px; height: 25px;"
            />
          </div>
          <v-btn
            icon
            v-on:click="sub_1_day()"
          >
            <img src="/asset_day/arrow-left-s-line.svg" />
          </v-btn>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                id="date_text_field"
                :value="dateText"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
                class="pa-0 ma-0"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="datepicker_date"
              no-title
              scrollable
              type="month"
            >
            </v-date-picker>
          </v-menu>
          <v-btn
            icon
            v-on:click="add_1_day()"
          >
            <img src="/asset_day/arrow-right-s-line.svg" />
          </v-btn>
        </div>
        <v-btn
          disabled
          style="color: #333333;"
        >
          {{this.date.getWeek()+"주차"}}
        </v-btn>
      </div>
    </v-row>
    <v-row
      no-gutters
    >
      <statistics-top-card-month :this_week="datepicker_date" @error_flag="get_child_data_top_card"/>
    </v-row>
    <v-row
      no-gutters
    >
      <v-tabs
        v-model="tabs"
        slider-size="2"
        background-color="#f6f7fa"
        color="#3b46ff"
        class="v_tabs_month"
        style="margin: 30px 0 20px;"
      >
        <v-tab class="vTabs pa-0" href="#tab-1">간트 차트</v-tab>
        <v-tab class="vTabs pa-0" href="#tab-2">근무 시간</v-tab>
        <v-tab class="vTabs pa-0" href="#tab-3">일정</v-tab>
        <v-switch
          v-model="showDeleted"
          :label="`삭제 사용자 보기`"
        />
      </v-tabs>
      <v-tabs-items v-model="tabs" style="width: 100%;" touchless>
        <v-tab-item v-for="i in 3" :key="i" :value="'tab-' + i">
          <statistics-gantt-month style="height: 750px;" v-if="i==1" :this_month="datepicker_date" :show-deleted="showDeleted" @error_flag="get_child_data_gantt"/>
          <statistics-work-time-month style="height: 500px; overflow: auto;" v-if="i == 2" :this_month="datepicker_date" :show-deleted="showDeleted" @error_flag="get_child_data_work_time"/>
          <statistics-sch-month style="height: 500px; overflow: auto;" v-if="i==3" :given_date="datepicker_date" @error_flag="get_child_data_sch"/>
        </v-tab-item>
      </v-tabs-items>
    </v-row>
  </v-container>
</template>

<style>
@media (max-width: 450px){
  .v_tabs_month a{
    font-size: 14px !important;

  }

  .vTabs{
    width: 100% !important;
  }
}
</style>

<script>
import AlertApi from '../customapi/alertApi.vue'
import StatisticsGanttMonth from './statisticsGanttMonth.vue'
import StatisticsSchMonth from './statisticsSchMonth.vue'
import StatisticsTopCardMonth from './statisticsTopCardMonth.vue'
import StatisticsWorkTimeMonth from './statisticsWorkTimeMonth.vue'

export default {
  components: { StatisticsTopCardMonth, StatisticsWorkTimeMonth, StatisticsSchMonth, StatisticsGanttMonth, AlertApi },
  props: ['title'],
  data () {
    return {
      topTitle: this.title,
      date: new Date(),
      dateText: '',
      showDeleted: false,
      tabs: '',
      menu: false,
      error_flag_top_card: false,
      error_flag_work_time: false,
      error_flag_sch: false,
      error_flag_gantt: false,
    }
  },
  methods: {
    get_child_data_gantt(child_data){
      this.error_flag_gantt = child_data
    },
    get_child_data_work_time(child_data){
      this.error_flag_work_time = child_data
    },
    get_child_data_sch(child_data){
      this.error_flag_sch = child_data
    },
    get_child_data_top_card(child_data){
      this.error_flag_top_card = child_data
    },
    add_1_day () {
      const add_date = new Date(
        this.date.getFullYear(),
        this.date.getMonth() + 1,
        1,
        1
      )

      this.date = add_date
    },
    sub_1_day () {
      const sub_date = new Date(
        this.date.getFullYear(),
        this.date.getMonth() - 1,
        1
      )

      this.date = sub_date
    }
  },
  watch: {
    topTitle: {
      handler () {
        topTitle = this.title
      }
    }
  },
  computed: {
    datepicker_date: {
      // 밑에서 toISOString()은 GMT로 시간이 맞춰져 있기 떄문에 대략 9시간 정도 차이난다.
      // 그래서 한국시간에서 9시간을 더해주면, 월과 일이 맞춰지기 때문에 9를 넣어주었다.
      get: function () {
        const month_start = new Date(
          this.date.getFullYear(),
          this.date.getMonth(),
          1,
          9
        )

        const month_end = new Date(
          this.date.getFullYear(),
          this.date.getMonth() + 1,
          0
        )

        const month_range =
          month_start.getFullYear() +
          '.' +
          (month_start.getMonth() + 1) +
          '.' +
          month_start.getDate() +
          ' ~ ' +
          month_end.getFullYear() +
          '.' +
          (month_end.getMonth() + 1) +
          '.' +
          month_end.getDate()

        this.dateText = month_range

        const result = month_start.toISOString().substr(0, 10)

        return result
      },
      set: function (v) {
        const a = v.split('-')
        this.date = new Date(a[0], a[1] - 1, 1)
      }
    }
  }
}
</script>

<style scoped>
.vTabs{
  width: 110px;
  font-family: NotoSansBold;
  font-size: 20px;
}
</style>
