<template>
  <v-app class="calendar_main" style="padding-left: 100px; padding-top: 70px; padding-bottom: 150px; padding-right: 100px;">
    <alert-api
      :accessed="error_flag"
      style="margin-right: 169px;"
    ></alert-api>
    <div
      v-if="windowWidth>630"
      style="width: 100%; height: 100%;"
    >
      <v-row no-gutters style="height: 20%">
        <div class="calendar-desktop-top-container">
          <div style="height: 30%; font-family: NotoSansBold; font-size: 26px;">캘린더</div>
          <div class="top-middle-container" style="height: 70%; max-width: 1000px;">
            <div style="display: flex; align-items: center; height: 50%;">
              <div style="font-family: NotoSansBold; font-size: 30px; margin-right: 50px; width: 46px;">
                {{date.getMonth()+1}}월
              </div>
              <div style="padding-right: 16px;">
                <img src="/icon_page_state/calendar-line-blue.svg" style="width: 24px; height: 23px; transform: translateY(13%);">
              </div>
              <div class="top-middle-child-img">
                <v-btn
                  icon
                  v-on:click="sub_1_day()"
                >
                  <img src="/asset_day/arrow-left-s-line.svg" />
                </v-btn>
              </div>
              <div style="font-family: NotoSans; font-size: 20px; padding-left: 7px; padding-right: 1px; transform: translateY(9.5%);">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="date_text_field"
                      :value="dateText"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="display: inline-block; width: 180px; transform: translateY(-3%);"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="datepicker_date"
                    no-title
                    scrollable
                    type="month"
                  >
                  </v-date-picker>
                </v-menu>
              </div>
              <div class="top-middle-child-img">
                <v-btn
                  icon
                  @click="add_1_day"
                >
                  <img src="/asset_day/arrow-right-s-line.svg" />
                </v-btn>
              </div>
            </div>
            <div
              style="height: 50%;"
            >
              <calendar-explain-color/>
            </div>
          </div>
        </div>
      </v-row>
      <v-row no-gutters style="height: 80%">
        <div style="width: 100%; height: 100%;">
          <v-card outlined width="100%" height="100%">
            <!-- loading bar -->
            <div class="linear-activity" v-if="loading" style="height: 2px;">
              <div class="indeterminate" style="height: 2px;"></div>
            </div>
            <div class="calendar-div-wrapper" style="height: 100%; padding: 21px 113px 61px 96px;">
              <div class="calendar-header" style="height: 3%">
                <div class="calendar-header-child">일</div>
                <div class="calendar-header-child">월</div>
                <div class="calendar-header-child">화</div>
                <div class="calendar-header-child">수</div>
                <div class="calendar-header-child">목</div>
                <div class="calendar-header-child">금</div>
                <div class="calendar-header-child">토</div>
              </div>
              <v-calendar
                ref="calendar"
                v-model="date"
                hide-header
                id="calendarMain"
                :type="type"
                :events="events"
                :event-height="20"
                :event-overlap-threshold="5"
                :event-color="getEventColor"
                :event-margin-bottom="10"
                @change="getEvents"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                style="height: 92%;"
              >
                <template v-slot:event="{event}">
                  &nbsp;{{event.name}}
                </template>
                <!-- <template v-slot:day-label="{date}">
                  <div style="text-align: left; margin-left: 10px; font-family: NotoSansBold; font-size: 12px;">{{date.split('-')[2]}}</div>
                </template> -->
              </v-calendar>
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                content-class="calendarExplain"
              >
                <v-card
                  style="max-width: 500px; width: 50vw; height: 100%; border-radius: 15px;"
                  outlined
                  elevation="0"
                >
                  <!-- <v-card-title>상세내용</v-card-title>
                  <v-card-text>시작 시간</v-card-text> -->
                  <div style="position:relative; padding: 30px 0 50px 41px; size: 16px; font-weight: bold;">
                    <div
                      style="display:inline-block; ">
                      상세 내용
                    </div>
                    <v-btn icon text @click="selectedOpen = false"
                      style="position: absolute; right: 30px; top: 32px; width: 13px; height: 13px;">
                      <img src="/asset_day/close.svg"/>
                    </v-btn>
                    <v-btn icon text @click="selectedOpen = false"
                      style="position: absolute; right: 50px; top: 295px; width: 13px; height: 13px;">
                      <!-- TODO -->
                      <!-- 수정 기능의 버튼이다. 현재는 비활성화 시켜두었다. -->
                      <!-- <img src="/asset_day/modify.svg"/> -->
                    </v-btn>
                  </div>

                  <div class="vCard_title_calendar_popup">
                    <span>{{selectedEvent.name}}</span>
                  </div>

                  <v-card-text id="vCardText">
                    <span id="span_explain_title">시작 시간</span>
                    <span id="span_content">{{ selectedEventStart }}</span>
                  </v-card-text>

                  <v-card-text id="vCardText">
                    <span id="span_explain_title">종료 시간</span>
                    <span id="span_content">{{ selectedEventEnd }}</span>
                  </v-card-text>

                  <v-card-text id="vCardText">
                    <span id="span_explain_title_extra">장소</span>
                    <span id="span_content">{{ selectedEvent.place }}</span>
                  </v-card-text>

                  <v-card-text id="vCardText" style="padding: 0;">
                    <span id="span_explain_title_extra">메모</span>
                    <span id="span_content">{{ selectedEvent.memo }}</span>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-menu>

              <v-menu
                v-model="selectedOpenDay"
                :close-on-content-click="false"
                content-class="explainDateMoreCalendar"
              >
                <v-card
                  style="width: 600px; height: 600px; border-radius: 15px;"
                  flat
                >
                  <v-calendar
                    v-model="dateToday"
                    type="day"
                    id="calendarSub"
                    :events="events"
                    :event-color="getEventColor"
                    @click:event="showEvent"
                    style="border-radius: 15px;"
                  >
                    <template v-slot:event="{ event, eventParsed }">
                      <p>{{ event.name }}</p>
                      <p>{{ eventParsed.start.date+" "+eventParsed.start.time }}</p>
                      <p>{{ eventParsed.end.date+" "+eventParsed.end.time }}</p>
                    </template>
                  </v-calendar>
                </v-card>
              </v-menu>
            </div>
          </v-card>
        </div>
      </v-row>
    </div>
    <div
      v-else
      style="width: 100%; height: 100%;"
    >
      <div
        style="height: 5%; display: flex; align-items: center;"
      >
        <div
          style="width: 25px; height: 25px;"
        >
          <img src="/icon_page_state/calendar-line-blue.svg" style="width: 25px; height: 25px;">
        </div>
        <div
          class="ml-3"
          style="display: flex; align-items: center;"
        >
          <div>
            <v-btn
              icon
              v-on:click="sub_1_day()"
            >
              <img src="/asset_day/arrow-left-s-line.svg" />
            </v-btn>
          </div>
          <div>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              content-class="mobile_calendar_date_picker"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="date_text_field"
                  :value="dateText"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                  class="pa-0 ma-0"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="datepicker_date"
                no-title
                scrollable
                type="month"
              >
              </v-date-picker>
            </v-menu>
          </div>
          <div>
            <v-btn
              icon
              @click="add_1_day"
            >
              <img src="/asset_day/arrow-right-s-line.svg" />
            </v-btn>
          </div>
        </div>
      </div>
      <div
        style="height: 10%;"
      >
        <calendar-explain-color/>
      </div>
      <calendar-month-com :parent_date="date"/>
    </div>
  </v-app>
</template>

<script>
import Vue from 'vue'
import Axios from 'axios'
import AlertApi from '../customapi/alertApi.vue'
import CalendarExplainColor from './calendar_explain_color.vue'
import CalendarMonthCom from './CalendarMonthCom.vue'

export default {
  name: 'App',
  components: {
    AlertApi,
    CalendarExplainColor,
    CalendarMonthCom
  },
  data: () => ({
    date: new Date(),
    dateToday: '',
    dateText: '',
    menu: false,
    events: [],
    loading: true,
    type: 'month',
    selectedEventStart: '',
    selectedEventEnd: '',
    selectedEvent: {},
    selectedEventDay: {},
    selectedElement: null,
    selectedOpen: false,
    selectedOpenDay: false,
    url: Vue.prototype.$serverUrl,
    error_flag: false,
    windowWidth: window.innerWidth
  }),
  methods: {
    onResize(){
      this.windowWidth = window.innerWidth
    },
    viewDay ({ date }) {
      
      this.date = new Date(date)
      this.dateToday = new Date(date)

      const open = () => {
        setTimeout(() => {
          this.selectedOpenDay = true
          this.loading = false
        }, 100)
      }

      if (this.selectedOpenDay) {
        this.selectedOpenDay = false
        setTimeout(open, 100)
      } else {
        open()
      }
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedEventStart = this.explain_start_end_parsing(event.start, event.end)[0]
        this.selectedEventEnd = this.explain_start_end_parsing(event.start, event.end)[1]
        this.selectedElement = nativeEvent.target

        setTimeout(() => {
          this.selectedOpen = true
        }, 100)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 100)
      } else {
        open()
      }
    },
    getEvents ({ start, end }) {
      Axios.get(
        this.$getBlueDogUrl() + '/monthSchedule?ymd=' + this.datepicker_date.split('-').join('')
      ).then((response) => {
        this.loading = false

        // 이벤트 표시 완료(바 형태의 표시를 의미)
        const events = []
        for (let i = 0; i < response.data.length; i++) {
          const first = new Date(response.data[i].start)
          const second = new Date(response.data[i].end - 1000)

          events.push({
            title: 'hello',
            icon: 'access_time',
            name: response.data[i].name,
            start: first.toLocaleDateString().split('. ').join('-').slice(0, -1) + ' ' + first.toLocaleTimeString('en-GB', { hour12: false }),
            end: second.toLocaleDateString().split('. ').join('-').slice(0, -1) + ' ' + second.toLocaleTimeString('en-GB', { hour12: false }),
            color: response.data[i].color,
            allDay: true
          })
        }


        this.events = events
      }).catch((error)=>{
        this.loading = false
        this.error_flag = true
      })
    },
    getEventColor (event) {
      return event.color
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    urlYmdFormat (date) {
      const date_when =
        date.getFullYear().toString() +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        date.getDate().toString().padStart(2, '0')
      return date_when
    },
    explain_start_end_parsing (start, end) {
      const st = new Date(start)
      // 밑의 en 에서 1000을 더해준 이유는 getEvents함수를 통해 이벤트를 만들어줄때
      // 1000을 빼서 계산하는 것이 필수적이기 때문에
      // 위에서 해당 처리를 한뒤임으로 밑에서 1000을 더해준다.
      let en = new Date(new Date(end).getTime() + 1000)
      if (en - st < 86400000) {
        return [st.toLocaleDateString() + ' ' + st.toLocaleTimeString().slice(0, -3),
          en.toLocaleDateString() + ' ' + en.toLocaleTimeString().slice(0, -3)]
      } else if (en - st === 86400000) {
        return [st.toLocaleDateString() + ' 종일',
          en.toLocaleDateString()]
      } else if (en - st > 86400000) {
        // 종일인지 알아보는 조건문
        // 일정을 종일로 설정해두면, 오전 12:00:00로 시간이 같게된다.
        if (st.toLocaleTimeString() === en.toLocaleTimeString()) {
          en = new Date(en - 1000)
          return [st.toLocaleDateString() + ' 종일',
            en.toLocaleDateString()]
        } else {
          return [st.toLocaleDateString() + ' ' + st.toLocaleTimeString().slice(0, -3),
            en.toLocaleDateString() + ' ' + en.toLocaleTimeString().slice(0, -3)]
        }
      } else {
        return ['아무것도', '안나옴']
      }
    },
    add_1_day () {
      const add_date = new Date(
        this.date.getFullYear(),
        this.date.getMonth() + 1,
        1,
        1
      )

      this.date = add_date
      this.loading = true
    },
    sub_1_day () {
      const sub_date = new Date(
        this.date.getFullYear(),
        this.date.getMonth() - 1,
        1
      )

      this.date = sub_date
      this.loading = true
    }
  },
  mounted() {
    scrollTo(0,0)
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    datepicker_date: {
      get: function () {
        const month_start = new Date(this.date.getFullYear(), this.date.getMonth(), 1, 9)
        // 밑에서 toISOString()은 GMT로 시간이 맞춰져 있기 떄문에 대략 9시간 정도 차이난다.
        // 그래서 한국시간에서 9시간을 더해주면, 월과 일이 맞춰지기 때문에 9를 넣어주었다.

        const month_end = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)

        const month_range =
          month_start.getFullYear() +
          '.' +
          (month_start.getMonth() + 1) +
          '.' +
          month_start.getDate() +
          ' ~ ' +
          month_end.getFullYear() +
          '.' +
          (month_end.getMonth() + 1) +
          '.' +
          month_end.getDate()

        this.dateText = month_range

        const result = month_start.toISOString().substr(0, 10)

        this.loading = true

        return result
      },
      set: function (v) {
        const a = v.split('-')
        this.date = new Date(a[0], a[1] - 1, 1)
      }
    }
  }
}
</script>

<style scoped>

</style>

<style>

@media (max-width: 960px){
  .calendar_main{
    padding: 20px !important;
  }

  .calendar-div-wrapper{
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

.mobile_calendar_date_picker{
  max-width: unset !important;
}

.calendarExplain{
  /* max-width: 500px !important; */
  max-width: 500px;
  min-width: 50vw !important;
  min-height: 50vh !important;
  left: 30vw !important;
  top: 45vh !important;
  border-radius: 15px;
  box-shadow: none;
}

.calendar-desktop-top-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.top-middle-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.top-middle-child-img{
  width: 24px;
  height: 24px;
  transform: translateY(-13%);
}

.calendar-header{
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}

.calendar-header-child{
  font-family: NotoSansBold;
  font-size: 16px;
  color: #2f2f2f;
}

.explainDateMoreCalendar{
  max-width: none !important;
  left: 20% !important;
  top: 30% !important;
  border-radius: 15px;
}

.vCard_title_calendar_popup{
  width: 100%;
  height: 52px;
  size: 15px;
  color: black;
  padding: 0 40px 30px 40px;
}

#calendarMain > div > div > div.v-event{
  padding-left: 5px;
}

#calendarSub > div > div > div > div > div > div > div.v-event-timed{
  padding-left: 5px;
}
</style>
