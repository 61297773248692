<template>
  <v-card outlined style="width: 100%; height: 750px; overflow: auto;" class="pl-10 pr-10">
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      hide-default-footer
      mobile-breakpoint="755"
      style="width: 100%"
      class="statistics_table"
      :no-data-text="error_flag==true?no_data_text:''"
    >
      <template #header.worktimetotal="{}">
        <div style="display: flex; flex-wrap: wrap; height: inherit; align-items: center; justify-content: center;">
          <div>
             근무 시간
          </div>
          <div>
            (지난주 비교)
          </div>
        </div>
      </template>
      <template v-slot:item.worktimebar="{ item }">
        <div style="width: 100%; height: 100px">
          <div style="padding-top: 30px;">
            <v-progress-linear
              :value="item.worktimebar"
              height="27"
              rounded
              color=""
              class="worktimeProgress"
              v-if="item.worktimebar<76.9"
            />
            <v-progress-linear
              :value="item.worktimebar"
              height="27"
              rounded
              color=""
              class="worktimeProgress_active"
              v-else
            />
          </div>
          <div class="worktime_container_week" style="width: 100%; height: 20px; position: relative; font-family: NotoSans; font-size: 15px; color: #afafaf; padding-top: 9px;">
            <div style="position: absolute; display: inline-block; left: 0px;">0h</div>
            <div style="position: absolute; display: inline-block; right: 23.1%;">40h</div>
            <div style="position: absolute; display: inline-block; right: 0px;">52h</div>
          </div>
        </div>
      </template>
      <template v-slot:item.worktimetotal="{item}">
        <div style="display: flex; flex-wrap: wrap; height: inherit; align-items: center; justify-content: center;">
          <div>
             {{item.worktimetotal+" "}}
          </div>
          <div>
            {{"("+item.compare_last_week+")"}}
          </div>
        </div>
        <!-- <div>{{item.worktimetotal+" "+"("+item.compare_last_week+")"}}</div> -->
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default {
  props: ['this_week', 'showDeleted'],
  data () {
    return {
      url: Vue.prototype.$serverUrl,
      items: [],
      loading: true,
      date: new Date(this.this_week),
      header_css: 'font-statistics-data-table-header',
      headers: [
        {
          text: '이름',
          value: 'name',
          align: 'center',
          width: '12%'
        },
        {
          text: '주간 근무 시간',
          value: 'worktimebar',
          align: 'center',
          width: '50%'
        },
        {
          text: '근무 시간(지난주 비교)',
          value: 'worktimetotal',
          align: 'center',
          width: '23%'
        },
        {
          text: '초과 근무',
          value: 'worktimeover',
          align: 'center',
          width: '15%'
        }
      ],
      no_data_text: Vue.prototype.$serverAccessFailedTableNoResultText,
      error_flag: false
    }
  },
  mounted () {
    this.readDataAPI()
  },
  methods: {
    change_status () {
      const el = document.querySelector('div.worktimeProgress')
      el.className = 'v-progress-linear worktimeProgress_active v-progress-linear--rounded theme--light'
    },
    readDataAPI () {
      // guenTae로 부터 받아온 props는 한 '주'의 시작인 일요일 값을 가져온다.
      // 따라, 다음과 같이 하루 더해주어야 한다.
      this.date.setTime(this.date.getTime() + 86400000)

      const date_when = this.date_parsing(this.date) // 이번주 월요일

      let date_when_after = new Date()

      date_when_after.setTime(this.date.getTime() + 4 * 86400000)

      date_when_after = this.date_parsing(date_when_after) // 이번주 금요일

      let last_week_start = new Date() // 저번주 월요일

      let last_week_end = new Date() // 저번주 금요일

      last_week_start.setTime(this.date.getTime() - 7 * 86400000)

      last_week_end.setTime(this.date.getTime() - 3 * 86400000)

      last_week_start = this.date_parsing(last_week_start)
      last_week_end = this.date_parsing(last_week_end)

      axios
        .all([
          axios.get(
            this.$getBlueDogUrl() + '/checkTimeWeek?when1=' + last_week_start + '&when2=' + last_week_end + '&showDeleted=' + this.showDeleted
          ),
          axios.get(
            this.$getBlueDogUrl() + '/checkTimeWeek?when1=' + date_when + '&when2=' + date_when_after + '&showDeleted=' + this.showDeleted
          )
        ])
        .then(
          axios.spread((obj1, obj2) => {
            this.items = []

            // 이름과 일한 시간을 비교하기 위해
            // 해당 값들을 객체로 가지고 있는 배열(이름, [일한시간1, 일한시간2...])
            const obj1_item_array = []

            for (let i = 0; i < obj1.data.length; i++) {
              const obj1_object = {}

              obj1_object.name = obj1.data[i].name
              obj1_object.worktimetotal = this.calc_work_time(
                obj1.data[i].work_time_week
              )

              obj1_item_array.push(obj1_object)
            }

            this.loading = false

            for (let i = 0; i < obj2.data.length; i++) {
              const item = {}

              item.name = obj2.data[i].name
              item.worktimetotal = this.calc_work_time(obj2.data[i].work_time_week)
              for (let i = 0; i < obj1_item_array.length; i++) {
                // 저번주에 일한 사람과 이번주에 일한 사람의 시간을 비교해야 함으로
                // 이름이 같다면 이라는 조건문을 넣어두었다.
                if (item.name === obj1_item_array[i].name) {
                  item.compare_last_week = item.worktimetotal - obj1_item_array[i].worktimetotal
                }
              }

              // 근무 시간 표시하는 progress
              if (item.worktimetotal > 3120) {
                item.worktimebar = 100
              } else {
                item.worktimebar = item.worktimetotal * (100 / 3120)
              }

              if (item.worktimetotal >= 2400) {
                item.worktimeover = this.calc_time_unit(item.worktimetotal - 2400)
              } else {
                item.worktimeover = '0h 0m'
              }

              item.worktimetotal = this.calc_time_unit(item.worktimetotal)
              item.compare_last_week = this.bigger_then_zero_for_work(item.compare_last_week)

              this.items.push(item)
            }
          })
        ).catch((error)=>{
          this.loading = false;
          this.error_flag = true;
          this.child_to_parent(this.error_flag)
        })
    },
    child_to_parent(child_data){
      this.$emit('error_flag', child_data)
    },
    calc_work_time (arr) {
      let sum = 0
      for (let i = 0; i < arr.length; i++) {
        sum += arr[i]
      }
      sum = sum / 60000 // 시간을 구하기 위해 시간만큼 나눔(분단위)

      return sum
    },
    date_parsing (date) {
      const result =
        date.getFullYear().toString() +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        date.getDate().toString().padStart(2, '0')
      return result
    },
    calc_time_unit (time) {
      // Math.abs()는 절댓값을 반환해주는 동작이다.
      // 저번주와 이번주를 비교할때, 저번주 시간이 더 많아서 time을 음수로 받을 수 있다.
      // 따라서, 절댓값으로 비교해주어 표시를 해주고자 한다.
      const MathAbsTime = Math.abs(time)

      if (MathAbsTime < 60) return '0h ' + parseInt(MathAbsTime % 60) + 'm'
      // 정수로 안나눠질 때가 있기 때문에, 해당 값을 int화 해주어야 한다.
      else return parseInt(MathAbsTime / 60) + 'h ' + parseInt(MathAbsTime % 60) + 'm'
    },
    bigger_then_zero_for_work (data) {
      if (data > 0) {
        return ' + ' + this.calc_time_unit(data)
      } else if (data === '0.0') {
        return ' 0'
      } else {
        return ' - ' + this.calc_time_unit(data)
      }
    }
  },
  watch: {
    this_week: {
      handler () {
        this.date = new Date(this.this_week)
        this.loading = true
        this.readDataAPI()
      }
    },
    showDeleted: {
      handler () {
        this.date = new Date(this.this_week)
        this.loading = true
        this.readDataAPI()
      }
    },
  }
}
</script>

<style>
/* workTime을 나타내는 테이블 header 변경 css */
.statistics_table .v-data-table__wrapper > table > thead > tr > th {
  font-family: NotoSansBold;
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.statistics_table .v-data-table__wrapper > table > thead > tr th {
  border-bottom: 1px #979797 solid !important;
}

.statistics_table .v-data-table__wrapper > table > tbody > tr > td {
  font-family: NotoSans;
  font-size: 15px;
  color: #2f2f2f;
}

/* workTime을 나타내는 테이블 border 변경 css */
.statistics_table .v-data-table__wrapper > table > tbody > tr td {
  border: none !important;
}

/* workTime을 나타내는 테이블 name 변경 css */
.statistics_table .v-data-table__wrapper > table > tbody > tr > td:first-child {
  font-family: NotoSansBold;
  font-size: 15px;
  color: black;
}

/* workTime을 나타내는 테이블 progress 변경 css */
.v-progress-linear{
  border-radius: 13px;
  background-color: #dfdfdf !important;
}

.v-progress-linear .v-progress-linear__background{
  background-color: #dfdfdf !important;
  border: 1px #dfdfdf solid !important;
}

.worktimeProgress .v-progress-linear__determinate{
  border-radius: 13px;
  background-color: #5a7fff !important;
  border: 1px #5a7fff solid !important;
}

.worktimeProgress_active .v-progress-linear__determinate{
  border-radius: 13px;
  background-color: #ffb85a !important;
  border: 1px #ffb85a solid !important;
}

.statistics_table .v-data-table__mobile-row__cell{
  width: 100%;
}

@media (max-width: 754px) {
  .statistics_table .v-data-table__wrapper > table > tbody > tr td:last-child {
     border-bottom: solid 1px #dfdfe0 !important;
  }
  .statistics_table .v-data-table__wrapper > table > tbody > tr td:first-child .v-data-table__mobile-row__header,
  .statistics_table .v-data-table__wrapper > table > tbody > tr td:last-child .v-data-table__mobile-row__header {
     width: -webkit-fill-available !important;
  }
}

@media (max-width: 450px) {
  .worktime_container_week{
    font-size: 11px !important;
  }
}

</style>

<style scoped>
.v-progress-linear--rounded{
  border-radius: 10px;
}
</style>
