<template>
  <v-app>
    <v-main id="v_main_admin" style="padding: 70px 100px 189px 100px;">
      <alert-api
        :accessed="error_flag"
      ></alert-api>
      <v-container fluid class="pa-0 d-flex">
        <admin-check :bottom_items="bottom_item" :accessed="passwordConfirm"/>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <v-card
              outlined
            >
              <v-card-title class="justify-center">
                관리자 암호를 입력해 주세요
              </v-card-title>
              <v-card-text>
                <v-form class="d-flex justify-center">
                  <v-text-field
                    ref="password"
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="showPassword? 'text':'password'"
                    :rules="[()=>passwordRule || (`비밀번호가 틀렸습니다.`)]"
                    outlined
                    clearable
                    required
                    :autofocus="true"
                    @keydown.enter="login"
                    @click:append="showPassword = !showPassword"
                    style="max-width: 70%"
                  >
                  </v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="d-flex flex-column">
                <div>
                  <v-spacer></v-spacer>
                  <div class="d-flex justify-center" style="width: 100%;">
                    <v-btn
                      align-center
                      justify-center
                      :loading="loading"
                      @click="login"
                    >
                      Login
                    </v-btn>
                  </div>
                </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped>
@media (max-width: 960px) {
  #v_main_admin{
    padding: 20px !important;
  }
}
</style>

<script>
import Axios from 'axios'
import Vue from 'vue'
import AdminCheck from './adminCheck.vue'
import alertApi from '../customapi/alertApi.vue'

export default {
  components: {
    AdminCheck, alertApi
  },
  data: () => ({
    dialog: true,
    loading: false,
    bottom_loading: true,
    bottom_item: [],
    password: '',
    passwordRule: true, // 비밀번호가 틀렸다는 걸 표시하기 위한 RULE FLAG
    passwordConfirm: false, // 비밀번호가 맞으면 데이터를 보여주기 위한 FLAG
    showPassword: false,
    url: Vue.prototype.$serverUrl,
    error_flag: false
  }),
  methods: {
    login() {
      this.loading = true
      Axios.get(this.$getBlueDogUrl() + '/adminCheckPW?password=' + this.password).then((response) => {
        this.loading = false
        if (response.data) {
          Vue.prototype.$adminDialog = false
          this.dialog = false
          this.passwordConfirm = true
          this.bottom_card_data_getUserList()
        } else {
          this.passwordRule = false
          this.password = ''
          setTimeout(()=>{
            this.passwordRule = true
          }, 10)
        }
      }).catch((error)=>{
        this.loading = false
        this.error_flag = true
        this.password = ''
      })
    },
    bottom_card_data_getUserList: function () {
      Axios.get(this.$getBlueDogUrl() + '/getUserList').then((response) => {
        this.bottom_item = response.data
      })
    },
    // admin에서 로그인했을때 유지해주는 장치
    basis_condition: function () {
      this.dialog = Vue.prototype.$adminDialog
    }
  },
  mounted () {
    scrollTo(0,0)
    this.basis_condition()

    // 관리자로 로그인 한뒤 다른 창으로 갔다가 돌아왔을때 데이터가 없는데
    // 그 이유는, 로직이 처음에 로그인에 성공했을때 데이터를 받게끔 되어있어서 그런것이다.
    // 즉, 밑의 조건문에서 전에 관리자로 로그인했었다는걸 확인해준다.
    if (Vue.prototype.$adminDialog == false) {
      this.bottom_card_data_getUserList()
      this.passwordConfirm = true
    }
  },
  watch:{
  }
}
</script>
