<template>
  <v-list class="vListMonthBottom" style="height: 100%; overflow: auto" >
    <v-list-item v-for="item in item_multiple_block" :key="item.date" style="min-height: 30px; height: 30px; margin-bottom: 10px;">
      <div
        v-if="item.check_block"
        style="display: flex; flex-wrap: wrap; font-size: 13px; flex: 1 1; "
      >
        <div style="font-family: NotoSansBold;">{{item.start}}</div>
        <div style="font-family: NotoSansBold;">~</div>
        <div style="font-family: NotoSansBold;">{{item.end}}</div>
      </div>
      <v-divider vertical style="margin-right: 10px;"></v-divider>
      <v-list-item-content
        style="flex: 2 1;"
      >
        <v-list-item-subtitle
          v-text="item.content"
        ></v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-for="(item,index) in item_single_block" :key="index" style="min-height: 30px; height: 30px;">
      <div
        style="display: flex; flex-wrap: wrap; font-size: 13px; flex: 1 1;"
      >
        <div 
          v-if="item.check_block"
          style="font-family: NotoSansBold;"
        >
          {{item.start}}
        </div>
      </div>
      <v-divider v-if="item_single_block.length == index" vertical style="margin-right: 10px;"></v-divider> 
      <v-divider v-else vertical style="margin-right: 10px;"></v-divider>
      <!-- <v-divider vertical style="margin-right: 10px;"></v-divider> -->
      <v-list-item-content
        style="flex: 2 1;"
      >
        <v-list-item-subtitle 
          v-text="item.content"
        >
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <div
      v-if="item_single_block.length == 0 && item_multiple_block.length == 0 && !error"
      style="font-family: NotoSansBold; font-size: 13px; display: flex; justify-content: center; color: rgba(0,0,0,0.6);"
    >일정 없음</div>
    <div
      v-if="item_single_block.length == 0 && item_multiple_block.length == 0 && error"
      style="font-family: NotoSansBold; font-size: 13px; display: flex; justify-content: center; color: rgba(0,0,0,0.6);"
    >
      {{no_result_text}}
    </div>
  </v-list>
</template>

<script>
import Vue from 'vue'

export default {
  props: ['items', 'search', 'error'],
  data () {
    return {
      item_single_block: [],
      item_multiple_block: [],
      no_result_text: Vue.prototype.$serverAccessFailedTableNoResultText
    }
  },
  methods: {
    seperating (items, search) {
      this.item_single_block = []
      this.item_multiple_block = []

      let single_date_block_flag = true
      let multiple_date_block_flag = true

      for (const item in items) {
        if (items[item].start <= search && search <= items[item].end) {
          if (items[item].start == items[item].end) {
            if (single_date_block_flag) {
              items[item].check_block = true
              this.item_single_block.push(items[item])
              single_date_block_flag = false
            } else {
              this.item_single_block.push(items[item])
            }
          } else {
            this.item_multiple_block.push(items[item])
          }
        }
      }

      this.item_multiple_block.sort(function (a, b) {
        return a.end < b.end ? -1 : a.end > b.end ? 1 : 0
      })

      for (const item_multiple in this.item_multiple_block) {
        if (multiple_date_block_flag) {
          this.item_multiple_block[item_multiple].check_block = true
          multiple_date_block_flag = false
        }

        if (item_multiple < this.item_multiple_block.length - 1) {
          if (this.item_multiple_block[item_multiple].start === this.item_multiple_block[parseInt(item_multiple) + 1].start &&
                    this.item_multiple_block[item_multiple].end === this.item_multiple_block[parseInt(item_multiple) + 1].end) {
            this.item_multiple_block[parseInt(item_multiple) + 1].check_block = false
          } else {
            multiple_date_block_flag = true
          }
        }
      }
    }
  },
  mounted () {
    // 월간일정 tab 누를때 동작
    // console.log("mounted");
  },
  watch: {
    items: {
      handler () {
      }
    },
    search: {
      handler () {
        this.seperating(this.items, this.search)
      }
    }
  }
}
</script>

<style>

</style>
