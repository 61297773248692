<template>
  <v-card outlined style="width: 100%; height: 400px; overflow: auto;" class="pl-10 pr-10">
    <v-data-table
      :headers="headers"
      :items="date"
      :loading="loading"
      sort-by="time"
      hide-default-footer
      mobile-breakpoint="960"
      :no-data-text="error_flag==true?no_data_text:''"
      :footer-props="{
          itemsPerPageOptions: [-1],
      }"
      style="width: 100%"
      class="statistics_table_sch_month"
    >
      <template v-slot:item.time="{ item }">
        <div
          v-if="item.time.date_difference_cond == 'one_day'"
          style="padding: 10px 0 10px; min-width: 140px !important; max-width: 140px !important;"
        >
          <div style="font-family: NotoSansBold;">{{item.time.start_date}}</div>
          <div style="display: flex; flex-wrap: wrap; align-items: center; font-size: 13px; color: #5a7fff; justify-content: space-between;">
            <div style="font-family: NotoSansBold;">{{item.time.start_time}}</div>
            <div style="font-family: NotoSansBold;">~</div>
            <div style="font-family: NotoSansBold;">{{item.time.end_time}}</div>
          </div>
        </div>
        <div
          v-if="item.time.date_difference_cond == 'all_day'"
          style="padding: 10px 0 10px; min-width: 150px !important; max-width: 150px !important;"
        >
          <div style="font-family: NotoSansBold;">{{item.time.start_date}}</div>
          <div style="font-family: NotoSansBold; color: #5a7fff; font-size: 13px;">{{item.time.all_day}}</div>
        </div>
        <div
          v-if="item.time.date_difference_cond == 'two_or_more_days'"
          style="padding: 10px 0 10px; display: flex; justify-content: space-between; min-width: 200px !important; max-width: 200px !important;"
        >
          <div style="font-family: NotoSansBold;">{{item.time.start_date}}</div>
          <div style="font-family: NotoSansBold;">~</div>
          <div style="font-family: NotoSansBold;">{{item.time.end_date}}</div>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default {
  props: ['given_date'],
  data () {
    return {
      date: [],
      loading: true,
      headers: [
        {
          text: '시간',
          value: 'time',
          align: 'center',
          width: '13%'
        },
        {
          text: '제목',
          value: 'title',
          align: 'center',
          width: '22%'
        },
        {
          text: '장소',
          value: 'place',
          align: 'center',
          width: '35%'
        },
        {
          text: '메모',
          value: 'memo',
          align: 'center',
          width: '30%'
        }
      ],
      error_flag: false,
      no_data_text: Vue.prototype.$serverAccessFailedTableNoResultText
    }
  },
  methods: {
    readDataAPI () {
      const ymd = this.url_ymd_format(new Date(this.given_date))

      axios.get(this.$getBlueDogUrl() + '/monthSchedule?ymd=' + ymd)
      .then((response) => {
        this.date = []

        this.loading = false
        for (let i = 0; i < response.data.length; i++) {
          const data = response.data[i]

          const item = {}

          item.title = data.name
          item.place = data.place
          item.memo = data.memo
          item.time = this.time_format(data.start, data.end)

          this.date.push(item)
        }
      }).catch((error)=>{
        this.loading=false
        this.error_flag=true
        this.child_to_parent(this.error_flag)
      })
    },
    child_to_parent(child_data){
      this.$emit('error_flag', child_data)
    },
    url_ymd_format (date) {
      const date_when =
        date.getFullYear().toString() +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        date.getDate().toString().padStart(2, '0')
      return date_when
    },
    date_parsing_with_dot (date) {
      const result =
        date.getFullYear().toString() + '. '+
        (date.getMonth() + 1).toString().padStart(2, '0') + '. '+
        date.getDate().toString().padStart(2, '0')
      return result
    },
    time_format (start, end) {
      let st = new Date(start)
      let en = new Date(end)

      let data_type = {
        date_difference_cond: null,
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        all_day: null,
      }

      if (en - st < 86400000) { // 하루 일정 (종일x)
        data_type.date_difference_cond = 'one_day'
        data_type.start_date = this.date_parsing_with_dot(st)
        data_type.start_time = this.calc_time(st)
        data_type.end_time = this.calc_time(en)
        return data_type
      } else if (en - st == 86400000) { // 하루 일정 (종일o)
        data_type.date_difference_cond = 'all_day'
        data_type.start_date = this.date_parsing_with_dot(st)
        data_type.all_day = "종일"
        return data_type
      } else { // 이틀이상 일정
        en = new Date(en - 1000)
        data_type.date_difference_cond = 'two_or_more_days'
        data_type.start_date = this.date_parsing_with_dot(st)
        data_type.end_date = this.date_parsing_with_dot(en)
        return data_type
      }
    },
    calc_time (date) {
      const apm = date.getHours() < 12 ? '오전' : '오후'
      let hour = (hour = date.getHours() % 12) ? hour : 12
      const minute = date.getMinutes().toString().padStart(2, '0')
      return apm + ' ' + hour + ':' + minute
    },
    yyyy_mm_dd (_time) {
      const day = new Date(_time)
      const year = day.getFullYear()
      const month = day.getMonth() + 1
      const date = day.getDate()
      return year + '. ' + month + '. ' + date
    }
  },
  mounted () {
    this.readDataAPI()
  },
  watch: {
    given_date: {
      handler () {
        this.loading = true
        this.readDataAPI()
      }
    }
  }
}
</script>

<style>
.statistics_table_sch_month td{
  border-bottom: unset !important;
}

@media (max-width: 960px) {
  .statistics_table_sch_month tr td:last-child{
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  }

  .statistics_sch_month_table_time_wrapper{
    text-align: right !important;
  }
}

.statistics_sch_month_table_time_wrapper{
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

</style>
