<template>
  <v-card outlined width="100%" style="overflow: unset !important; height: 1000px !important;">
    <div
      v-if="windowWidth>420"
      style="width: 85%; height: 15%; display: flex; align-items: center;  justify-content: space-between; margin: 0 auto;"
    >
      <div style="width: 150px;">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="직원 이름"
          outlined
          rounded
          dense
          hide-details
        ></v-text-field>
      </div>
      <v-btn
        @click="onexport()"
        color="#5a7fff"
        :disabled="excel_flag"
        style="width: 165px; height: 40px; color: #ffffff;"
      >
        Excel 파일 저장
      </v-btn>
    </div>
    <div
      style="width: 85%; height: 15%; margin: 0 auto;"
      v-else>
      <div style="width: 100%; display: flex; align-items:center; justify-content:flex-end; margin-bottom: 10px; padding-top: 10px;">
        <v-btn
          @click="onexport()"
          color="#5a7fff"
          :disabled="excel_flag"
          style="width: 165px; height: 40px; color: #ffffff;"
        >
          Excel 파일 저장
        </v-btn>
      </div>
      <div style="width: 100%; display: flex; align-items:center; justify-content:flex-start;">
        <div style="width: 150px;">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="직원 이름"
            outlined
            rounded
            dense
            hide-details
          ></v-text-field>
        </div>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      hide-default-header hide-default-footer
      :fixed-header="true"
      :item-class="funcItemClass"
      mobile-breakpoint="0"
      :search="search"
      :footer-props="{
        itemsPerPageOptions: [-1], // 모든 내용을 보여준다는 의미 all
      }"
      :no-data-text="error_flag==true?no_data_text:''"
      style="
        overflow: auto;
        width: 85%;
        height: 75%;
        margin: 0px auto;
        border: 1px solid #dddddd !important;
      "
      class="gantt_month_table"
    >
      <template #header="{}">
        <thead class="v-data-table-header">
          <tr>
            <th v-for="(header,i) in headers" :key="i" class="text-center parent-header td-border-style" :style="`min-width:`+header.width">
              {{header.text}}
            </th>
          </tr>
          <tr>
            <th v-for="(h1,i1) in getSubHeader(headers)" :key="i1" class="text-center child-header td-border-style" style="background-color: #fff8e6">
              <!--i1은 인덱스로써, 처음 7개는 고정되는 값으로써, 조정할 필요 없다.-->
              <span v-if="i1<7">{{h1.text}}</span>
              <ul class="ul_without_dot" v-else>
                <li v-for="ele in h1.text" :key="ele">
                  <v-tooltip bottom v-if="ele.length > 5">
                    <template #activator="{on, attrs}">
                      <v-btn icon v-bind="attrs" v-on="on" style="font-size: 12px;" height="18px">
                        <div class="truncate_text_class">{{truncate_text(ele)}}</div>
                      </v-btn>
                    </template>
                    <div>{{ele}}</div>
                  </v-tooltip>
                  <div v-else>{{ele}}</div>
                </li>
              </ul>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item.late="{ item }">
        <v-tooltip
          bottom
          v-if="typeof item.late_arr != `undefined` && item.late_arr.length > 0"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              {{ item.late }}
            </v-btn>
          </template>
          <span v-for="item in item.late_arr" :key="item">
            {{ item }}
          </span>
        </v-tooltip>
        <span v-else>
          {{ item.late }}
        </span>
      </template>
      <template v-slot:item.absent="{ item }">
        <v-tooltip
          bottom
          v-if="typeof item.absent_arr != `undefined` && item.absent_arr.length > 0"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              {{ item.absent }}
            </v-btn>
          </template>
          <span v-for="item in item.absent_arr" :key="item">
            {{ item }}
          </span>
        </v-tooltip>
        <span v-else>
          {{ item.absent }}
        </span>
      </template>
      <template v-slot:item.outwork="{ item }">
        <v-tooltip
          bottom
          v-if="
            typeof item.outwork_arr != `undefined` &&
            item.outwork_arr.length > 0
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              {{ item.outwork }}
            </v-btn>
          </template>
          <span v-for="item in item.outwork_arr" :key="item">
            {{ item }}
          </span>
        </v-tooltip>
        <span v-else>
          {{ item.outwork }}
        </span>
      </template>
      <template v-for="day in this.days" v-slot:[`item.`+day]="{ item }">
        <div :key="day">
          <div
            v-if="item[day] != undefined && item[day].start_time != undefined"
          >
            {{ item[day].start_time }}
          </div>
          <div v-else style="height: 21px"></div>
          <div v-if="item[day] != undefined && item[day].end_time != undefined">
            {{ item[day].end_time }}
          </div>
          <div v-else style="height: 21px"></div>
          <div
            v-if="item[day] != undefined && item[day].status != undefined"
            :style="`backgroundColor:` + item[day].status_color"
          >
            {{ item[day].status }}
          </div>
          <div v-else style="height: 21px"></div>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<style>
.ul_without_dot {
  padding-left: 0 !important;
  list-style: none;
}

.truncate_text_class{
  font-weight: 700 !important;
}

/* 테이블 줄 생기게끔 변경 */
.gantt_month_table th,
.gantt_month_table td {
  border-left: 1px solid #dddddd !important;
  border-bottom: 1px solid #dddddd !important;
}

.gantt_month_table .v-data-table__wrapper > table > tbody > tr > td:first-child {
  font-family: NotoSansBold;
  font-size: 15px;
  color: black;
}

/* 각 cell의 padding 제거 */
.itemClass > td {
  padding: 0px !important;
}

/* 스크롤바 제어를 위해 */
.gantt_month_table > div {
  overflow: unset !important;
}

/* 상태(지각, 결근, 등..) */
.gantt_status_check {
  background-color: #cae2ff;
}
</style>

<script>
import Vue from 'vue'
import axios from 'axios'
import XLSX from 'xlsx'

export default {
  props: ['this_month', 'showDeleted'],
  data () {
    return {
      url: Vue.prototype.$serverUrl,
      monthOrYear: 'month',
      date: new Date(this.this_month),
      items: [],
      days: [],
      loading: true,
      excel_flag: true,
      search: '',
      headers: [],
      excel_days_ymd: [],
      excel_data: {
        office_day: [],
        office_month: []
      },
      error_flag: false,
      no_data_text: Vue.prototype.$serverAccessFailedTableNoResultText,
      windowWidth: window.innerWidth,
    }
  },
  methods: {
    funcItemClass () {
      return 'itemClass'
    },
    readDataApi () {
      // urlYmdFormat(date) -> yyyyMMdd
      const ymd = Vue.prototype.$urlYmdFormat(this.date)

      axios
        .all([
          axios.get(
            this.$getBlueDogUrl() + '/checkStatisticsGanttMonth?date=' + ymd + '&&' + 'monthOrYear=' + this.monthOrYear  + '&showDeleted=' + this.showDeleted
          ),
          axios.get(
            this.$getBlueDogUrl() + '/checkStatisticsGanttMonthAvg?date=' + ymd + '&&' + 'monthOrYear=' + this.monthOrYear  + '&showDeleted=' + this.showDeleted
          ),
          axios.get(
            this.$getBlueDogUrl() + '/checkStatisticsGanttMonthAvgPerPeople?date=' + ymd  + '&showDeleted=' + this.showDeleted
          )
        ])
        .then(
          axios.spread((obj1, obj2, obj3) => {
            this.items = []

            // 평균값을 multiheader로 구현하기 위해 아래와 같이 모델의 값을 받아두었다.
            // 아래의 동적인 headers를 이용하여, 그들의 속성에 children으로 값을 받을 것이다.
            const obj2_data = obj2.data.targetGanttModelAvg
            const obj3_data = obj3.data

            // headers를 동적으로 추가해주기 때문에 아래와 같이, 매번 headers를 갱신한다.
            // 아래와 같이 하지 않으면, 검색하는 month(prop인 this_month)가 변경될때마다
            // 열이 data에 들어가 추가되어, 저번달과 이번달의 열이 더해지게 된다.
            this.headers = [
              {
                text: '이름',
                value: 'name',
                align: 'center',
                width: '99px',
                children: [{ text: '' }]
              },
              {
                text: '근무 시간',
                value: 'officetime',
                align: 'center',
                width: '115px',
                children: [{ text: obj2_data.avg_officetime }]
              },
              {
                text: '휴가',
                value: 'leave',
                align: 'center',
                width: '82px',
                children: [{ text: obj2_data.avg_leave }]
              },
              {
                text: '초과근무',
                value: 'overwork',
                align: 'center',
                width: '96px',
                children: [{ text: obj2_data.avg_overwork }]
              },
              {
                text: '지각',
                value: 'late',
                align: 'center',
                width: '82px',
                children: [{ text: obj2_data.sum_late }]
              },
              {
                text: '결근',
                value: 'absent',
                align: 'center',
                width: '82px',
                children: [{ text: obj2_data.sum_absent }]
              },
              {
                text: '외근',
                value: 'outwork',
                align: 'center',
                width: '82px',
                children: [{ text: obj2_data.sum_outwork }]
              }
            ]
            this.days = []
            this.loading = false
            this.days = obj1.data.periodData.days
            this.excel_days_ymd = obj1.data.periodData.excel_days

            // 일자(요일) v-datatable column 생성 시작
            for (var index in obj1.data.periodData.days) {
              const head_json_temp = {
                text: '',
                value: '',
                align: 'center',
                width: '85px',
                sortable: false // TODO 나중에 바꿀수도 있음
              }

              head_json_temp.text = obj1.data.periodData.days[index]
              head_json_temp.value = obj1.data.periodData.days[index]

              // 밑의 한줄은 multiheader에 대한 처리를 위한 것이며, `일(요일)`에 대한 처리이다.
              head_json_temp.children = [{ text: obj2_data.map_month_sch[head_json_temp.value] }]

              this.headers.push(head_json_temp)
            }
            // 일자(요일) v-datatable column 생성 끝

            // 날을 이동할때 데이터가 중복 추가되는 오류 발생
            // 따라서, 다음과 같이 엑셀 데이터에 대해 초기화를 진행
            this.excel_data = {
              office_day: [],
              office_month: []
            }

            // excel_month 월 평균 -> 열 생성 및 전체 평균 데이터 추가
            const excel_avg_item_header = {}
            excel_avg_item_header.이름 = '전체' // 빈칸은 이름을 위해 비워둠
            excel_avg_item_header.평균출근시간 = obj3_data.avg_mean_map.avg_mean_start_time
            excel_avg_item_header.평균퇴근시간 = obj3_data.avg_mean_map.avg_mean_end_time
            excel_avg_item_header.월간근무 = obj2_data.avg_officetime
            excel_avg_item_header.초과근무 = obj2_data.avg_overwork
            excel_avg_item_header.휴가평균 = obj2_data.avg_leave
            excel_avg_item_header.결근 = obj2_data.sum_absent
            excel_avg_item_header.지각 = obj2_data.sum_late
            excel_avg_item_header.외근 = obj2_data.sum_outwork

            this.excel_data.office_month.push(excel_avg_item_header)
            // excel_month 월 평균 -> 열 생성 및 전체 평균 데이터 추가 끝

            // 나중에 concat을 통해 배열을 합치기 위한 변수 선언
            const excel_temp_arr = []

            // data-table 데이터 입력 및, excel 출력을 위한 데이터 입력
            for (var index in obj1.data.ganttContentData) {
              const data = obj1.data.ganttContentData[index]

              const item = {}
              const excel_avg_item_element = {}

              // array 아닌 value(주석 중요하지 않음, 가독성 위해)
              item.name = data.name
              item.officetime = data.officetime
              item.overwork = data.overwork
              item.leave = data.leave + '일'

              // array 인 value
              item.absent = data.arr_absent.length + '회'
              item.absent_arr = this.item_arr_data_process(data.arr_absent)
              item.late = data.arr_late.length + '회'
              item.late_arr = this.item_arr_data_process(data.arr_late)
              item.outwork = data.arr_outwork.length + '회'
              item.outwork_arr = this.item_arr_data_process(data.arr_outwork)

              // excel_month 데이터 처리(월간 평균값 및 사람 각각의 평균)
              excel_avg_item_element.이름 = data.name
              excel_avg_item_element.평균출근시간 = obj3_data[data.name].avg_start_time
              excel_avg_item_element.평균퇴근시간 = obj3_data[data.name].avg_end_time
              excel_avg_item_element.월간근무 = data.officetime
              excel_avg_item_element.초과근무 = data.overwork
              excel_avg_item_element.휴가평균 = data.leave + '회'
              excel_avg_item_element.결근 = data.arr_absent.length + '회'
              excel_avg_item_element.지각 = data.arr_late.length + '회'
              excel_avg_item_element.외근 = data.arr_outwork.length + '회'

              this.excel_data.office_month.push(excel_avg_item_element)

              // 아래 format으로 `한달` 전부에 대해서 excel데이터로 넣기위한 init
              // ex) [이름: "누군가의 이름", 날짜: "일(요일)", 출근시간: "", 퇴근시간: "", 상태: ""]

              for (const key in data.dayDataMap) {
                // 일(요일) 헤더에 대한 데이터 처리(html v-data-table)
                item[key] = data.dayDataMap[key]
              }

              const excel_each_arr = this.excel_data_init(item.name, this.excel_days_ymd)

              for(const key in data.dayDataYmdMap) {
                // excel_each_arr는 일(요일) 데이터가 모두 존재함으로
                // 해당 데이터에 대해서 api로 받은 값에 대해 key값과 `일(요일)`을 찾아서
                // 존재한다면 해당 값에 출근시간, 퇴근시간, 상태 등을 적어주게끔 한다.
                excel_each_arr.forEach((e)=>{this.excel_data_push(e, data.dayDataYmdMap, key)})
              }

              // concat을 시키기 위한 배열에 추가
              excel_temp_arr.push(excel_each_arr)

              this.items.push(item)
            }

            // XLSX lib는 하나의 배열이 하나의 sheet를 의미함으로
            // 미리 선언해두었던 this.excel_data.office_day에 excel_temp_arr을 concat으로 합치면서 넣어준다.
            for (const index in excel_temp_arr) {
              this.excel_data.office_day = this.excel_data.office_day.concat(excel_temp_arr[index])
            }

            this.excel_flag = false
          })
        ).catch((error)=>{
          this.loading = false;
          this.error_flag = true
          this.excel_flag = true
          this.child_to_parent(this.error_flag)
        })
    },
    child_to_parent(child_data){
      this.$emit('error_flag', child_data)
    },
    excel_data_init (coworker, arr_period) {
      const arr = []

      for (var index in arr_period) {
        const excel_init_item = {}
        excel_init_item.이름 = coworker
        excel_init_item.날짜 = arr_period[index]
        excel_init_item.출근시간 = ''
        excel_init_item.퇴근시간 = ''
        excel_init_item.상태 = ''

        arr.push(excel_init_item)
      }

      return arr
    },
    excel_data_push (element, item, key) {
      if (element.날짜.indexOf(key) > -1) {
        element.출근시간 = item[key].start_time
        element.퇴근시간 = item[key].end_time
        element.상태 = item[key].status
      }
    },
    add_drag_scroll (ele) {
      let pos = { top: 0, left: 0, x: 0, y: 0 }
      ele.style.cursor = 'grab'
      const mouseDownHandler = (e) => {
        ele.style.cursor = 'grabbing'
        ele.style.userSelect = 'none'

        pos = {
          left: ele.scrollLeft,
          top: ele.scrollTop,
          x: e.clientX,
          y: e.clientY
        }

        document.addEventListener('mousemove', mouseMoveHandler)
        document.addEventListener('mouseup', mouseUpHandler)
      }

      const mouseMoveHandler = (e) => {
        const dx = e.clientX - pos.x
        const dy = e.clientY - pos.y

        ele.scrollLeft = pos.left - dx
        ele.scrollTop = pos.top - dy
      }

      const mouseUpHandler = (e) => {
        ele.style.cursor = 'grab'
        ele.style.userSelect = ''

        document.removeEventListener('mousemove', mouseMoveHandler)
        document.removeEventListener('mouseup', mouseUpHandler)
      }

      ele.addEventListener('mousedown', mouseDownHandler)
    },
    item_arr_data_process (item_arr) {
      for (const index in item_arr) {
        const item = item_arr[index]
        item_arr[index] = item.substr(item.indexOf('.') + 1) + '일'
      }
      return item_arr
    },
    truncate_text (text) {
      let trunc_text = text
      if (trunc_text.length > 3) {
        trunc_text = trunc_text.split(' ').join('')
        trunc_text = trunc_text.substr(0, 3) + '..'
      }
      return trunc_text
    },
    // https://lovemewithoutall.github.io/it/json-to-excel/
    onexport () {
      // export json to Worksheet of Excel
      // only array possible
      var officeDay = XLSX.utils.json_to_sheet(this.excel_data.office_day)
      var officeMonth = XLSX.utils.json_to_sheet(this.excel_data.office_month)

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, officeDay, 'office_day') // sheetAName is name of Worksheet
      XLSX.utils.book_append_sheet(wb, officeMonth, 'office_month')

      // export Excel file
      XLSX.writeFile(wb, 'book.xlsx') // name of the file is 'boo
    },
    meanRowtoFixedHeader () {
      const header = document.querySelector('.gantt_month_table').querySelector('.v-data-table-header')
      const tbody = document.querySelector('.gantt_month_table').querySelector('tbody')
      const tdToth = tbody.children[0].innerHTML.replaceAll('td', 'th')
      const newEleTh = document.createElement('th')
      newEleTh.innerHTML = tdToth
      header.append(newEleTh)
    },
    getSubHeader (headers) {
      let result = []
      headers
        .filter((i) => i.children)
        .forEach((v) => {
          result = result.concat(v.children)
        })
      return result
    },
    onResize(){
      this.windowWidth = window.innerWidth;
    }
  },
  mounted () {
    this.readDataApi()
    this.add_drag_scroll(document.querySelector('.gantt_month_table'))
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    this_month: {
      handler () {
        this.loading = true
        this.date = new Date(this.this_month)
        this.readDataApi()
      }
    },
    showDeleted: {
      handler () {
        this.loading = true
        this.readDataApi()
      }
    }
  }
}
</script>
