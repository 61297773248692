<template>
  <div
    style="height: 100%; overflow-y: auto;"
  >
    <v-row
      v-if="windowWidth > 670"
      no-gutters
      style="height: 9%;"
    >
      <div
       class="font-subtitle" 
       background-color="#f6f7fa">
        오늘 일정
      </div>
    </v-row>
    <v-row v-else></v-row>
    <v-row
      v-if="windowWidth > 670"
      no-gutters
      style="height: 91%;"
    >
      <v-card outlined width="100%" height="100%" style="overflow-y: auto;">
        <div class="px-10">
          <v-data-table
            :headers="headers"
            :items="date"
            :loading="loading"
            hide-default-footer
            hide-default-header
            id="dashBoardDate"
            :no-data-text="no_data_text"
          >
            <template v-slot:item.timeTitle="{ item }">
              <div
                class="font-title dashboard_date_font_size"
                style="padding-top: 19px; padding-bottom: 10px; font-size: 16px"
              >
                {{ _time_format(item.start, item.end) }}
              </div>
              <div class="pb-5" style="font-family: NotoSans; font-size: 15px;">
                {{ item.title }}
              </div>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-row>
    <v-row
      v-else
      no-gutters
      style="height: 100%;"
    >
      <div class="px-10" style="width: 100%; overflow-y: auto;">
        <v-data-table
          id="dashBoardDate"
          :headers="headers"
          :items="date"
          :loading="loading"
          hide-default-footer
          hide-default-header
          mobile-breakpoint="671"
          :no-data-text="no_data_text"
        >
          <template v-slot:item.timeTitle="{ item }">
            <div
              class="font-title"
              style="padding-top: 19px; padding-bottom: 10px; font-size: 16px"
            >
              {{ _time_format(item.start, item.end) }}
            </div>
            <div
             class="pb-5 font-subtitle" 
             style="font-size: 15px"
            >
              {{ item.title }}
            </div>
          </template>
        </v-data-table>
      </div>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default {
  name: 'App',
  components: {},
  data: () => ({
    date: [],
    week: [],
    month: [],
    loading: true,
    date_today: new Date(),
    headers: [{ text: '', value: 'timeTitle', align: 'left' }],
    no_data_text: "오늘은 일정이 없습니다.",
    tabs: null,
    windowWidth: window.innerWidth,
  }),
  methods: {
    readDateAPI () {
      this.date = []
      const ymd = this.urlYmdFormat(this.date_today)
      this.loading = true

      axios.get(this.$getBlueDogUrl() + '/dateSchedule?ymd=' + ymd).then((response) => {
        this.loading = false
        for (let i = 0; i < response.data.length; i++) {
          const data = response.data[i]

          const item = {}
          item.title = data.name // data.name이 제목의 내용이다...
          item.start = data.start
          item.end = data.end
          item.memo = data.memo
          item.place = data.place

          this.date.push(item)
        }
      }).catch((error)=>{
        this.loading = false;
        this.no_data_text = Vue.prototype.$serverAccessFailedTableNoResultText
      })
    },
    urlYmdFormat (date) {
      const date_when =
        date.getFullYear().toString() +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        date.getDate().toString().padStart(2, '0')
      return date_when
    },
    _time_format (start, end) {
      const result_s = this.calc_time(start)
      const result_e = this.calc_time(end)
      if (result_s == '오전 12:00' && result_e == '오전 12:00') {
        return '종일'
      }
      return this.calc_time(start) + ' - ' + this.calc_time(end)
    },
    calc_time (_time) {
      if (_time == null || _time == 0) {
        return
      }
      const today = new Date(_time)
      const apm = today.getHours() < 12 ? '오전' : '오후'
      let hour = (hour = today.getHours() % 12) ? hour : 12
      const minute = today.getMinutes().toString().padStart(2, '0')
      return apm + ' ' + hour + ':' + minute
    },
    onResize(){
      this.windowWidth = window.innerWidth;
    }
  },
  mounted () {
    window.addEventListener('resize', this.onResize)
    this.readDateAPI()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>

<style>
#dashBoardDate td{
  border-bottom: unset !important;
}

#dashBoardDate table tbody td {
  padding-left: 0;
}

@media (max-width: 967px) {
  .dashboard_date_font_size{
    font-size: 12px !important;
  }
}

@media (max-width: 671px) {
  #dashBoardDate td{
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
}
</style>
