import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import common from './plugins/common'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/global.css'

Vue.config.productionTip = false

Vue.use(common)

new Vue({
  vuetify,
  router,
  axios,
  VueAxios,
  render: h => h(App)
}).$mount('#app')
