<template>
  <v-app 
    v-if="windowWidth > 960" 
    style="width: 100%; background-color: #f6f7fa;"
  >
    <v-row no-gutters style="height: 100%; margin-bottom: 38px;">
      <v-col style=" height: 100%;">
        <div style="line-height: 100%; font-family: NotoSansBold; font-size: 26px; margin-bottom: 40px;">관리자 페이지</div>
        <admin-top-card :accessed="accessed"/>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col style="background-color: #f6f7fa; height: 90%;">
        <div style="line-height: 100%; font-family: NotoSansBold; font-size: 26px; margin-bottom: 40px;">개인 연차 설정</div>
        <admin-bottom-card :items="bottom_items" @changed_data_arr="child_to_parent_bottom_items"/>
      </v-col>
    </v-row>
  </v-app>

  <v-app
   v-else
   style="width: 100%; background-color: #f6f7fa;" 
  >
    <v-tabs
      v-model="tabs"
      align-with-title
      height="60px"
      id="admin_main_v_tab"
    >
      <v-tabs-slider style="background: rgb(90, 127, 255);"></v-tabs-slider>

      <v-tab
        v-for="i in 2"
        :key="i"
        :href="`#tab-${i}`"
      >
        {{test_file[i]}}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs" style="background: #f6f7fa;">
      <v-tab-item
        :value="`tab-1`"
      >
        <v-row no-gutters style="height: 100%; margin-bottom: 38px;">
          <v-col style=" height: 100%;">
            <admin-top-card :accessed="accessed"/>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item
        :value="`tab-2`"
      >
        <v-row no-gutters>
          <v-col style="background-color: #f6f7fa; height: 90%;">
            <admin-bottom-card :items="bottom_items" @changed_data_arr="child_to_parent_bottom_items"/>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-app>
</template>

<script>
import adminTopCard from './adminTopCard.vue'
import adminBottomCard from './adminBottomCard.vue'

export default {
  components: { adminTopCard, adminBottomCard },
  props: ['bottom_items', 'accessed'],
  data: () => ({
    windowWidth: window.innerWidth,
    test_file: ['','관리자 페이지', '개인 연차 설정'],
    tabs: null,
    update_flag: true
  }),
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    child_to_parent_bottom_items(value){
      this.bottom_items = JSON.parse(JSON.stringify(value))
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
  },
}
</script>

<style>
#admin_main_v_tab{
  flex: 0;
}

#admin_main_v_tab > div > .v-slide-group__wrapper{
  background: #f6f7fa;
}
</style>
