<template>
  <v-app class="statistics_root" style="padding: 86px 100px 80px;">
    <div
      style="max-width: 480px;"
    >
      <v-tabs
        id="vTabs"
        v-model="tabs"
        hide-slider
        
      >
        <v-tab href="#tabs-1" id="v_tab_left" class="vTab">주간 통계</v-tab>
        <v-tab href="#tabs-2" id="v_tab_center" class="vTab">월간 통계</v-tab>
        <v-tab href="#tabs-3" id="v_tab_right" class="vTab">연간 통계</v-tab>
      </v-tabs>
    </div>
    <div>
      <v-tabs-items v-model="tabs" style="background: rgb(246, 247, 250);" touchless>
        <v-tab-item v-for="i in 3" :key="i" :value="'tabs-'+i">
          <statistics-week v-if="i==1" :title="weekGuenTae"/>
          <statistics-month v-if="i==2" :title="monthGuenTae"/>
          <statistics-year v-if="i==3" :title="yearGuenTae"/>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-app>
</template>
<style scoped>
@media (max-width: 450px){
  .vTab{
    font-size: 14px !important;
  }
}

.vTab{
  font-family: NotoSansBold;
  color: #5a7fff;
  font-size: 20px;
  background-color: white;
  width: 100%;
  border-top: 4px #5a7fff solid;
  border-bottom: 4px #5a7fff solid;
}

#v_tab_left{
  border-left: 4px #5a7fff solid;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}

#v_tab_center{
  border-left: 4px #5a7fff solid;
  border-right: 4px #5a7fff solid;
}

#v_tab_right{
  border-right: 4px #5a7fff solid;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}

.v-tab--active{
  color: white;
  background-color: #5a7fff;
}
</style>

<style>
@media (max-width: 960px) {
  .statistics_root{
    padding: 20px !important;
  }
}
</style>

<script>
import statisticsWeek from './statisticsWeek'
import statisticsMonth from './statisticsMonth'
import statisticsYear from './statisticsYear'

export default {
  name: 'App',
  components: {
    statisticsWeek,
    statisticsMonth,
    statisticsYear
  },
  data: () => ({
    tabs: null,
    weekGuenTae: '주간 근태 현황',
    monthGuenTae: '월간 근태 현황',
    yearGuenTae: '연간 근태 현황'
  }),
  mounted() {
    scrollTo(0,0)
  },
}
</script>
