<template>
  <div style="height: 85%">
    <div class="linear-activity" v-if="loading">
      <div class="indeterminate"></div>
    </div>
    <v-card
      outlined
      style="height: 100%;"
    >
      <v-calendar
        :v-model="today"
        :value="today"
        color="primary"
        type="month"
        style="height: 50%; border: none"
        class="vCalendarDashMonth"
        :events="events"
        :event-height="2"
        :event-color="getEventColor"
        @change="getEvents"
      >
        <!-- 마치 v-data-table의 slot과 비슷하다. 다만, 밖의 값을 참조하기 위한 방법이 다르다.
        @click으로 버튼이 동작하면, v-data-table의 search와 연결되어, 해당되는 요일의 값이 산출된다.
        class를 주어 동적으로 색상을 변경 가능하게 하였다. -->
        <!-- date 양식 : `yyyy-mm-dd` -->
        <template v-slot:day-label="{ date, day }">
          <v-btn
            v-if="count[date] != 0 && count[date] != null"
            icon
            v-ripple
            @click="search_color_changer(date)"
            :class="`date-${date}`"
          >
            {{ day }}
            <!-- :content 부분에서 값을 받아오는데, 이때 json형식으로 count에 값을 주어
                  해당되는 부분에서만 값을 받아올수 있게끔 처리를 해두었다. -->
            <v-badge :content="count[date]"></v-badge>
          </v-btn>
          <v-btn
            v-else
            icon
            v-ripple
            @click="search_color_changer(date)"
            :class="`date-${date}`"
          >
            {{ day }}
          </v-btn>
        </template>
      </v-calendar>
      <v-divider></v-divider>
      <div
        style="height: 49%;"
      >
        <calendar-month-bottom-bar :items="items" :search="search" :error="error_flag"/>
      </div>
    </v-card>
  </div>
</template>

<script>
import Axios from 'axios'
import CalendarMonthBottomBar from './CalendarMonthBottomBar.vue'
export default {
  components: { CalendarMonthBottomBar },
  props: ['parent_date'],
  data () {
    return {
      today: new Date(),
      // count =>
      // {`yyyy-mm-01`:0, `yyyy-mm-02`:1, ...}
      // count는 위와같이 해당 날짜(date)에 데이터가 있는지 없는지 판단하는 역할을 한다.
      count: {},
      items: [],
      search: '',
      searchFormat: this.urlYmdFormat_using_hyphon(new Date()),
      loading: true,
      events: [],
      //휴가, 외근, 휴일(평일 휴일), 업무일(공휴일 업무일), 기타
      colors: ['#d50001', '#8e24aa', '#4051b6', '#f6bf26', '#7986cb'],
      error_flag: false
    }
  },
  methods: {
    calendar_bar_color_picker(data_title){
      if(this.search_if_word_included(data_title, "휴가")){
        return 0
      }else if(this.search_if_word_included(data_title, "외근")){
        return 1
      }else if(this.search_if_word_included(data_title, "휴일")){
        return 2
      }else if(this.search_if_word_included(data_title, "평일 휴일")){
        return 2
      }else if(this.search_if_word_included(data_title, "업무일")){
        return 3
      }else if(this.search_if_word_included(data_title, "공휴일")){
        return 3
      }

      return 4
    },
    search_if_word_included(cmp_object, cmp_word){
      if(cmp_object.indexOf(cmp_word)>-1){
        return true
      }
      return false
    },
    // yyyy-mm-dd 포맷으로 calendar에서 반환하기 때문에
    // 해당 변화를 위해 포맷팅하는 함수를 따로 제작
    urlYmdFormat_using_hyphon (date) {
      const date_when =
        date.getFullYear().toString() +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getDate().toString().padStart(2, '0')
      return date_when
    },
    // yyyyMMdd 포맷
    urlYmdFormat (date) {
      const date_when =
        date.getFullYear().toString() +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        date.getDate().toString().padStart(2, '0')
      return date_when
    },
    // 해당되는 일정을 보고싶어 버튼을 누르게 되면, formatting된 요일이 search로 들어가게 되고
    // 해당되는 클래스의 색상을 바꾸어주게 된다.
    search_color_changer (date) {
      this.search = date
      const el = document.querySelector('.date-' + this.search)
      el.style.backgroundColor = '#dee6ff'
    },
    // count를 요일(yyyy-mm-dd)을 기준으로 초기화 시켜준다.
    initiating_count (date) {
      let start = new Date(date.getFullYear(), date.getMonth(), 1)
      const end = new Date(date.getFullYear(), date.getMonth() + 1, 0)

      const item = {}

      const diff = end.getDate() - start.getDate()

      for (let i = 0; i <= diff; i++) {
        item[`${this.urlYmdFormat_using_hyphon(start)}`] = '0'
        start = new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate() + 1
        )
      }
      this.count = item
    },
    getEvents ({ start, end }) {
      Axios.get(
        this.$getBlueDogUrl() + '/monthSchedule?ymd=' + this.urlYmdFormat(this.today)
      ).then((response) => {
        this.loading = false

        // count 배열 초기화
        this.initiating_count(this.today)

        // 이벤트 표시 완료(바 형태의 표시를 의미)
        const events = []
        for (let i = 0; i < response.data.length; i++) {
          const first = new Date(response.data[i].start)
          const second = new Date(response.data[i].end - 1000)

          events.push({
            name: '',
            start: first,
            end: second,
            // color: this.colors[this.rnd(0, this.colors.length - 1)],
            color: this.colors[this.calendar_bar_color_picker(response.data[i].name)],
            allDay: true
          })
        }

        this.events = events

        this.items = []

        for (let i = 0; i < response.data.length; i++) {
          const start = this.urlYmdFormat_using_hyphon(new Date(response.data[i].start))

          // 1000을 빼준이유
          // 일정을 종일로 설정해둘 경우, end가 다음날로 설정되기 때문이다.
          // 이를 해결하기위해 임의로 전날로 만들기 위해 1000을 빼준것이다.
          const end = this.urlYmdFormat_using_hyphon(new Date(response.data[i].end - 1000))

          // let diff = new Date(end).getDate() - new Date(start).getDate();
          const item = {}
          item.check_block = false
          item.start = start
          item.end = end
          item.content = response.data[i].name // 일정 내용의 변수 이름이 name이다.
          this.count[item.start]++

          if (new Date(end).toLocaleDateString() != new Date(start).toLocaleDateString()) {
            this.count[item.end]++
          }

          this.items.push(item)
        }
      }).catch((error)=>{
        this.loading = false;
        this.error_flag = true;
      })
    },
    getEventColor (event) {
      return event.color
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    }
  },
  mounted () {
    // windowWidth 사이즈 변함에 따라 dom이 달라지는데
    // 이때, 변화된 요일에 대해서 calendar가 인식을 못하기에
    // 인식을 가능하게끔 변경하여준다.
    if(!!this.parent_date){
      this.today = new Date(this.parent_date)
    }
  },
  // 여기서 watch를 사용하는 이유는, 버튼의 색상을 누를때 그 변한 색상이 그대로 유지되기 때문이다.
  // 따라서, watch로 search가 변하는 값을 보고있다가, 변하게되면 전의 class값의 색상을 원래의 색상을 바꾸어준다.
  watch: {
    parent_date:{
      handler(){
        this.loading = true
        this.today = new Date(this.parent_date)
      }
    },
    search: {
      handler (after, before) {
        // search에 아무값도 넣어주지 않았기 떄문에, watch가 볼때
        // before는 ""이 된다.
        // 그렇기 떄문에, 아래와 같이 before가 "" 일때는 동작하지 못하게 동작을 넣어주지 않는다.
        if (before == '') {
        } 
        else {
          const el = document.querySelector('.date-' + before)
          el.style.backgroundColor = ''
        }
      }
    }
  }
}
</script>

<style>
span.v-badge__badge {
  min-width: 10px !important;
  width: 13px !important;
  height: 14px !important;
  padding: 2px 3px;
  font-size: 10px;
  inset: auto auto calc(100%) calc(100%) !important;
}

.vCalendarDashMonth div div {
  border: none !important;
}

.vDataTableDashMonth table > tbody > tr > td {
  border-bottom: none !important;
}

/* .linear-activity {
    overflow: hidden;
    width: 90%;
    height: 4px;
    background-color: #aac7e4 !important;
    margin: 0px auto;
}

.indeterminate {
    position: relative;
    width: 100%;
    height: 100%;
}

.indeterminate:before {
    content: '';
    position: absolute;
    height: 100%;
    background-color: #1976d2 !important;
    animation: indeterminate_first 1s infinite ease-out;
}

@keyframes indeterminate_first {
    0% {
        left: -100%;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 20%;
    }
} */
</style>
