<template>
    <div class="container_worktime">

        <div v-if="this.subtitle == '근무 시간 설정'" class="worktime_child" style="font-size: 15px; font-weight: bold; min-width: 100px; margin-right: 55px;">
            {{this.subtitle}}
        </div>
        <div v-else class="worktime_child" style="font-size: 15px; font-weight: bold; min-width: 100px;  margin-right: -1px;">
            {{this.subtitle}}
        </div>

        <div v-if="this.subtitle == '근무 시간 설정'" style="display: inline-block;">
            <div class="conainer_worktime_2">
                <div class="worktime_child worktime_option">
                    <v-select
                        :readonly="!modify_flag"
                        :items="time_option_arr"
                        v-model="start_time_option"
                        :filled="modify_flag"
                        hide-details
                    >
                    </v-select>
                </div>
                <div class="worktime_child worktime_item">
                    <v-select
                        :items="time_hour_arr"
                        v-model="start_time_hour"  
                        :readonly="!modify_flag "
                        :filled="modify_flag"
                        hide-details
                    >
                    </v-select>
                </div><div class="worktime_op">:</div>
                <div class="worktime_child worktime_item">
                    <v-select
                        :items="time_minute_arr"
                        v-model="start_time_minute"  
                        :readonly="!modify_flag "
                        :filled="modify_flag"
                        hide-details
                    >
                    </v-select>
                </div>
            </div>
            <div class="conainer_worktime_2">
                <div class="worktime_op">-</div>
            </div>
            <div class="conainer_worktime_2">
                <div class="worktime_child worktime_option">
                    <v-select
                        :items="time_option_arr"
                        v-model="end_time_option"  
                        :readonly="!modify_flag "
                        :filled="modify_flag"
                        hide-details
                    >
                    </v-select>
                </div>
                <div class="worktime_child worktime_item">
                    <v-select
                        :items="time_hour_arr"
                        v-model="end_time_hour"  
                        :readonly="!modify_flag "
                        :filled="modify_flag"
                        hide-details
                    >
                    </v-select>
                </div><div class="worktime_op">:</div>
                <div class="worktime_child worktime_item">
                    <v-select
                        :items="time_minute_arr"
                        v-model="end_time_minute"  
                        :readonly="!modify_flag "
                        :filled="modify_flag"
                        hide-details
                    >
                    </v-select>
                </div>
            </div>
        </div>

        <v-switch
            v-if="this.subtitle != '근무 시간 설정'"
            v-model="use_flag"
            :disabled="!modify_flag"
            :hide-details="true"
            :ripple="false"
            class="time_control_switch"
        ></v-switch>
        <div v-if="this.subtitle != '근무 시간 설정'" style="display: inline-block;">
            <div class="conainer_worktime_2">
                <div class="worktime_child worktime_option">
                    <v-select
                        :readonly="!modify_flag || !use_flag"
                        :items="time_option_arr"
                        v-model="start_time_option"
                        :filled="modify_flag && use_flag"
                        hide-details
                    >
                    </v-select>
                </div>
                <div class="worktime_child worktime_item">
                    <v-select
                        :items="time_hour_arr"
                        v-model="start_time_hour"  
                        :readonly="!modify_flag || !use_flag"
                        :filled="modify_flag && use_flag"
                        hide-details
                    >
                    </v-select>
                </div><div class="worktime_op">:</div>
                <div class="worktime_child worktime_item">
                    <v-select
                        :items="time_minute_arr"
                        v-model="start_time_minute"  
                        :readonly="!modify_flag || !use_flag"
                        :filled="modify_flag && use_flag"
                        hide-details
                    >
                    </v-select>
                </div>
            </div>
            <div class="conainer_worktime_2">
                <div class="worktime_op">-</div>
            </div>
            <div class="conainer_worktime_2">
                <div class="worktime_child worktime_option">
                    <v-select
                        :items="time_option_arr"
                        v-model="end_time_option"  
                        :readonly="!modify_flag || !use_flag"
                        :filled="modify_flag && use_flag"
                        hide-details
                    >
                    </v-select>
                </div>
                <div class="worktime_child worktime_item">
                    <v-select
                        :items="time_hour_arr"
                        v-model="end_time_hour"  
                        :readonly="!modify_flag || !use_flag"
                        :filled="modify_flag && use_flag"
                        hide-details
                    >
                    </v-select>
                </div><div class="worktime_op">:</div>
                <div class="worktime_child worktime_item">
                    <v-select
                        :items="time_minute_arr"
                        v-model="end_time_minute"  
                        :readonly="!modify_flag || !use_flag"
                        :filled="modify_flag && use_flag"
                        hide-details
                    >
                    </v-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['subtitle' ,'item', 'modify_flag'],
    data() {
        return{
            time_option_arr: ['AM', 'PM'],
            time_hour_arr:[],
            time_minute_arr:[],
            start_time_option: "AM",
            start_time_hour: '00',
            start_time_minute: '00',
            end_time_option: "PM",
            end_time_hour: "00",
            end_time_minute: '00',
            use_flag: false,
            server_send_data:{
                start_time_option: "AM",
                start_time_hour: '00',
                start_time_minute: '00',
                end_time_option: "PM",
                end_time_hour: '00',
                end_time_minute: '00',
                use_flag: false,
            },
        }
    },
    methods: {
        arr_data_init(){
            this.time_hour_arr = []
            this.time_minute_arr = []

            // TODO 1 -> 01 이렇게 두자리로 바꾸기...
            for(let i=0;i<12;i++){
                this.time_hour_arr.push(i.toString().padStart(2,'0'))
            }

            for(let i=0;i<60;i++){
                this.time_minute_arr.push(i.toString().padStart(2,'0'))
            }

        },
        emit_to_parent(name, child_data){
            this.$emit(name, child_data)
        },
    },
    mounted() {
        this.arr_data_init()
    },
    watch:{
        item:{
            handler(){
                this.start_time_option = this.item.startOpt
                this.start_time_hour = this.item.startHour.padStart(2, '0')
                this.start_time_minute = this.item.startMin.padStart(2, '0')
                this.end_time_option = this.item.endOpt
                this.end_time_hour = this.item.endHour.padStart(2, '0')
                this.end_time_minute = this.item.endMin.padStart(2, '0')
                this.use_flag = this.item.useFlag === 'true'? true : false
            }
        },
        start_time_option:{
            handler(){
                this.server_send_data.start_time_option = this.start_time_option
                this.emit_to_parent('child-data',this.server_send_data)
                }
        },
        start_time_hour:{
            handler(){
                this.server_send_data.start_time_hour = parseInt(this.start_time_hour)
                this.emit_to_parent('child-data',this.server_send_data)
            }
        },
        start_time_minute:{
            handler(){
                this.server_send_data.start_time_minute = parseInt(this.start_time_minute)
                this.emit_to_parent('child-data',this.server_send_data)
            }
        },
        end_time_option:{
            handler(){
                this.server_send_data.end_time_option = this.end_time_option
                this.emit_to_parent('child-data',this.server_send_data)
            }
        },
        end_time_hour:{
            handler(){
                this.server_send_data.end_time_hour = parseInt(this.end_time_hour)
                this.emit_to_parent('child-data',this.server_send_data)
            }
        },
        end_time_minute:{
            handler(){
                this.server_send_data.end_time_minute = parseInt(this.end_time_minute)
                this.emit_to_parent('child-data',this.server_send_data)
            }
        },
        use_flag:{
            handler(){
                this.server_send_data.use_flag = this.use_flag
                this.emit_to_parent('child-data', this.server_send_data)
            }
        }
    }
}
</script>

<style>

@media (max-width: 404px) {
    
}

.container_worktime{
    width: fit-content !important;
    display: block;
    align-items: center;
}

.conainer_worktime_2{
    display: inline-block;
    padding-bottom: 2px;
}

.worktime_child,
.worktime_op{
    display: inline-block;
}

.worktime_option{
    min-width: 57px !important;
    margin-right: 6px;
}

.worktime_child_values{
    display: inline-block;
}

.worktime_child{
    display:inline-block;
    min-width: 35px;
    height: 26px;
}
.worktime_child > div,
.worktime_child > div > div,
.worktime_child > div > div > div{
    height: 100% !important;
}

.worktime_child .v-input__append-inner, 
.worktime_child .v-input__slot::after{
    display: none;
}

.worktime_child .v-select__selections{
    margin: 0 auto;
}

.worktime_child > div,
.worktime_child .v-select__selection {
    margin: 0;
    padding: 0;
}

.worktime_child .v-select__selections input{
    display: none;
}

.worktime_child .v-select__selections > div{
    margin: 0 auto;
}

.worktime_child .v-input__slot{
    min-height: inherit !important;
    border-radius: 0;
}

.worktime_item{
    min-width: 44px;
}

.v-menu__content{
    max-width: 70px;
}

.worktime_op{
    width: fit-content;
    line-height: 100%;
}

.time_control_switch{
    display: inline-block !important;
    margin-left: 11px !important;
}
</style>