<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: ['data'],
  data () {
    return {
      labelArray: [],
      valueArray: [],
      colorArray: []
    }
  },
  watch: {
    data: {
      handler () {
        if (!!this.data && this.data.length > 0) {
          this.valueArray = this.data.map((it) => it.value)
          //   this.colorArray = this.data.map((it) => it.color.trim());
          this.colorArray = this.data.map((it) =>
            this.rgbHexToText(it.color.trim())
          )
          this.labelArray = this.data.map((it) => `${it.label}`)
          setTimeout(() => {
            this.renderChart(
              {
                labels: this.labelArray,
                datasets: [
                  {
                    data: this.valueArray,
                    backgroundColor: this.colorArray
                  }
                ]
              },
              {
                legend: {
                  display: false
                },

                plugins: {
                  datalabels: {
                    color: '#00000000'
                  }
                }
              }
            )
          })
        } else {
          console.error('Chart Data Error')
          console.error(this.data)
        }
      }
    }
  },
  mounted () {
    if(!!this.data && this.data.length > 0){
      this.rendering()
    }
    // if (!!this.data && this.data.length > 0) {
    //   this.valueArray = this.data.map((it) => it.value);
    //   //   this.colorArray = this.data.map((it) => it.color.trim());
    //   this.colorArray = this.data.map((it) =>
    //     this.rgbHexToText(it.color.trim())
    //   );
    //   this.labelArray = this.data.map((it) => `${it.label}` );
    //   console.log(this.valueArray);
    //   console.log(this.colorArray);
    //   //   console.log(this.labelArray);
    //   setTimeout(() => {
    //     this.renderChart(
    //       {
    //         labels: this.labelArray,
    //         datasets: [
    //           {
    //             data: this.valueArray,
    //             backgroundColor: this.colorArray,
    //           },
    //         ],
    //       },
    //       {
    //         legend: {
    //             display:false
    //         },

    //         plugins:{
    //           datalabels:{
    //             color:'#00000000',
    //           },
    //         },
    //       }
    //     );
    //   }, 1000);
    // } else {
    //   console.error("Chart Data Error");
    //   console.error(this.data);
    // }
  },
  methods: {
    // #aabbcc -> {r:170, g:187, g:204}
    hexToRgb: function (hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
        : null
    },
    //
    rgbHexToText: function (hex) {
      const rgb = this.hexToRgb(hex)
      return rgb != null ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)` : null
    },
    rendering(){
      if (!!this.data && this.data.length > 0) {
          this.valueArray = this.data.map((it) => it.value)
          //   this.colorArray = this.data.map((it) => it.color.trim());
          this.colorArray = this.data.map((it) =>
            this.rgbHexToText(it.color.trim())
          )
          this.labelArray = this.data.map((it) => `${it.label}`)
          setTimeout(() => {
            this.renderChart(
              {
                labels: this.labelArray,
                datasets: [
                  {
                    data: this.valueArray,
                    backgroundColor: this.colorArray
                  }
                ]
              },
              {
                legend: {
                  display: false
                },

                plugins: {
                  datalabels: {
                    color: '#00000000'
                  }
                }
              }
            )
          })
        } else {
          console.error('Chart Data Error')
          console.error(this.data)
        }
      }
    
  }
}
</script>
