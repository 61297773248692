<template>
  <v-card
    outlined
    width="100%"
    style="
      overflow: unset !important;
      height: 800px !important;
      position: relative;"
  >
    <div
      v-if="windowWidth>450"
      style="width: 85%; height: 15%; margin: 0 auto; display: flex; flex-wrap: wrap-reverse; justify-content: space-between; align-items: center;"
    >
      <div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="직원 이름"
          outlined
          rounded
          dense
          hide-details
          style="min-width: 180px; max-width: 180px;"
        ></v-text-field>
      </div>
      <div>
        <statistics-gantt-year-excel-button :test1="date" :show-deleted="showDeleted"/>
      </div>
    </div>
    <div
      v-else
      style="width: 85%; height: 15%; margin: 0 auto; display: flex; flex-flow: column; justify-content: space-around;"
    >
      <div
        style="display: flex; justify-content: flex-end; align-items: center;"
      >
        <statistics-gantt-year-excel-button :test1="date" :show-deleted="showDeleted"/>
      </div>
      <div>
        <div
          style="display: flex; justify-content: flex-start; align-items: center;"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="직원 이름"
            outlined
            rounded
            dense
            hide-details
            style="min-width: 180px; max-width: 180px;"
          ></v-text-field>
        </div>
      </div>
    </div>
    <div
      v-if="windowWidth>450"
      style="display: flex; height: 5%; width: 85%; margin: 0 auto; align-items: center; justify-content: flex-end;"
    >
      <div class="ganttYear_subtitle" style="background-color: darkgrey; border: 1px solid #464141;"></div><div>:휴가</div>
      <div class="ganttYear_subtitle" style="background-color: rgb(241, 177, 102); border: 1px solid rgb(236 148 45);"></div><div>:지각</div>
      <div class="ganttYear_subtitle" style="background-color: beige; border: 1px solid #eaea8f;"></div><div>:결근</div>
      <div class="ganttYear_subtitle" style="background-color: aliceblue; border: 1px solid #b0d6f7;"></div><div>:외근</div>
    </div>
    <div
      v-else
      style="display: flex; height: 5%; width: 85%; margin: 0 auto; align-items: center; justify-content: flex-end; font-size: 12px;"
    >
      <div class="ganttYear_subtitle_small_ver" style="background-color: darkgrey; border: 1px solid #464141;"></div><div>:휴가</div>
      <div class="ganttYear_subtitle_small_ver" style="background-color: rgb(241, 177, 102); border: 1px solid rgb(236 148 45);"></div><div>:지각</div>
      <div class="ganttYear_subtitle_small_ver" style="background-color: beige; border: 1px solid #eaea8f;"></div><div>:결근</div>
      <div class="ganttYear_subtitle_small_ver" style="background-color: aliceblue; border: 1px solid #b0d6f7;"></div><div>:외근</div>
    </div>
    <div
      style="height: 80%;"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        hide-default-header
        hide-default-footer
        :fixed-header="true"
        :item-class="funcItemClass"
        :search="search"
        mobile-breakpoint="0"
        :no-data-text="error_flag==true?no_data_text:''"
        loading-text="데이터가 로딩중입니다.."
        :footer-props="{
          itemsPerPageOptions: [-1], // 모든 내용을 보여준다는 의미 all
        }"
        style="
          overflow: auto;
          width: 85%;
          height: 95%;
          margin: 0px auto;
          border: 1px solid #dddddd !important;
        "
        class="gantt_year_table"
      >
        <template #header="{}">
          <thead class="v-data-table-header">
          <tr>
            <th
              v-for="(header, i) in headers"
              :key="i"
              class="text-center parent-header td-border-style"
              :style="`min-width:` + header.width"
            >
              {{ header.text }}
            </th>
          </tr>
          <tr>
            <th
              v-for="(h1, i1) in getSubHeader(headers)"
              :key="i1"
              class="text-center child-header td-border-style"
              style="background-color: #fff8e6;"
            >
              <!--i1은 인덱스로써, 처음 7개는 고정되는 값으로써, 조정할 필요 없다.-->
              <div v-if="i1 < 7">{{ h1.text }}</div>
              <ul
                class="ul_without_dot"
                v-else
              >
                <li v-for="(ele,i4) in h1.text" :key="i4">
                  <v-tooltip bottom v-if="ele.length >= 4">
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        style="font-size: 12px"
                        height="18px"
                      >
                        <div class="truncate_text_class">{{ truncate_text(ele) }}</div>
                      </v-btn>
                    </template>
                    <div>{{ ele }}</div>
                  </v-tooltip>
                  <div v-else>{{ ele }}</div>
                </li>
              </ul>
            </th>
          </tr>
          </thead>
        </template>
        <template v-slot:item.late="{ item }">
          <span>
            {{ item.late }}
          </span>
        </template>
        <template v-slot:item.absent="{ item }">
          <span>
            {{ item.absent }}
          </span>
        </template>
        <template v-slot:item.outwork="{ item }">
          <span>
            {{ item.outwork }}
          </span>
        </template>
        <template v-for="(day,i2) in this.days" v-slot:[`item.`+day]="{ item }" >
          <div style="display: flex; justify-content: space-evenly;" :key="i2">
            <div v-for="(val,i3) in item[day]" :key="i3">
              <div v-if="val.count != 0" :style="`width: 27px; height: 20px; margin: 0 3px; border: 1px solid `+val.borderColor+`; border-radius: 20px; overflow: hidden; background-color: `+val.color">
                {{val.count}}
              </div>
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<style>
.ul_without_dot {
  padding-left: 0 !important;
  list-style: none;
  max-height: 120px;
  overflow-y: auto;
}

/* 테이블 줄 생기게끔 변경 */
.gantt_year_table th,
.gantt_year_table td{
  border-left: 1px solid #dddddd !important;
  border-bottom: 1px solid #dddddd !important;
}

.gantt_year_table .v-data-table__wrapper > table > tbody > tr > td:first-child {
  font-family: NotoSansBold;
  font-size: 15px;
  color: black;
}

/* 각 cell의 padding 제거 */
.itemYearClass > td {
  padding: 0px !important;
}

/* 스크롤바 제어를 위해 */
.gantt_year_table > div {
  overflow: unset !important;
}

/* 상태(지각, 결근, 등..) */
.gantt_status_check {
  background-color: #cae2ff;
}

.ganttYear_subtitle{
  width: 30px;
  height: 20px;
  border-radius: 20px;
  margin: 0 10px;
}

.ganttYear_subtitle_small_ver{
  width: 15px;
  height: 10px;
  border-radius: 10px;
  margin: 0 5px;
}
</style>

<script>
import Vue from 'vue'
import axios from 'axios'
import statisticsGanttYearExcelButton from './statisticsGanttYearExcelButton.vue'

export default {
  components: { statisticsGanttYearExcelButton },
  props: ['selectedDate', 'showDeleted'],
  data () {
    return {
      url: Vue.prototype.$serverUrl,
      monthOrYear: 'year',
      year: this.selectedDate.getFullYear(),
      date: new Date(this.selectedDate),
      items: [],
      days: [],
      loading: true,
      search: '',
      headers: [],
      no_data_text: Vue.prototype.$serverAccessFailedTableNoResultText,
      windowWidth: window.innerWidth,
      error_flag: false,
    }
  },
  methods: {
    funcItemClass () {
      return 'itemYearClass'
    },
    readDataApi () {
      // urlYmdFormat(date) -> yyyyMMdd
      const ymd = Vue.prototype.$urlYmdFormat(this.date)

      axios
        .all([
          axios.get(
            this.$getBlueDogUrl() +
            '/checkStatisticsGanttMonth?date=' +
            ymd +
            '&&' +
            'monthOrYear=' +
            this.monthOrYear
            + '&showDeleted=' + this.showDeleted
          ),
          axios.get(
            this.$getBlueDogUrl() +
            '/checkStatisticsGanttMonthAvg?date=' +
            ymd +
            '&&' +
            'monthOrYear=' +
            this.monthOrYear
            + '&showDeleted=' + this.showDeleted
          ),
          axios.get(
            this.$getBlueDogUrl() +
            '/checkStatisticsGanttMonthAvgPerPeople?date=' +
            ymd +
            '&&' +
            'monthOrYear=' +
            this.monthOrYear
            + '&showDeleted=' + this.showDeleted
          )
        ])
        .then(
          axios.spread((obj1, obj2, obj3) => {
            this.items = []

            // 평균값을 multiheader로 구현하기 위해 아래와 같이 모델의 값을 받아두었다.
            // 아래의 동적인 headers를 이용하여, 그들의 속성에 children으로 값을 받을 것이다.
            const obj2_data = obj2.data.targetGanttModelAvg
            const obj3_data = obj3.data

            // headers를 동적으로 추가해주기 때문에 아래와 같이, 매번 headers를 갱신한다.
            // 아래와 같이 하지 않으면, 검색하는 month(prop인 this_month)가 변경될때마다
            // 열이 data에 들어가 추가되어, 저번달과 이번달의 열이 더해지게 된다.
            this.headers = [
              {
                text: '이름',
                value: 'name',
                align: 'center',
                width: '99px',
                children: [{ text: '' }]
              },
              {
                text: '근무 시간',
                value: 'officetime',
                align: 'center',
                width: '115px',
                children: [{ text: obj2_data.avg_officetime }]
              },
              {
                text: '휴가',
                value: 'leave',
                align: 'center',
                width: '82px',
                children: [{ text: obj2_data.avg_leave }]
              },
              {
                text: '초과근무',
                value: 'overwork',
                align: 'center',
                width: '96px',
                children: [{ text: obj2_data.avg_overwork }]
              },
              {
                text: '지각',
                value: 'late',
                align: 'center',
                width: '82px',
                children: [{ text: obj2_data.sum_late }]
              },
              {
                text: '결근',
                value: 'absent',
                align: 'center',
                width: '82px',
                children: [{ text: obj2_data.sum_absent }]
              },
              {
                text: '외근',
                value: 'outwork',
                align: 'center',
                width: '82px',
                children: [{ text: obj2_data.sum_outwork }]
              }
            ]
            this.loading = false
            this.days = obj1.data.periodData.days

            // 일자(요일) v-datatable column 생성 시작
            for (var index in obj1.data.periodData.days) {
              const head_json_temp = {
                text: '',
                value: '',
                align: 'center',
                width: '85px',
                sortable: false // TODO 나중에 바꿀수도 있음
              }

              head_json_temp.text = obj1.data.periodData.days[index]
              head_json_temp.value = obj1.data.periodData.days[index]

              // 밑의 한줄은 multiheader에 대한 처리를 위한 것이며, `일(요일)`에 대한 처리이다.
              head_json_temp.children = [
                { text: obj2_data.map_month_sch[head_json_temp.value] }
              ]

              this.headers.push(head_json_temp)
            }
            // 일자(요일) v-datatable column 생성 끝

            // data-table 데이터 입력 및
            for (var index in obj1.data.ganttContentData) {
              const data = obj1.data.ganttContentData[index]

              const item = {}

              // array 아닌 value(주석 중요하지 않음, 가독성 위해)
              item.name = data.name
              item.officetime = data.officetime
              item.overwork = data.overwork
              item.leave = data.leave + '일'

              // array 인 value
              item.absent = data.arr_absent.length + '회'
              item.late = data.arr_late.length + '회'
              item.outwork = data.arr_outwork.length + '회'

              const init_json = this.arr_init(this.days)

              item.leave_tempJson = this.process_arr_data(data.arr_leave)
              for (const key in item.leave_tempJson) {
                init_json[key].push(
                  {
                    type: 'leave',
                    color: 'darkgrey',
                    borderColor: '#464141',
                    count: item.leave_tempJson[key]
                  }
                )
              }

              item.late_tempJson = this.process_arr_data(data.arr_late)
              for (const key in item.late_tempJson) {
                init_json[key].push(
                  {
                    type: 'late',
                    color: 'rgb(241, 177, 102)',
                    borderColor: 'rgb(236 148 45)',
                    count: item.late_tempJson[key]
                  }
                )
              }

              item.absent_tempJson = this.process_arr_data(data.arr_absent)
              for (const key in item.absent_tempJson) {
                init_json[key].push(
                  {
                    type: 'absent',
                    color: 'beige',
                    borderColor: '#eaea8f',
                    count: item.absent_tempJson[key]
                  }
                )
              }

              item.outwork_tempJson = this.process_arr_data(data.arr_outwork)
              for (const key in item.outwork_tempJson) {
                init_json[key].push(
                  {
                    type: 'outwork',
                    color: 'aliceblue',
                    borderColor: '#b0d6f7',
                    count: item.outwork_tempJson[key]
                  }
                )
              }

              for (const key in init_json) {
                item[key] = init_json[key]
              }

              this.items.push(item)
            }
          })
        ).catch((error)=>{
        this.loading = false
        this.error_flag = true
        this.child_to_parent(this.error_flag)
      })
    },
    child_to_parent(child_data){
      this.$emit('error_flag',child_data)
    },
    add_drag_scroll (ele) {
      let pos = { top: 0, left: 0, x: 0, y: 0 }
      ele.style.cursor = 'grab'
      ele.style.userSelect = 'none'
      const mouseDownHandler = (e) => {
        ele.style.cursor = 'grabbing'

        pos = {
          left: ele.scrollLeft,
          top: ele.scrollTop,
          x: e.clientX,
          y: e.clientY
        }

        document.addEventListener('mousemove', mouseMoveHandler)
        document.addEventListener('mouseup', mouseUpHandler)
      }

      const mouseMoveHandler = (e) => {
        const dx = e.clientX - pos.x
        const dy = e.clientY - pos.y

        ele.scrollLeft = pos.left - dx
        ele.scrollTop = pos.top - dy
      }

      const mouseUpHandler = (e) => {
        ele.style.cursor = 'grab'

        document.removeEventListener('mousemove', mouseMoveHandler)
        document.removeEventListener('mouseup', mouseUpHandler)
      }

      ele.addEventListener('mousedown', mouseDownHandler)
    },
    arr_init (arr_store) {
      const temp = {}

      for (const index in arr_store) {
        temp[arr_store[index]] = []
      }

      return temp
    },
    arr_process (arr_store) {
      const temp = {}

      for (const index in arr_store) {
        temp[arr_store[index]] = 0
      }
      return temp
    },
    process_arr_data (arr_data) {
      const temp = this.arr_process(this.days)

      for (const index in arr_data) {
        switch (arr_data[index].substr(0, 3)) {
          case '01.':
            temp['1월']++
            break
          case '02.':
            temp['2월']++
            break
          case '03.':
            temp['3월']++
            break
          case '04.':
            temp['4월']++
            break
          case '05.':
            temp['5월']++
            break
          case '06.':
            temp['6월']++
            break
          case '07.':
            temp['7월']++
            break
          case '08.':
            temp['8월']++
            break
          case '09.':
            temp['9월']++
            break
          case '10.':
            temp['10월']++
            break
          case '11.':
            temp['11월']++
            break
          case '12.':
            temp['12월']++
            break
        }
      }

      return temp
    },
    item_arr_data_process (item_arr) {
      for (const index in item_arr) {
        const item = item_arr[index]
        item_arr[index] = item.substr(item.indexOf('.') + 1) + '일'
      }
      return item_arr
    },
    truncate_text (text) {
      text = text.split(' ').join('')
      return text.substr(0, 3) + '..'
    },
    meanRowtoFixedHeader () {
      const header = document
        .querySelector('.gantt_year_table')
        .querySelector('.v-data-table-header')
      const tbody = document
        .querySelector('.gantt_year_table')
        .querySelector('tbody')
      const tdToth = tbody.children[0].innerHTML.replaceAll('td', 'th')
      const newEleTh = document.createElement('th')
      newEleTh.innerHTML = tdToth
      header.append(newEleTh)
    },
    getSubHeader (headers) {
      let result = []
      headers
        .filter((i) => i.children)
        .forEach((v) => {
          result = result.concat(v.children)
        })
      return result
    },
    onReisze(){
      this.windowWidth = window.innerWidth
    }
  },
  mounted () {
    this.readDataApi()
    this.add_drag_scroll(document.querySelector('.gantt_year_table'))
    window.addEventListener('resize', this.onReisze)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onReisze)
  },
  watch: {
    selectedDate: {
      handler(){
        this.loading = true
        this.date = new Date(this.selectedDate)
        this.readDataApi()
      }
    },
    showDeleted: {
      handler(){
        this.loading = true
        this.readDataApi()
      }
    }
  }
}
</script>
