<template>
  <div
    style="height: 100%;"
  >
    <v-row no-gutters style="height: 9%">
      <v-tabs
        v-model="tabs"
        id="vtabs"
        height="100%"
        :slider-size="2"
        slider-color="#3b46ff"
        color="#3b46ff"
      >
        <v-tab href="#mobile-tabs-5-1" class="pa-0">
          <div>일간 일정</div>
        </v-tab>

        <v-tab href="#mobile-tabs-5-2" class="pa-0">
          <div>주간 일정</div>
        </v-tab>

        <v-tab href="#mobile-tabs-5-3" class="pa-0">
          <div>월간 일정</div>
        </v-tab>
      </v-tabs>
    </v-row>
    <v-row no-gutters style="height: 91%;">
      <v-card outlined style="width: 100%; height: 100%;">
        <v-tabs-items 
          v-model="tabs"
          touchless
          style="height: 100%;"
        >
          <v-tab-item
            v-for="i in 3"
            :key="i"
            :value="'mobile-tabs-5-' + i"
          >
            <dash-board-date v-if="i==1"/>
            <v-data-table
              v-if="i == 2"
              id="dashBoardWeekMonth"
              class="px-10"
              :headers="headers"
              :items="date"
              :loading="loading"
              hide-default-footer
              hide-default-header
              mobile-breakpoint="671"
              sort-by="start"
              style="height: 100%; overflow-y: auto;"
              :header-props="{
                divider: false,
              }"
              :no-data-text="error_flag==true?no_data_text_err:no_data_text"
            >
              <template v-slot:item.timeTitle="{ item }">

                <div 
                  style="text-align: right;"
                >
                  <div
                    style="font-size: 16px; font-family: NotoSansBold;"
                  >{{ yyyy_mm_dd(item.start) }}</div>
                  <div
                    style="font-size: 16px; color: #7ba5ff; font-family: NotoSansBold;"
                  >{{ _time_format(item.start, item.end) }}</div>
                </div>

                <!-- <div style="padding-top: 19px">
                  <span
                    class="font-title ma-0 mr-2 "
                    style="font-size: 16px; display: inline-block"
                    >{{ yyyy_mm_dd(item.start) }}</span
                  >
                  <span
                    class="font-title ma-0 "
                    style="font-size: 16px; color: #7ba5ff"
                    >{{ _time_format(item.start, item.end) }}</span
                  >
                </div> -->
                <div style="padding-bottom: 12px; padding-top: 10px">
                  <span
                    style="
                      font-family: NotoSans;
                      font-size: 15px;
                      display: block;
                      padding-bottom: 8px;
                    "
                    >{{ item.title }}</span
                  >
                </div>
              </template>
              <template v-slot:item.start="{ item }">
              <!-- 정렬하기 위해서 넣어둔 값... display: none임 -->
                <div style="display: none">{{ item.start }}</div>
              </template>
            </v-data-table>
            <dash-board-month-com v-if="i==3"/>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-row>
  </div>
</template>

<style>
#vtabs .v-tab:not(.v-tab--active) > div {
  color: black;
}

#dashBoardWeekMonth table tbody td {
  padding-left: 0;
}
.v-window-item--active{
  height: 100% !important;
}

#dashBoardDate .v-data-table__mobile-row{
  justify-content: flex-end !important;
}

#dashBoardWeekMonth .v-data-table__mobile-row{
  justify-content: flex-end !important;
}

@media (max-width: 671px) {
  #dashBoardWeekMonth td{
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  /* 해당 td는 정렬을 위해 임시로 넣어둔 값으로, 공간 자체를 차지할 이유는 없음으로 다음과 같이 처리해준다. */
  #dashBoardWeekMonth td:last-child{
    display: none !important;
  }
}
</style>

<script>
import Vue from 'vue'
import axios from 'axios'
import DashBoardMonthCom from './DashBoardMonthCom.vue'
import DashBoardDate from './DashBoardDate.vue'


export default {
  name: 'App',
  components: { DashBoardMonthCom, DashBoardDate },
  data: () => ({
    focus: '',
    events: [],
    checkNumber: 0,
    date: [],
    week: [],
    month: [],
    loading: true,
    date_today: new Date(),
    headers: [
      { text: '', value: 'timeTitle', align: 'left' },
      { text: '', value: 'start' }
    ],
    no_data_text_err:Vue.prototype.$serverAccessFailedTableNoResultText,
    no_data_text:"이번주는 일정이 없습니다.",
    error_flag: false,
    tabs: null
  }),
  methods: {
    readWeekAPI () {
      const ymd = this.urlYmdFormat(this.date_today)
      this.loading = true
      const check = ++this.checkNumber
      axios.get(this.$getBlueDogUrl() + '/weekSchedule?ymd=' + ymd).then((response) => {
        this.loading = false
        if (check != this.checkNumber) {
          return
        }
        this.date = []
        for (let i = 0; i < response.data.length; i++) {
          const data = response.data[i]

          const item = {}
          item.title = data.name // data.name이 제목의 내용이다...
          item.start = data.start
          item.end = data.end
          item.memo = data.memo
          item.place = data.place

          this.date.push(item)
        }
      }).catch((error)=>{
        this.loading = false
        this.error_flag = true
      })
    },
    urlYmdFormat (date) {
      const date_when =
        date.getFullYear().toString() +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        date.getDate().toString().padStart(2, '0')
      return date_when
    },
    _time_format (start, end) {
      const result_s = this.calc_time(start)
      const result_e = this.calc_time(end)
      if (result_s == '오전 12:00' && result_e == '오전 12:00') {
        return '종일'
      }
      return this.calc_time(start) + ' - ' + this.calc_time(end)
    },
    calc_time (_time) {
      const today = new Date(_time)
      const apm = today.getHours() < 12 ? '오전' : '오후'
      let hour = (hour = today.getHours() % 12) ? hour : 12
      const minute = today.getMinutes().toString().padStart(2, '0')
      return apm + ' ' + hour + ':' + minute
    },
    yyyy_mm_dd (_time) {
      const day = new Date(_time)
      const year = day.getFullYear()
      const month = day.getMonth() + 1
      const date = day.getDate()
      return year + '. ' + month + '. ' + date
    },
    calendar_focus () {
      const today = new Date()
      this.focus = today
    }
  },
  mounted () {
    this.readWeekAPI()
    this.calendar_focus()
  }
}
</script>
