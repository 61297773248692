var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","pa-0":""}},[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.windowWidth>960)?_c('v-col',{staticClass:"pa-0"},[_c('div',{staticClass:"pl-25 pb-6 pt-10",staticStyle:{"font-size":"26px","font-weight":"bold"}},[_vm._v(" 오늘 일정 ")])]):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"id":"schedule_col_1"}},[(_vm.windowWidth>960)?_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"elevation-0 text-center px-10",staticStyle:{"font-size":"25px"},attrs:{"headers":_vm.headers,"items":_vm.date,"loading":_vm.loading,"id":"schedule","hide-default-footer":"","elevation":0,"no-data-text":_vm.alertFlag == true? _vm.no_data_text_err : _vm.no_data_text,"loading-text":_vm.loading_text},scopedSlots:_vm._u([{key:"item.time",fn:function(ref){
var item = ref.item;
return [(_vm.time_format(item.start, item.end).allday != null)?_c('div',[_vm._v(" "+_vm._s(_vm.time_format(item.start, item.end).allday)+" ")]):_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap","align-items":"center","max-width":"180px","margin":"0 auto"}},[_c('div',[_vm._v(_vm._s(_vm.time_format(item.start, item.end).start_time))]),_c('div',[_vm._v("~")]),_c('div',[_vm._v(_vm._s(_vm.time_format(item.start, item.end).end_time))])])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.title))])]}},{key:"item.place",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.place))])]}},{key:"item.memo",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.memo))])]}}],null,false,1006399204)})],1):_vm._e(),(_vm.windowWidth<=960)?_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"elevation-0 text-center px-5",style:("font-size: 25px; min-height:"+_vm.windowHeight+"px;"),attrs:{"headers":_vm.headers,"items":_vm.date,"loading":_vm.loading,"id":"schedule","hide-default-footer":"","mobile-breakpoint":"960","no-data-text":_vm.alertFlag==true?_vm.no_data_text_err:_vm.no_data_text,"loading-text":_vm.loading_text},scopedSlots:_vm._u([{key:"item.time",fn:function(ref){
var item = ref.item;
return [(_vm.time_format(item.start, item.end).allday != null)?_c('div',{staticStyle:{"font-family":"NotoSansBold"}},[_vm._v(" "+_vm._s(_vm.time_format(item.start, item.end).allday)+" ")]):_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap","align-items":"center","max-width":"180px","justify-content":"flex-end"}},[_c('div',{staticStyle:{"font-family":"NotoSansBold"}},[_vm._v(_vm._s(_vm.time_format(item.start, item.end).start_time))]),_c('div',{staticStyle:{"font-family":"NotoSansBold"}},[_vm._v("~")]),_c('div',{staticStyle:{"font-family":"NotoSansBold"}},[_vm._v(_vm._s(_vm.time_format(item.start, item.end).end_time))])])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.title))])]}},{key:"item.place",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.place))])]}},{key:"item.memo",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.memo))])]}}],null,false,1062546586)})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }