<template>
    <v-alert 
        v-if="accessed"
        type="error"
        transition="slide-x-transition"
    >
        {{title}}
        <template #append="">
            <v-tooltip 
                bottom
            >
                <template #activator="{on, attrs}">
                    <v-btn 
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="page_reload()"
                    >
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </template>
                <span>새로고침</span>
            </v-tooltip>
        </template>
    </v-alert>
</template>

<script>
import Vue from 'vue'

export default {
    props: ['accessed'],
    data(){
        return{
            title: Vue.prototype.$serverAccessFailed,
        }
    },
    methods:{
        page_reload(){
            location.reload();
        }
    }
}
</script>

<style>

</style>