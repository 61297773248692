import Vue from 'vue'
import VueRouter from 'vue-router'
import Day from '../components/day/day.vue'
import Dashboard from '../components/dashboard/Dashboard.vue'
import Statistics from '../components/statistics/statistics.vue'
import Calendar from '../components/calendar/calendar.vue'
import Admin from '../components/admin/admin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root',
    component: Day
  },
  {
    path: '/day',
    name: 'day',
    component: Day
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: Statistics
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: Calendar
  }
]

const router = new VueRouter({
  routes
})

export default router
