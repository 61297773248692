<template>
    <v-btn
        v-if="!custom_btn_flag"
        color="#5a7fff"
        class="admin_top_card_modify_btn"
        @click="custom_btn_flag = !custom_btn_flag, emit_btn_flag(custom_btn_flag)"
    >수정</v-btn>
    <div
        v-else
        class="admin_top_card_cancel_btn"
    >
        <v-btn 
            icon
            style="color: #5a7fff !important; height: 100%;"
            @click="custom_btn_flag = !custom_btn_flag, emit_btn_flag(custom_btn_flag), emit_cancel_btn_flag('cancel')"
        >취소</v-btn>
        <v-btn
            color="#5a7fff"
            style="color: #ffffff !important; margin-left: 20px; width: 100px; height: 100%;"
            @click="custom_btn_flag = !custom_btn_flag, emit_btn_flag(custom_btn_flag), send_data_to_server()"
        >저장</v-btn>
    </div>
</template>

<script>
import Axios from 'axios'

export default {
    props:['btn_flag', 'send_server_data'],
    data(){
        return{
            custom_btn_flag: this.btn_flag,
            
        }
    },
    methods:{
        emit_btn_flag(child_data){
            this.$emit('child_btn_flag', child_data)
        },
        emit_cancel_btn_flag(child_data){
            this.$emit('child_cancel_btn_flag', child_data)
        },
        send_data_to_server(){
             Axios({
                method: 'post',
                url: this.$getBlueDogUrl() + '/setAdminConfig',
                data: this.send_server_data,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log("response : ",response)
            }).catch(function (error) {
                alert("서버 연결이 불안정합니다.")
                console.log("error : ",error)
            })
        }
    }
}
</script>

<style>

@media (max-width: 1350px) {
    .admin_top_card_modify_btn{
        right: 50px !important;
        top: 15px !important;
    }

    .admin_top_card_cancel_btn{
        right: 50px !important;
        top: 15px !important;
    }
}

.admin_top_card_modify_btn{
    position: absolute; 
    right: 40px; 
    top: 30px;
    font-size: 14px !important;  
    font-family: NotoSansBold; 
    color: #ffffff !important; 
    width: 100px; 
    height: 40px !important;
}

.admin_top_card_cancel_btn{
    display: flex;
    position: absolute; 
    right: 40px; 
    top: 30px;
    font-size: 14px !important;  
    font-family: NotoSansBold; 
    color: #ffffff !important; 
    height: 40px !important;
}
</style>