<template>
  <div
    :id="'for_timeline_space' + '_' + recalcCount + '_'+ data.index"
    style="display: relative;"
  ></div>
</template>

<script>
export default {
  props: ['data'],
  data () {
    return {
      recalcCount: this.makeRandomNum()
    }
  },
  methods: {
    onload () {
      const for_timeline_space = document.getElementById('for_timeline_space' + '_' + this.recalcCount + '_' + this.data.index)

      const container = document.createElement('div')
      container.id = 'container' + this.data.index
      container.style.display = 'relative'
      container.style.borderRadius = '15px'

      let barContainer = document.createElement('div')
      barContainer.classList.add('no-scrollbar')
      barContainer.id = 'barContainer' + this.data.index
      barContainer.style.width = '35vw'
      barContainer.style.borderRadius = '15px'
      barContainer.style.overflowY = 'hidden'
      barContainer.style.overflowX = 'auto'

      const wholeWidth = '50vw'

      let dynamic = document.createElement('div')
      dynamic.id = 'dynamic' + this.data.index
      dynamic.style.width = wholeWidth
      dynamic.style.height = '23px'

      barContainer.appendChild(dynamic)
      container.appendChild(barContainer)

      const scaleContainer = document.createElement('div')
      scaleContainer.classList.add('no-scrollbar')
      scaleContainer.id = 'scaleContainer'
      scaleContainer.style.width = '35vw'
      scaleContainer.style.height = '20px'
      scaleContainer.style.overflow = 'auto'

      const scaleDiv = document.createElement('div')
      scaleDiv.style.width = wholeWidth
      scaleDiv.style.display = 'flex'
      scaleDiv.style.alignItems = 'center'
      scaleDiv.style.justifyContent = 'space-between'

      const scale00 = document.createElement('div')
      scale00.innerText = '00'
      scale00.style.height = '20px'

      const scale12 = document.createElement('div')
      scale12.innerText = '12'
      scale12.style.height = '20px'

      const scale24 = document.createElement('div')
      scale24.innerText = '24'
      scale24.style.height = '20px'

      scaleDiv.appendChild(scale00)
      scaleDiv.appendChild(scale12)
      scaleDiv.appendChild(scale24)

      scaleContainer.appendChild(scaleDiv)

      container.appendChild(scaleContainer)

      if (for_timeline_space != null) {
        for_timeline_space.appendChild(container)
      }

      barContainer = document.getElementById('barContainer' + this.data.index)
      barContainer.classList.add('dragTestClass')
      dynamic = document.getElementById('dynamic' + this.data.index)

      this.setBar(dynamic, this.parseTime(dynamic, this.data))

      // this.addDragScroll(barContainer)
      
      barContainer.addEventListener('scroll', (event) => {scaleContainer.scrollTo(barContainer.scrollLeft, 0)})

      scaleContainer.addEventListener('wheel', (event) => {event.preventDefault()})
      scaleContainer.addEventListener('touchmove', (event) => {
        event.preventDefault()
        
      }, {passive: false})

      const containerWidth = this.viewportToPixels(barContainer.style.width)
      const barWidth = this.viewportToPixels(dynamic.style.width)
      let offset = barWidth - containerWidth
      offset /= 2
      barContainer.scrollTo(offset, 0) // 50% scroll

      this.emitChildToParent('dom_generated_counts', true)
    },

    transformScroll (event) {
      if (!event.deltaY) {
        return
      }

      event.currentTarget.scrollLeft += event.deltaY // + event.deltaX;
      // event.preventDefault();
    },

    parseTime (element, data) {
      const breakColor = '#ffeb3b'
      const workColor = '#03a9f4'
      let prevBreak
      const workTime = data.workTime

      const colorArray = []
      const barWidth = this.viewportToPixels(element.style.width)
      const timeScale = barWidth / (24 * 60)
      const date_before = new Date()
      // let date_before = new Date(workTime.start_time);
      const date_renew_start = new Date(date_before.getFullYear(),date_before.getMonth(),date_before.getDate(),0,0)
      const date_renew_end = new Date(date_before.getFullYear(),date_before.getMonth(),date_before.getDate(),23,59,59,999)

      // 일한 시간이 없다면
      // 1. 그 날의 start, end, color(Color 객체)를 구분한다.
      // 
      if (!workTime) {
        colorArray.push(
          this.newColor(
            new Date(date_renew_start), // start
            new Date(date_renew_end), // end
            'gray' // color
          )
        )
        colorArray.forEach((it) => {
          it.start = this.calcPixel(new Date(it.start), timeScale)
          it.end = this.calcPixel(new Date(it.end), timeScale)
        })
      } 
      // 일한 시간이 있다면
      else {
        const lastIndex = data.breakTime.length - 1
        if (lastIndex == -1) {
          colorArray.push(
            this.newColor(
              new Date(date_renew_start),
              new Date(workTime.start_time),
              'gray'
            )
          )
          colorArray.push(
            this.newColor(
              new Date(workTime.start_time),
              new Date(workTime.end_time),
              workColor
            )
          )
          colorArray.push(
            this.newColor(
              new Date(workTime.end_time),
              new Date(date_renew_end),
              'gray'
            )
          )
        }

        for (let i = 0; i <= lastIndex; i++) {
          const breakTime = data.breakTime[i]

          if (i == 0) {
            colorArray.push(
              this.newColor(
                new Date(date_renew_start),
                new Date(workTime.start_time),
                'gray'
              )
            )
            colorArray.push(
              this.newColor(
                new Date(workTime.start_time),
                new Date(breakTime.start_time),
                workColor
              )
            )
            // prevBreak = workTime;
          } 
          else {
            colorArray.push(
              this.newColor(
                new Date(prevBreak.start_time),
                new Date(prevBreak.end_time),
                breakColor
              )
            )
            colorArray.push(
              this.newColor(
                new Date(prevBreak.end_time),
                new Date(breakTime.start_time),
                workColor
              )
            )
          }

          prevBreak = breakTime

          if (i == lastIndex) {
            colorArray.push(
              this.newColor(
                new Date(breakTime.start_time),
                new Date(breakTime.end_time),
                breakColor
              )
            )

            colorArray.push(
              this.newColor(
                new Date(breakTime.end_time),
                new Date(workTime.end_time),
                workColor
              )
            )

            colorArray.push(
              this.newColor(
                new Date(workTime.end_time),
                new Date(date_renew_end),
                'gray'
              )
            )
          }
        }

        // this.test = JSON.parse(JSON.stringify(colorArray));

        colorArray.forEach((it) => {
          it.start = this.calcPixel(new Date(it.start), timeScale)
          it.end = this.calcPixel(new Date(it.end), timeScale)
        })
      }
      return colorArray
    },

    setBar (element, data) {
      element.innerHTML = ''
      for (let i = 0; i < data.length; i++) {
        const color = data[i]
        const chip = document.createElement('div')
        chip.style.display = 'inline-block'
        chip.style.height = '100%'
        chip.innerHTML = '&nbsp;'
        chip.style.width = `${color.end - color.start}px`
        chip.style.backgroundColor = color.color
        element.appendChild(chip)
      }
    },

    newColor (start, end, color) {
      return {
        start: start,
        end: end,
        color: color
      }
    },

    calcPixel (date, scale) {
      const minutes = date.getHours() * 60 + date.getMinutes()
      const percent = minutes * scale + ''
      const s = percent.split('.')
      let ret = ''
      ret += s[0]
      // console.log(ret)
      return ret
    },

    // vw, vh 값을 픽셀로 변경
    viewportToPixels (value) {
      var parts = value.match(/([0-9\.]+)(vh|vw|px)/)
      var q = Number(parts[1])
      if (parts.length < 3) {
        return value
      } else if (parts[2] == 'px') {
        return q
      }

      var side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
      return side * (q / 100)
    },

    addDragScroll (ele) {
      let pos = { top: 0, left: 0, x: 0, y: 0 }
      ele.style.cursor = 'grab'
      const mouseDownHandler = (e) => {
        ele.style.cursor = 'grabbing'
        ele.style.userSelect = 'none'

        pos = {
          left: ele.scrollLeft,
          top: ele.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY
        }

        document.addEventListener('mousemove', mouseMoveHandler)
        document.addEventListener('mouseup', mouseUpHandler)
      }

      const mouseMoveHandler = (e) => {
        ele.style.cursor = 'grabbing'
        // How far the mouse has been moved
        const dx = e.clientX - pos.x
        const dy = e.clientY - pos.y

        // Scroll the element
        ele.scrollLeft = pos.left - dx
        ele.scrollTop = pos.top - dy
      }

      const mouseUpHandler = () => {
        ele.style.cursor = 'grab'
        ele.style.removeProperty('user-select')

        document.removeEventListener('mousemove', mouseMoveHandler)
        document.removeEventListener('mouseup', mouseUpHandler)
      }

      // Attach the handler
      ele.addEventListener('mousedown', mouseDownHandler)
    },

    makeRandomNum(){
      return Math.floor(Math.random()*10000000000)
    },

    winResize () {
      const dynamic = document.getElementById('dynamic' + this.data.index)
      this.setBar(dynamic, this.parseTime(dynamic, this.data))
    }, 

    emitChildToParent(name, value){
      this.$emit(name, value)
    }
  },
  mounted: function () {
    this.onload()
    window.addEventListener('resize', this.winResize)
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.winResize);
  },
  watch: {
    data: {
      handler (after, before) {
        const div = document.getElementById('for_timeline_space' + '_' + this.recalcCount + '_' + before.index)
        this.recalcCount = this.makeRandomNum()
        div.id = 'for_timeline_space' + '_' + this.recalcCount + '_' + after.index
        div.innerHTML = ''
        this.onload()
      }
    }
  },
}
</script>

<style>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>
