<template>
  <div
    style="height: 100%;"
  >
    <v-row no-gutters style="height: 9%">
      <v-tabs
        v-model="tabs"
        left
        id="vtabs"
        height="100%"
        style="padding-bottom: 11px"
        :slider-size="2"
        active-class="font-title-dashBoardWeek"
        slider-color="#3b46ff"
        color="#3b46ff"
      >
        <v-tab href="#mobile-tabs-5-1" class="pa-0 font-subtitle">
          <div style="height: 100%">주간 일정</div>
        </v-tab>

        <div style="display: inline-block; width: 10px"></div>

        <v-tab href="#mobile-tabs-5-2" class="pa-0 font-subtitle">
          <div style="height: 100%">월간 일정</div>
        </v-tab>
      </v-tabs>
    </v-row>
    <v-row no-gutters style="height: 91%;">
      <v-card outlined style="width: 100%; height: 100%; overflow-y: auto;">
        <v-tabs-items v-model="tabs" touchless style="height: 100%;">
          <v-tab-item
            v-for="i in 2"
            :key="i"
            :value="'mobile-tabs-5-' + i"
            style="height: 100%;"
          >
            <v-data-table
              id="dashBoardWeekMonth"
              class="px-10"
              v-if="i == 1"
              :headers="headers"
              :items="date"
              :loading="loading"
              hide-default-footer
              hide-default-header
              sort-by="start"
              style="height: 100%;"
              :header-props="{
                divider: false,
              }"
              :no-data-text="error_flag==true?no_data_text_err:no_data_text"
            >
              <template v-slot:item.timeTitle="{ item }">
                <div
                  style="display: flex; flex-wrap: wrap; padding-top: 19px;"
                >
                  <div
                    style="display: flex; flex-wrap: wrap;"
                    v-if="yyyy_mm_dd(item.start) == yyyy_mm_dd(item.end)"
                  >
                    <div
                      class="font-title ma-0 mr-2 dashboard_week_time"
                      style="font-size: 16px; display: inline-block;"
                      >{{ yyyy_mm_dd(item.start) }}
                    </div>
                    <div
                      class="font-title ma-0 dashboard_week_time"
                      style="font-size: 16px; color: #7ba5ff"
                      >{{ _time_format(item.start, item.end) }}
                    </div>
                </div>
                <div
                  style="display: flex; flex-wrap: wrap;"
                  v-else
                >
                  <div
                    class="font-title ma-0 dashboard_week_time"
                    style="font-size: 16px; display: inline-block;"
                  >
                    {{yyyy_mm_dd(item.start)}}
                </div>
                  <div class="mx-1">
                    <div
                      style="font-family: NotoSancsBold; font-size: 16px;"
                    >
                      -
                    </div>
                  </div>
                  <div
                    class="font-title ma-0 dashboard_week_time"
                    style="font-size: 16px; color: #7ba5ff"
                  >
                    {{yyyy_mm_dd(item.end)}}
                  </div>
                </div>
              </div>
              <div style="padding-bottom: 20px; padding-top: 10px">
                <span
                  style="font-family: NotoSans; font-size: 15px; display: block;"
                >
                  {{ item.title }}
                </span>
              </div>
              </template>
              <template v-slot:item.start="{ item }">
              <!-- 정렬하기 위해서 넣어둔 값... display: none임 -->
                <span style="display: none">{{ item.start }}</span>
              </template>
            </v-data-table>
            <div
              v-if="i==2"
              style="height: 100%; overflow: hidden;"
            >
              <dash-board-month-com/>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-row>
  </div>
</template>

<style>
#vtabs > div {
  background-color: #f6f7fa;
}
#vtabs .v-tab:not(.v-tab--active) > div {
  color: black;
}

#dashBoardWeekMonth td {
  border-bottom: unset !important;
}

#dashBoardWeekMonth table tbody td {
  padding: 0;
}
.v-window-item--active{
  height: 100% !important;
}

@media (max-width: 967px) {
  .dashboard_week_time{
    font-size: 12px !important;
  }
}

</style>

<script>
import Vue from 'vue'
import axios from 'axios'
import DashBoardMonthCom from './DashBoardMonthCom.vue'

export default {
  name: 'App',
  components: { DashBoardMonthCom },
  data: () => ({
    focus: '',
    events: [],
    checkNumber: 0,
    date: [],
    week: [],
    month: [],
    loading: true,
    date_today: new Date(),
    headers: [
      { text: '', value: 'timeTitle', align: 'left' },
      { text: '', value: 'start' }
    ],
    no_data_text_err:Vue.prototype.$serverAccessFailedTableNoResultText,
    no_data_text:"이번주는 일정이 없습니다.",
    error_flag: false,
    tabs: null
  }),
  methods: {
    readWeekAPI () {
      const ymd = this.urlYmdFormat(this.date_today)
      this.loading = true
      const check = ++this.checkNumber
      axios.get(this.$getBlueDogUrl() + '/weekSchedule?ymd=' + ymd).then((response) => {
        this.loading = false
        if (check != this.checkNumber) {
          return
        }
        this.date = []
        for (let i = 0; i < response.data.length; i++) {
          const data = response.data[i]

          const item = {}
          item.title = data.name // data.name이 제목의 내용이다...
          item.start = data.start
          item.end = data.end
          item.memo = data.memo
          item.place = data.place

          this.date.push(item)
        }
      }).catch((error)=>{
        this.loading = false
        this.error_flag = true
      })
    },
    urlYmdFormat (date) {
      const date_when =
        date.getFullYear().toString() +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        date.getDate().toString().padStart(2, '0')
      return date_when
    },
    _time_format (start, end) {
      const result_s = this.calc_time(start)
      const result_e = this.calc_time(end)
      if (result_s == '오전 12:00' && result_e == '오전 12:00') {
        return '종일'
      }
      return this.calc_time(start) + ' - ' + this.calc_time(end)
    },
    calc_time (_time) {
      const today = new Date(_time)
      const apm = today.getHours() < 12 ? '오전' : '오후'
      let hour = (hour = today.getHours() % 12) ? hour : 12
      const minute = today.getMinutes().toString().padStart(2, '0')
      return apm + ' ' + hour + ':' + minute
    },
    yyyy_mm_dd (_time) {
      const day = new Date(_time)
      const year = day.getFullYear()
      const month = day.getMonth() + 1
      const date = day.getDate()
      return year + '. ' + month + '. ' + date
    },
    calendar_focus () {
      const today = new Date()
      this.focus = today
    }
  },
  mounted () {
    this.readWeekAPI()
    this.calendar_focus()
  }
}
</script>
