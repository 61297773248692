var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dash-board-live-guentae-div-wrapper",staticStyle:{"height":"100%","padding":"30px 25px 0 35px"}},[_c('v-text-field',{staticStyle:{"margin-bottom":"1%"},attrs:{"label":"직원 이름","single-line":"","filled":"","dense":"","rounded":"","hide-details":"","height":"10%"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-fade-transition',{attrs:{"leave-absolute":""}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"size":"24","color":"info","indeterminate":""}}):_c('v-img',{attrs:{"width":"24","height":"24","src":"/basic_icons/search-line.svg"}})],1)]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.windowWidth>1612)?_c('v-data-table',{staticStyle:{"height":"85%","overflow-y":"auto"},attrs:{"id":"liveGuentae","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"search":_vm.search,"hide-default-footer":"","hide-default-header":"","footer-props":{
      itemsPerPageOptions: [-1],
    },"no-data-text":_vm.no_data_text},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"display":"inline-block","vertical-align":"middle","position":"absolute","left":"10px","transform":"translateY(-35%)"}},[_c('img',{attrs:{"src":item.state}})]),_c('div',{staticStyle:{"display":"inline-block","vertical-align":"middle","position":"absolute","left":"80px","transform":"translateY(-25%)"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticStyle:{"display":"inline-block","vertical-align":"middle","position":"absolute","right":"10px","transform":"translateY(-25%)"}},[_vm._v(" "+_vm._s(item.endTime)+" ")])])]}}],null,false,1404070880)}):_vm._e(),(_vm.windowWidth<=1612 && _vm.windowWidth>600)?_c('v-data-table',{staticStyle:{"height":"75%","overflow-y":"auto"},attrs:{"id":"liveGuentae","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"search":_vm.search,"hide-default-footer":"","hide-default-header":"","footer-props":{
      itemsPerPageOptions: [-1],
    },"no-data-text":_vm.no_data_text},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"display":"inline-block","vertical-align":"middle","position":"absolute","left":"10px","transform":"translateY(-35%)"}},[_c('img',{attrs:{"src":item.state}})]),_c('div',{staticStyle:{"display":"inline-block","vertical-align":"middle","position":"absolute","left":"80px","transform":"translateY(-25%)"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticStyle:{"display":"inline-block","vertical-align":"middle","position":"absolute","right":"10px","transform":"translateY(-25%)"}},[_vm._v(" "+_vm._s(item.endTime)+" ")])])]}}],null,false,1404070880)}):_vm._e(),(_vm.windowWidth<=600)?_c('v-data-table',{staticStyle:{"height":"75%","overflow-y":"auto"},attrs:{"id":"liveGuentae","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"search":_vm.search,"hide-default-footer":"","hide-default-header":"","footer-props":{
      itemsPerPageOptions: [-1],
    },"no-data-text":_vm.no_data_text},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"display":"inline-block","vertical-align":"middle","position":"absolute","left":"10px","transform":"translateY(-35%)"}},[_c('img',{attrs:{"src":item.state}})]),_c('div',{staticStyle:{"display":"inline-block","vertical-align":"middle","position":"absolute","left":"80px","transform":"translateY(-25%)"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticStyle:{"display":"inline-block","vertical-align":"middle","position":"absolute","right":"10px","transform":"translateY(-25%)"}},[_vm._v(" "+_vm._s(item.endTime)+" ")])])]}}],null,false,1404070880)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }