<template>
  <v-app id="main_app">
    <v-main>
      <v-toolbar class="d-block d-md-none elevation-0" color="#f6f7fa">
        <img
          src="/asset_day/bluedog-logo.svg"
          style="width: 30px; height: 30px"
        />
        <v-spacer></v-spacer>
        <v-toolbar-title>메뉴</v-toolbar-title>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      </v-toolbar>

      <div id="navigation">
        <!-- PC용 -->
        <v-navigation-drawer
          v-if="windowWidth > 960"
          mobile-breakpoint="960"
          width="120px"
          floating
          dense
          app
        >
          <div
            style="text-align: center; padding: 50px 0 150px;"
          >
            <img
              src="/asset_day/bluedog-logo.svg"
            />
          </div>
          <router-link
            class="nav-item"
            style="display: block; padding: 40px; text-align: center; position: relative;"
            v-for="(link, index) in links"
            :key="link.link"
            :to="link.link"
          >
            <!-- ripple 애니메이션 영역 -->
            <div v-ripple class="center sq80 rounded-circle"></div>
            <template v-if="navbarActiveItem == index">
              <img :src="link.activeIcon" width="35px" height="35px" />
            </template>
            <template v-else>
              <img :src="link.normalIcon" width="35px" height="35px" />
            </template>
          </router-link>
          <!-- <div
            style="text-align: center;"
          >
            <v-tooltip right>
              <template v-slot:activator="{on:tooltip}">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="#3b46ff"
                  v-on="{...tooltip}"
                >
                  <v-icon dark>
                    mdi-cached
                  </v-icon>
                  
                </v-btn>
              </template>
              Google Calendar와 동기화를 시도합니다.
            </v-tooltip>
          </div> -->
        </v-navigation-drawer>

        <!-- 모바일용 -->
        <v-navigation-drawer
          v-if="drawer && windowWidth <= 960"
          v-model="drawer"
          absolute
          temporary
          right
        >
          <div style="height: 60px; text-align:right; padding: 13px;">
            <img src="/icon_page_state/ic-vt-close.svg"
              @click="drawer = false"
            />
          </div>
          <v-list>
            <v-list-item
              active-class="active-color"
              v-for="link in links"
              :key="link.link"
              :to="link.link"
              style="padding: 16px 0px 16px 32px"
            >
              <v-list-item-icon class="">
                <img :src="link.normalIcon" width="20px" height="20px" />
              </v-list-item-icon>
              <!-- <v-list-item-title> -->
              {{ link.name }}
              <!-- </v-list-item-title> -->
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </div>
      <router-view id="background_all"></router-view>
    </v-main>

    <v-card height="108px">
      <v-footer
        outlined
        tile
        absolute
        class="footer_parent"
        height="108px"
        style="background-color: #e4e6ed"
      >
        <v-col id="footer" cols="12" style="font-size: 15px">
          <strong>(주)모베란</strong> | 이용 방법, 문의 메일 moberan@moberan.com
        </v-col>
      </v-footer>
    </v-card>
    
  </v-app>
</template>

<style scoped>
#background_all {
  background: #f6f7fa;
}

.active-color {
  color: blue !important;
}

.nav-item::after {
  transition-delay: 0s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background-color: #d6e9ff;
  border-radius: 50%;
  opacity: 0;
  z-index: -10;
  content: "";
}

.router-link-active::after {
  transition-delay: 0.1s;
  opacity: 1;
  content: "";
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sq80 {
  width: 80px;
  height: 80px;
}

@media (min-width:960px) {
  #footer{
    padding-left: 220px;
  }
}

@media (max-width: 360px) {
  
}
</style>

<script>
export default {
  name: 'App',
  components: {},
  data: () => ({
    drawer: false,
    navbarActiveItem: null,
    windowWidth: window.innerWidth,
    links: [
      {
        link: '/Dashboard',
        name: '대시보드',
        normalIcon: '/icon_page_state/pie-chart-line.svg',
        activeIcon: '/icon_page_state/pie-chart-line-blue.svg'
      },
      {
        link: '/day',
        name: '일일 근태',
        normalIcon: '/icon_page_state/calendar-check-line.svg',
        activeIcon: '/icon_page_state/calendar-check-line-blue.svg'
      },
      {
        link: '/statistics',
        name: '통계',
        normalIcon: '/icon_page_state/line-chart-line.svg',
        activeIcon: '/icon_page_state/line-chart-line-blue.svg'
      },
      {
        link: '/calendar',
        name: '캘린더',
        normalIcon: '/icon_page_state/calendar-line.svg',
        activeIcon: '/icon_page_state/calendar-line-blue.svg'
      },
      {
        link: '/admin',
        name: '설정',
        normalIcon: '/icon_page_state/settings-4-line.svg',
        activeIcon: '/icon_page_state/settings-4-line-blue.svg'
      }
    ]
  }),
  methods: {
    setActiveNavItem (path) {
      if (path == '/') {
        this.navbarActiveItem = 1
      }
      for (let i = 0; i < this.links.length; i++) {
        if (this.links[i].link == path) {
          this.navbarActiveItem = i
          break
        }
      }
    },
    onResize () {
      this.windowWidth = window.innerWidth
    }
  },
  mounted () {
    this.setActiveNavItem(this.$route.path)
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    // route에 따라 navbarActiveItem 값 설정
    $route (to, from) {
      if (to.path != from.path) {
        console.log(`route ${from.path} -> ${to.path}`)
        this.setActiveNavItem(to.path)
      }
    },
  }
}
</script>
