<template>
    <div v-if="windowWidth>630" class="top-child-color-blocks" style="display: flex; flex-wrap: wrap; height: 100%;">
        <div class="top-child-bottom">
            <div class="top-child-bottom-block" style="background-color: #d50001;"></div>
            <div class="top-child-bottom-text">휴가</div>
        </div>
        <div class="top-child-bottom">
            <div class="top-child-bottom-block" style="background-color: #8e24aa;"></div>
            <div class="top-child-bottom-text">외근</div>
        </div>
        <div class="top-child-bottom">
            <div class="top-child-bottom-block" style="background-color: #4051b6;"></div>
            <div class="top-child-bottom-text">휴일 (평일 휴일)</div>
        </div>
        <div class="top-child-bottom">
            <div class="top-child-bottom-block" style="background-color: #f6bf26;"></div>
            <div class="top-child-bottom-text">업무일 (공휴일 업무일)</div>
        </div>
        <div class="top-child-bottom">
            <div class="top-child-bottom-block" style="background-color: #7986cb;"></div>
            <div class="top-child-bottom-text">기타</div>
        </div>
    </div>
    <div
        v-else class="top-child-color-blocks" style="display: flex; flex-wrap: wrap; height: 100%;">
        <div class="top-child-bottom">
            <div class="top-child-bottom-block-small-ver" style="background-color: #d50001;"></div>
            <div class="top-child-bottom-text-small-ver">휴가</div>
        </div>
        <div class="top-child-bottom">
            <div class="top-child-bottom-block-small-ver" style="background-color: #8e24aa;"></div>
            <div class="top-child-bottom-text-small-ver">외근</div>
        </div>
        <div class="top-child-bottom">
            <div class="top-child-bottom-block-small-ver" style="background-color: #4051b6;"></div>
            <div class="top-child-bottom-text-small-ver">휴일 (평일 휴일)</div>
        </div>
        <div class="top-child-bottom">
            <div class="top-child-bottom-block-small-ver" style="background-color: #f6bf26;"></div>
            <div class="top-child-bottom-text-small-ver">업무일 (공휴일 업무일)</div>
        </div>
        <div class="top-child-bottom">
            <div class="top-child-bottom-block-small-ver" style="background-color: #7986cb;"></div>
            <div class="top-child-bottom-text-small-ver">기타</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            windowWidth: window.innerWidth
        }
    },
    methods: {
        onResize(){
            this.windowWidth = window.innerWidth
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
}
</script>

<style>

.top-child-color-blocks{
  justify-content: flex-end;
}

.top-child-bottom{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  font-family: NotoSansBold;
  font-size: 15px;
  margin-right: 20px;
  justify-content: flex-end;
}

.top-child-bottom-block{
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 10px;
}


/* windowWidth 가 630보다 작을때 */
.top-child-bottom-block-small-ver{
  width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-right: 5px;
}

.top-child-bottom-text-small-ver{
    font-size: 11px !important;
}
</style>