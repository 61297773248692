import { render, staticRenderFns } from "./statisticsGanttYearExcelButton.vue?vue&type=template&id=a2e03ed6&"
import script from "./statisticsGanttYearExcelButton.vue?vue&type=script&lang=js&"
export * from "./statisticsGanttYearExcelButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
