<template>
  <statistics-gantt-year :title="yearGanttTitle"/>
</template>

<script>
import StatisticsGanttYear from './statisticsGanttYear.vue'

export default {
  components: {
    StatisticsGanttYear
  },
  props: ['title'],
  data () {
    return {
      yearGanttTitle: this.title
    }
  }
}
</script>

<style>

</style>
