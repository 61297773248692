<template>
  <v-app class="main-padding" style="height: 100%;">
    <alert-api
      :accessed="alert_flag">
    </alert-api>
    <v-row
      no-gutters
      style="flex: none; height: 50px;"
    >
      <v-col class="py-0">
        <div class="font-title">대시보드</div>
      </v-col>
    </v-row>
    <!-- 데스크탑 풀화면 타이틀 -->
    <v-row
      no-gutters
      v-if="windowWidth>1612"
      style="flex: none; height: 50px; align-items: center;"
    >
      <v-col 
        lg="9"
        cols="9"
        style="font-family:NotoSans; font-size: 20px; height: fit-content;"
      >근무현황</v-col>
      <v-col 
        lg="3"
        cols="3"
        style="font-family:NotoSans; font-size: 20px; height: fit-content;"
      >실시간 근태</v-col>
    </v-row>
    <!-- 데스크톱 조그만 화면 타이틀 -->
    <v-row
      no-gutters
      v-if="windowWidth<=1612 && windowWidth > 1229"
      style="flex: none; height: 5%;"
    >
    </v-row>
    <!-- 데스크톱 더 조그만 화면 타이틀 -->
    <v-row
      no-gutters
      v-if="windowWidth<=1229"
      style="display: none;"
    >
    </v-row>
<!-- 데스크탑 풀화면 -->
    <v-row 
      no-gutters
      v-if="windowWidth>1612"
      class="pt-0"
      style="height: 1000px;"
      >
      <v-col lg="9" cols="9" class="pl-0 py-0" style="height: 100%;">
        <v-container fluid style="height: 100%;" class="pa-0">
          <v-row no-gutters style="height: 45%;">
            <v-col style="height:100%;" lg="4" class="pt-0 pr-1">
              <v-card outlined style="height: 100%;">
                <day-donut-container
                  :loading="loading"
                  :items="dayChartData"
                  :updateTime="dayChartSyncTime"
                />
              </v-card>
            </v-col>
            <v-col style="height:100%;" lg="4" class="pt-0 pr-1">
              <v-card outlined style="height:100%;">
                <month-column
                  :loading="loading"
                  :data="monthChartData" 
                  :windowWidth="windowWidth"
                />
              </v-card>
            </v-col>
            <v-col style="height:100%;" lg="4" class="pt-0 pr-1">
              <v-card outlined class="upper-card pa-0" style="height:100%;">
                <div class="linear-activity" v-if="loading" style="margin-top: 20px; border-radius: 15px;">
                  <div class="indeterminate"></div>
                </div>
                <div style="height: 20%; font-size: 16px; font-family: NotoSansBold; padding-left: 44px; padding-top: 5%;">
                  {{date_today.getFullYear()}} 연 평균
                </div>
                <dash-board-year
                  :yeardata="yearChartData"/>
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters style="height: 55%;">
            <v-col style="height:100%;" lg="6" class="pb-0 pr-1">
              <dash-board-date />
            </v-col>
            <v-col style="height:100%;" lg="6" class="pb-0 pr-1">
              <dash-board-week-month />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col lg="3" cols="3" class="pl-0 pt-0" style="height: 100%;">
        <v-card outlined style="width: 100%; height: 100%;">
          <dash-board-live-guentae 
            :liveGuentae="liveGuentae"
            :loading="loading"
            :error="error"
            :windowWidth="windowWidth"
          />
        </v-card>
      </v-col>
    </v-row>
<!-- 데스크톱 조그만 화면 -->
    <div 
      v-if="windowWidth<=1612  && windowWidth > 1229"
      class="pt-0"
      style="height: 90vmin;">
      <v-row
        no-gutters
        style="height: 50%; margin-bottom: 10px;"
      >
        <v-col
          class="pt-0 px-1"
          md="4"
          style="height: 100%;"
        >
          <v-card outlined style="height: 100%;">
            <day-donut-container
              :loading="loading"
              :items="dayChartData"
              :updateTime="dayChartSyncTime"
            />
          </v-card>
        </v-col>
        <v-col 
          class="pt-0 px-1"
          md="4"
          style="height: 100%;"
        >
          <v-card outlined style="height:100%;">
            <month-column
              :loading="loading"
              :data="monthChartData" 
              :windowWidth="windowWidth"
            />
          </v-card>
        </v-col>
        <v-col 
          class="pt-0 px-1"
          md="4"
          style="height: 100%;"
        >
           <v-card outlined class="upper-card pa-0" style="height:100%;">
            <div
              style="
                height: 20%;
                padding-left: 30px;
                padding-top: 20px;
              "
            >
              <div class="linear-activity" v-if="loading" style="margin-top: 20px; border-radius: 15px;">
                <div class="indeterminate"></div>
              </div>
              <span style="font-size: 16px; font-family: NotoSansBold">
                {{date_today.getFullYear()}} 연 평균
              </span>
            </div>
            <dash-board-year
              :yeardata="yearChartData" 
              />
          </v-card>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        style="height: 50%;"
      >
        <v-col 
          class="pb-0 px-1"
          col="4"
          style="height: 100%;"
        >
          <v-card outlined style="width: 100%; height: 100%; overflow-y: hidden;">
            <dash-board-live-guentae 
              :liveGuentae="liveGuentae"
              :loading="loading"
              :error="error"
              :windowWidth="windowWidth"
            />
          </v-card>
        </v-col>
        <v-col 
          class="pb-0 px-1"
          col="4"
          style="height: 100%;"
        >
          <dash-board-date />
        </v-col>
        <v-col 
          class="pb-0 px-1"
          col="4"
          style="height: 100%;"
        >
          <dash-board-week-month />
        </v-col>
      </v-row>
    </div>
<!-- 데스크톱 좀 더 작은 화면 -->
    <div 
      v-if="windowWidth<=1229 && windowWidth>965"
      class="pt-0">
      <v-row
        no-gutters
        style="height: 50vh; margin-bottom: 10px;"
      >
        <v-col
          class="pt-0 px-1"
          
          md="6"
          sm="6"
          cols="6"
          style="height: 100%;"
        >
          <v-card outlined style="height: 100%;">
            <day-donut-container
              :loading="loading"
              :items="dayChartData"
              :updateTime="dayChartSyncTime"
            />
          </v-card>
        </v-col>
        <v-col 
          class="pt-0 px-1"
          
          md="6"
          sm="6"
          cols="6"
          
          style="height: 100%;"
        >
          <v-card outlined style="height:100%;">
            <month-column
              :loading="loading"
              :data="monthChartData" 
              :windowWidth="windowWidth"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        style="height: 50vh;"
      >
        <v-col 
          class="pb-0 px-1"
          md="6"
          sm="6"
          style="height: 100%;"
        >
          <v-card outlined style="width: 100%; height: 100%; overflow-y: hidden;">
            <dash-board-live-guentae 
              :liveGuentae="liveGuentae"
              :loading="loading"
              :error="error"
              :windowWidth="windowWidth"
            />
          </v-card>
        </v-col>
        <v-col
          class="pt-0 px-1"
          md="6"
          sm="6"
          style="height: 100%;"
        >
          <v-card outlined class="upper-card pa-0" style="height:100%;">
            <div
              style="
                height: 20%;
                padding-left: 30px;
                padding-top: 20px;
              "
            >
              <div class="linear-activity" v-if="loading" style="margin-top: 20px; border-radius: 15px;">
                <div class="indeterminate"></div>
              </div>
              <span style="font-size: 16px; font-family: NotoSansBold">
                {{date_today.getFullYear()}} 연 평균
              </span>
            </div>
            <dash-board-year
              :yeardata="yearChartData" 
              />
          </v-card>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        style="height: 50vh;"
      >
        <v-col 
          class="pb-0 px-1"
          md="6"
          sm="6"
          style="height: 100%;"
        >
          <dash-board-date />
        </v-col>
        <v-col 
          class="pb-0 px-1"
          md="6"
          sm="6"
          style="height: 100%;"
        >
          <dash-board-week-month />
        </v-col>
      </v-row>
    </div>
<!-- 모바일 큰 화면 -->
    <div 
      v-if=" windowWidth<=965 && windowWidth>670"
      class="pt-0">
      <v-row
        no-gutters
        style="height: 50vmin; margin-bottom: 10px;"
      >
        <v-col
          class="pt-0 px-1"
          md="6"
          sm="6"
          cols="6"
          style="height: 100%;"
        >
          <v-card outlined style="height: 100%; overflow-y: auto;">
            <day-donut-container
              :loading="loading"
              :items="dayChartData"
              :updateTime="dayChartSyncTime"
            />
          </v-card>
        </v-col>
        <v-col 
          class="pt-0 px-1"
          md="6"
          sm="6"
          cols="6"
          style="height: 100%;"
        >
          <v-card outlined style="height:100%;">
            <month-column
              :loading="loading"
              :data="monthChartData"
              :windowWidth="windowWidth"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        style="height: 50vmin;"
      >
        <v-col 
          class="pb-0 px-1"
          md="6"
          sm="6"
          style="height: 100%;"
        >
          <v-card outlined style="width: 100%; height: 100%; overflow-y: hidden;">
            <dash-board-live-guentae 
              :liveGuentae="liveGuentae"
              :loading="loading"
              :error="error"
              :windowWidth="windowWidth"
            />
          </v-card>
        </v-col>
        <v-col
          class="pt-0 px-1"
          md="6"
          sm="6"
          style="height: 100%;"
        >
           <v-card outlined class="upper-card pa-0" style="height:100%; overflow-y: auto;">
            <div
              style="
                height: 20%;
                padding-left: 30px;
                padding-top: 20px;
              "
            >
              <div class="linear-activity" v-if="loading" style="margin-top: 20px; border-radius: 15px;">
                <div class="indeterminate"></div>
              </div>
              <span style="font-size: 16px; font-family: NotoSansBold">
                {{date_today.getFullYear()}} 연 평균
              </span>
            </div>
            <dash-board-year
              :yeardata="yearChartData" 
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        style="height: 50vmin;"
      >
        <v-col 
          class="pb-0 px-1"
          md="6"
          sm="6"
          style="height: 100%;"
        >
          <dash-board-date />
        </v-col>
        <v-col 
          class="pb-0 px-1"
          md="6"
          sm="6"
          style="height: 100%;"
        >
          <dash-board-week-month />
        </v-col>
      </v-row>
    </div>
<!-- 모바일 작은 화면 -->
    <div 
      v-if=" windowWidth<=670"
      class="pt-0">
      <v-row
        no-gutters
        style="height: 70vmax; margin-bottom: 10px;"
      >
        <v-col
          class="pt-0 px-1"
          cols="12"
          style="height: 100%;"
        >
          <dash-board-mobile-tabs
            :day_chart_data="dayChartData"
            :day_chart_sync_time="dayChartSyncTime"
            :month_chart_data="monthChartData" 
            :window_width="windowWidth"
            :year_chart_data="yearChartData"
            :live_guentae="liveGuentae"
            :loading="loading"
            :error="error"
          />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        style="height: 70vmax;"
      >
        <v-col 
          class="pb-0 px-1"
          cols="12"
          style="height: 100%; "
        >
          <dash-board-date-week-month/>  
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>
<style>

canvas{
  width: 100%;
  height: 100%;
}

</style>
<style scoped>
.upper-card {
  width: 100%;
  padding: 20px 30px 20px 30px;
}


@media (max-width: 960px) {
  .main-padding{
    padding: 20px !important;
  }
}
</style>

<script>
import axios from 'axios'
import DayDonutContainer from './DayDonutContainer.vue'
import DashBoardDate from './DashBoardDate'
import DashBoardWeekMonth from './DashBoardWeekMonth'
import DashBoardYear from './DashBoardYear'
import MonthColumn from './MonthColumn.vue'
import DashBoardLiveGuentae from './DashBoardLiveGuentae'
import AlertApi from '../customapi/alertApi.vue'
import DashBoardDateWeekMonth from './DashBoardDateWeekMonth.vue'
import DashBoardDonutBarYearChart from './DashBoardDonutBarYearChart.vue'
import DashBoardMobileTabs from './DashBoardMobileTabs.vue'


export default {
  name: 'App',
  components: {
    DayDonutContainer,
    DashBoardDate,
    DashBoardWeekMonth,
    DashBoardYear,
    MonthColumn,
    DashBoardYear,
    DashBoardLiveGuentae,
    AlertApi,
    DashBoardDateWeekMonth,
    DashBoardDonutBarYearChart,
    DashBoardMobileTabs,
  },
  data() {
    return {
      date: [],
      week: [],
      month: [],
      loading: true,
      date_today: new Date(),
      headers: [{ text: '', value: 'timeTitle', align: 'left' }],
      tabs: null,
      dayChartSyncTime: new Date().getTime(),
      dayChartData: [],
      monthChartData: [],
      yearChartData: [],
      liveGuentae: null,
      error: false,
      alert_flag: false,
      windowWidth: window.innerWidth
    }
  },
  methods: {
    readDataAPI () {
      const ymd = this.urlYmdFormat(this.date_today)

      let targetDashboardDateDonutElementModel = 0
      let targetDashboardMonthElementModel = 0

      axios.get(this.$getBlueDogUrl() + '/daily4dashboard?when=' + ymd).then((response) => {
        this.loading = false;
        targetDashboardDateDonutElementModel = response.data.targetDashboardDateDonutElementModel
        targetDashboardMonthElementModel = response.data.targetDashboardMonthElementModel
        this.yearChartData = response.data.targetDashboardYearElementModel
        this.liveGuentae = response.data.todayLiveGuentae

        this.monthChartData.push({
          color: this.$getCSSVar('--color-work'),
          value: targetDashboardMonthElementModel.check_commute,
          label: '출근'
        })
        this.monthChartData.push({
          color: this.$getCSSVar('--color-late'),
          value: targetDashboardMonthElementModel.check_tardiness,
          label: '지각'
        })
        this.monthChartData.push({
          color: this.$getCSSVar('--color-overwork'),
          value: targetDashboardMonthElementModel.check_overwork,
          label: '초과근무'
        })

        this.dayChartData.push(
          {
            color: this.$getCSSVar('--color-work'),
            value: targetDashboardDateDonutElementModel.check_commute,
            label: '출근'
          },
          {
            color: this.$getCSSVar('--color-rest'),
            value: targetDashboardDateDonutElementModel.check_noseat,
            label: '자리비움'
          },
          {
            color: this.$getCSSVar('--color-late'),
            value: targetDashboardDateDonutElementModel.check_tardiness,
            label: '지각'
          },
          {
            color: this.$getCSSVar('--color-overwork'),
            value: targetDashboardDateDonutElementModel.check_overwork,
            label: '초과근무'
          }
        )
      }).catch((error)=>{
        this.loading = false;
        this.error = true;
        this.alert_flag = true;
      })
    },
    urlYmdFormat (date) {
      const date_when =
        date.getFullYear().toString() +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        date.getDate().toString().padStart(2, '0')
      return date_when
    },
    _time_format (start, end) {
      return this.calc_time(start) + ' - ' + this.calc_time(end)
    },
    calc_time (_time) {
      const today = new Date(_time)
      const apm = today.getHours() < 12 ? '오전' : '오후'
      let hour = (hour = today.getHours() % 12) ? hour : 12
      const minute = today.getMinutes().toString().padStart(2, '0')
      return apm + ' ' + hour + ':' + minute
    },
    onResize(){
      this.windowWidth = window.innerWidth
    }
  },
  mounted () {
    scrollTo(0,0)
    window.addEventListener('resize', this.onResize)
    this.readDataAPI()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>
