<template>
    <v-card
        outlined
        class="d-flex"
        style="height: 88%;"
    >
        <div v-if="accessed" class="admin_top_container" >
            <div class="admin_top_child" >
                <div class="admin_top_child_item_comp">
                    <admin-top-card-time-control @child-data="data_from_child_worktime" :subtitle="worktime_subtitle" :item="data_from_server.work_time" :modify_flag="modify_btn_flag"/>
                </div>
            </div>
            <div class="admin_top_child" >
                <div class="admin_top_child_item_comp">
                    <admin-top-card-time-control @child-data="data_from_child_coretime" :subtitle="coretime_subtitle" :item="data_from_server.core_time" :modify_flag="modify_btn_flag"/>
                </div>
                <div class="admin_text_labels" style="inline-block">
                    <div style="display: inline-block;">이시간 이후 출입시</div>
                    <div style="display: inline-block;">지각으로 표시 됩니다.</div>
                </div>
            </div>
            <div class="admin_top_child" >
                <div class="admin_top_child_item_comp">
                    <admin-top-card-time-control @child-data="data_from_child_lunchtime" :subtitle="lunchtime_subtitle" :item="data_from_server.lunch_time" :modify_flag="modify_btn_flag"/>
                </div>
                <div 
                    class="admin_top_child_item_checkbox px-0"
                    fluid
                >
                    <div class="admin_top_child_item_check_alert_label admin_text_labels">체크시, 근무 시간에서 제외 됩니다.</div>
                </div>
            </div>
            <div class="admin_top_child" >
                <div class="admin_top_child_item_comp">
                    <admin-top-card-time-control @child-data="data_from_child_dinnertime" :subtitle="dinnertime_subtitle" :item="data_from_server.dinner_time" :modify_flag="modify_btn_flag"/>
                </div>
                <div 
                    class="admin_top_child_item_checkbox px-0"
                    fluid
                >
                    <div class="admin_top_child_item_check_alert_label admin_text_labels">체크시, 근무 시간에서 제외 됩니다.</div>
                </div>
            </div>
            
            <admin-top-modify-button @child_btn_flag="data_from_modify_button_child" @child_cancel_btn_flag="data_from_cancel_button_child" :send_server_data="data_from_server" :btn_flag="modify_btn_flag" />
        </div>
        <div 
            v-else
            style="width: 100%; height: 258px;" 
        >
            <div
                style="width: fit-content; margin: 0 auto; padding-top: 30px; color: rgba(0, 0, 0, 0.38);"
            >
                관리자 로그인시 확인 가능합니다.
            </div>
        </div>
    </v-card>
</template>

<script>
import Axios from 'axios'
import adminTopCardTimeControl from './adminTopCardTimeControl.vue'
import AdminTopModifyButton from './adminTopModifyButton.vue';

export default {
  components: { adminTopCardTimeControl, AdminTopModifyButton },
  props:["accessed"],
    data() {
        return{
            label: "반영 여부",
            lunch_flag: false,
            dinner_flag: false,
            modify_btn_flag: false,
            worktime_subtitle: "근무 시간 설정",
            coretime_subtitle: "코어 타임 설정",
            lunchtime_subtitle: "점심 시간 설정",
            dinnertime_subtitle: "저녁 시간 설정",
            data_from_server:{
                work_time:{},
                core_time:{},
                lunch_time:{},
                dinner_time:{}
            },
            data_from_server_cancel_value: null,
            upadte_flag: true,
        }
    },
    methods: {
        data_from_modify_button_child(value){
            this.modify_btn_flag = value
        },
        data_from_cancel_button_child(value){
            if(value == 'cancel'){
                this.data_from_server = JSON.parse(JSON.stringify(this.data_from_server_cancel_value))
            }
        },
        data_from_child_worktime(value){
            this.copy_server_data_from_child(this.data_from_server.work_time, value)
        },
        data_from_child_coretime(value){
            this.copy_server_data_from_child(this.data_from_server.core_time, value)
        },
        data_from_child_lunchtime(value){
            this.copy_server_data_from_child(this.data_from_server.lunch_time, value)
        },
        data_from_child_dinnertime(value){
            this.copy_server_data_from_child(this.data_from_server.dinner_time, value)
        },
        copy_server_data_from_child(jsonA, jsonB){
            jsonA.startOpt = jsonB.start_time_option
            jsonA.startHour = jsonB.start_time_hour
            jsonA.startMin = jsonB.start_time_minute
            jsonA.endOpt = jsonB.end_time_option
            jsonA.endHour = jsonB.end_time_hour
            jsonA.endMin = jsonB.end_time_minute
            jsonA.useFlag = jsonB.use_flag
        },
        emit_to_parent(name, value){
            this.$emit(name, value)
        },
        object_null_or_not(data){

            var flag = true;

            Object.keys(data).forEach((name)=>{
                if(data[name] != null && typeof data[name] == "objects" && !Object.keys(data[name]).length){
                    flag = false
                }
            })

            return flag;
        }
    },
    updated(){
        if(this.upadte_flag){
            Axios.all([
                Axios.get(this.$getBlueDogUrl()+'/getAdminConfig')
            ])
            .then(Axios.spread((obj1)=>{
                this.data_from_server = {
                    work_time:{},
                    core_time:{},
                    lunch_time:{},
                    dinner_time:{}
                }
                for(let key in obj1.data){
                    switch(key){
                        case "workTime":
                            this.data_from_server.work_time=obj1.data[key]
                            break;
                        case "coreTime":
                            this.data_from_server.core_time=obj1.data[key]
                            break;
                        case "lunchTime":
                            this.data_from_server.lunch_time=obj1.data[key]
                            this.lunch_flag = JSON.parse(this.data_from_server.lunch_time.useFlag.toLowerCase()) 
                            break;
                        case "dinnerTime":
                            this.data_from_server.dinner_time=obj1.data[key]
                            this.dinner_flag = JSON.parse(this.data_from_server.dinner_time.useFlag.toLowerCase()) 
                            break;
                        default:
                            console.log("getAdminConfig ERROR");
                            break;
                    }
                }

                this.data_from_server_cancel_value = JSON.parse(JSON.stringify(this.data_from_server))
            })).catch(function(error){
                alert("서버 연결이 불안정합니다.")
            })
            this.upadte_flag = false
        }
    },
    mounted() {
        Axios.all([
                Axios.get(this.$getBlueDogUrl()+'/getAdminConfig')
            ])
            .then(Axios.spread((obj1)=>{
                this.data_from_server = {
                    work_time:{},
                    core_time:{},
                    lunch_time:{},
                    dinner_time:{}
                }
                for(let key in obj1.data){
                    switch(key){
                        case "workTime":
                            this.data_from_server.work_time=obj1.data[key]
                            break;
                        case "coreTime":
                            this.data_from_server.core_time=obj1.data[key]
                            break;
                        case "lunchTime":
                            this.data_from_server.lunch_time=obj1.data[key]
                            this.lunch_flag = JSON.parse(this.data_from_server.lunch_time.useFlag.toLowerCase()) 
                            break;
                        case "dinnerTime":
                            this.data_from_server.dinner_time=obj1.data[key]
                            this.dinner_flag = JSON.parse(this.data_from_server.dinner_time.useFlag.toLowerCase()) 
                            break;
                        default:
                            console.log("getAdminConfig ERROR");
                            break;
                    }
                }

                this.data_from_server_cancel_value = JSON.parse(JSON.stringify(this.data_from_server))
            })).catch(function(error){
                alert("서버 연결이 불안정합니다.")
            })
    },
    watch: {
        data_from_cancel_button_child:{
            handler(){
                this.data_from_server = JSON.parse(JSON.stringify(this.data_from_server_cancel_value))

            }
        }
    }
     
}
</script>

<style>

@media (max-width: 1350px) {

    .admin_top_container{
        padding-left: 45px !important;
        padding-top: 65px !important;
    }

    .admin_top_child{
        display: block !important;
    }

    .admin_top_child_item_comp{
        display: block;
    }    
}

@media(max-width: 441px){
    .admin_text_labels{
        font-size: 10px;
    }
}

.admin_text_labels{
    transform: translateY(-2px);
}

.admin_top_container{
    width: 100%;
    height: 100%;
    padding-top: 60px;
    padding-left: 71px;
}
.admin_top_child{
    display: flex;
    align-items: center;
    width: fit-content;
    padding-bottom: 35px;
}

.admin_top_child_item_comp{
    display: inline-block;
}

.admin_top_child_item_checkbox{
    display: inline-block;
}

.admin_top_child_item_checkbox > div:first-child {
    display: inline-block;
}

.admin_top_child_item_check_alert_label{
    display: inline-block;
}

.admin_top_child_item_checkbox_label{
    color: #3c3c3c;
    font-size: 15px;
    font-weight: bold;
}
.admin_top_card_modify_btn{
    position: absolute; 
    right: 40px;
    top: 30px; 
    font-size: 14px !important;  
    font-family: NotoSansBold; 
    color: #ffffff !important; 
    width: 100px; 
    height: 40px !important;
}

.v-input--selection-controls{
    margin: 0;
    padding: 0;
}
</style>

<style scoped>
.child_title{
    font-family: NotoSansBold;
}

.time_div{
    display: flex;
    margin-left: 64px;
}
</style>
