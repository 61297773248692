<template>
  <statistics-guentae-month :title="monthGuentae" />
</template>

<script>
import StatisticsGuentaeMonth from './statisticsGuentaeMonth.vue'

export default {
  components: {
    StatisticsGuentaeMonth
  },
  props: ['title'],
  data () {
    return {
      monthGuentae: this.title
    }
  }
}
</script>

<style>
</style>
