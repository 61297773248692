<template>
  <div class="pa-5" style="height: 100%;">
    <div class="linear-activity" v-if="loading" style="border-radius: 15px;">
      <div class="indeterminate"></div>
    </div>
    <div style="height: 10%;" class="font-innertitle">{{ millis2dayChartDay(updateTime) }}</div>
    <!-- <div style="width: 9vw; height:18vh; margin:auto; margin-top:25px;"> -->
    <div style="width: 60%; height: 50%; margin: 0 auto;">
      <day-donut :data="items"/>
    </div>
    <!-- 출근, 자리비움, 지각, 초과근무 내용 표시 -->
    <div style="height: 40%; display: flex; flex-flow: column; justify-content: space-around;">
      <div
        v-for="(item, index) in items"
        :key="index"
      >
        <div
          style="
            display: inline-block;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            backgroundColor: item.color;
            margin-right: 5px;"
          :style="`backgroundColor:`+ item.color+`;`"
        ></div>
        <div class="day-chart-legend-label">{{ item.label }}</div>
        <div class="day-chart-legend-value">{{ item.value }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.day-chart-legend-label {
  display: inline-block;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-right: 4px;
}
.day-chart-legend-value {
  display: inline-block;
  font-family: NotoSansBold;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
</style>

<script>
import DayDonut from './DayDonut.vue'

const WEEK_DAY = [
  '일요일',
  '월요일',
  '화요일',
  '수요일',
  '목요일',
  '금요일',
  '토요일'
]

export default {
  name: 'day-pie',
  props: ['items', 'updateTime', 'loading'],
  components: {
    DayDonut
  },
  data () {
    return {}
  },
  methods: {
    millis2dayChartDay (millis) {
      const date = new Date(millis)
      let month = date.getMonth() + 1
      month += ''
      month.padStart(2, '0')
      let day = date.getDate()
      day += ''
      day.padStart(2, '0')
      const weekday = WEEK_DAY[date.getDay()]
      let hour = date.getHours()
      const isDay = hour >= 12
      const p = isDay ? '오후' : '오전'
      if (isDay) {
        hour -= 12
      }
      hour += ''
      hour.padStart(2, '0')
      let minute = date.getMinutes()
      minute += ''
      minute.padStart(2, '0')

      const str = `${month}.${day} ${weekday} ${p} ${hour}:${minute}`
      return str
    }
  },
  watch: {
    loading: {
      handler(){
        console.log(this.loading)
      }
    }
  },
}
</script>
