<template>
  <div class="pa-5" style="height: 100%;">
    <div class="linear-activity" v-if="loading" style="border-radius: 15px;">
      <div class="indeterminate"></div>
    </div>
    <div style="height: 100%; display: flex; flex-flow: column; justify-content: space-between;">
      <div style="height: 7%; font-family: NotoSansBold;">월 바 차트</div>
      <div class="monthBarChart" style="height: 93%">
          <month-bar :data="data"/>
      </div>
    </div>
  </div>
</template>

<script>
import About from '../../views/About.vue'
import MonthBar from './MonthBar.vue'

export default {
  props: ['data', 'windowWidth', 'loading'],
  components: {
    MonthBar,
    About
  },
  data () {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style>
.monthBarChart > div{
  width: 100%;
  height: 100%;
}

@media (max-width: 660px) {
  .monthBarChart > div{
    width: 80vw !important;
  } 
}
</style>
