<template>
  <v-card outlined style="width: 100%; height: 750px overflow: auto;" class="pl-10 pr-10">
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      hide-default-footer
      style="width: 100%"
      class="statistics_table"
      :no-data-text="error_flag==true?no_data_text:''"
    >
      <template v-slot:item.worktimebar="{ item }">
        <div style="height: 100px">
          <div style="padding-top: 30px;">
            <v-progress-linear
              :value="item.worktimebar"
              height="27"
              rounded
              color=""
              class="worktimeProgress"
              v-if="item.worktimebar<83.2"
            />
            <v-progress-linear
              :value="item.worktimebar"
              height="27"
              rounded
              color=""
              class="worktimeProgress_active"
              v-else
            />
          </div>
          <div class="worktime_container_month" style="width: 100%; height: 20px; position: relative; font-family: NotoSans; font-size: 15px; color: #afafaf; padding-top: 9px;">
            <div style="position: absolute; display: inline-block; left: 0px;">0h</div>
            <div class="worktime_container_overtime_check" :style="`position: absolute; display: inline-block; right: 16.8%;`">174h</div>
            <div style="position: absolute; display: inline-block; right: 0px;">209h</div>
          </div>
        </div>
      </template>
      <template v-slot:item.worktimetotal="{item}">
        <div>{{item.worktimetotal+" "+"("+item.compare_last_week+")"}}</div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
export default {
  props: ['this_month', 'showDeleted'],
  data () {
    return {
      items: [],
      loading: true,
      date: new Date(this.this_month),
      headers: [
        {
          text: '이름',
          value: 'name',
          align: 'center',
          width: '15%'
        },
        {
          text: '월간 근무 시간',
          value: 'worktimebar',
          align: 'center',
          width: '45%'
        },
        {
          text: '근무 시간(지난달 비교)',
          value: 'worktimetotal',
          align: 'center',
          width: '20%'
        },
        {
          text: '초과 근무',
          value: 'worktimeover',
          align: 'center',
          width: '15%'
        }
      ],
      no_data_text: Vue.prototype.$serverAccessFailedTableNoResultText,
      error_flag: false,
      dom_updated_flag: false,
    }
  },
  methods: {
    calc_time_unit (time) {
      return parseInt(time / 60) + 'h ' + time % 60 + 'm'
    },
    change_status () {
      const el = document.querySelector('div.worktimeProgress')
      el.className = 'v-progress-linear worktimeProgress_active v-progress-linear--rounded theme--light'
    },
    readDataAPI () {
      const year = this.date.getFullYear()
      const month = this.date.getMonth()

      const this_month_start = this.date_parsing(new Date(year, month, 1))

      let this_month_end = this.date_parsing(new Date(year, month + 1, 0))

      const today = new Date()

      if (today.getMonth() + 1 + '' === this_month_end.substr(4, 2)) {
        this_month_end = this.date_parsing(new Date(year, month, today.getDate()))
      }

      const last_month_start = this.date_parsing(new Date(year, month - 1, 1))

      const last_month_end = this.date_parsing(new Date(year, month, 0))

      axios
        .all([
          axios.get(
            this.$getBlueDogUrl() + '/checkTimeBarMonth?when1=' + last_month_start + '&when2=' + last_month_end  + '&showDeleted=' + this.showDeleted
          ),
          axios.get(
            this.$getBlueDogUrl() + '/checkTimeBarMonth?when1=' + this_month_start + '&when2=' + this_month_end  + '&showDeleted=' + this.showDeleted
          )
        ])
        .then(
          axios.spread((obj1, obj2) => {
            this.items = []

            // 이름과 일한 시간을 비교하기 위해
            // 해당 값들을 객체로 가지고 있는 배열(이름, [일한시간1, 일한시간2...])
            const obj1_item_array = []

            for (let i = 0; i < obj1.data.length; i++) {
              const obj1_object = {}

              obj1_object.name = obj1.data[i].name
              obj1_object.worktimetotal = obj1.data[i].work

              obj1_item_array.push(obj1_object)
            }

            this.loading = false

            for (let i = 0; i < obj2.data.length; i++) {
              const item = {}

              item.name = obj2.data[i].name
              item.worktimetotal = obj2.data[i].work

              for (let i = 0; i < obj1_item_array.length; i++) {
                // 저번주에 일한 사람과 이번주에 일한 사람의 시간을 비교해야 함으로
                // 이름이 같다면 이라는 조건문을 넣어두었다.
                if (item.name === obj1_item_array[i].name) {
                  item.compare_last_week = item.worktimetotal - obj1_item_array[i].worktimetotal
                }
              }

              // 근무 시간 표시하는 progress
              if (item.worktimetotal > 12540) {
                item.worktimebar = 100
              } else {
                item.worktimebar = item.worktimetotal * (100 / 12540)
              }

              if (item.worktimetotal >= 10440) {
                item.worktimeover = this.calc_time_unit(item.worktimetotal - 10440)
              } else {
                item.worktimeover = '0h 0m'
              }

              item.worktimetotal = this.calc_time_unit(item.worktimetotal)
              item.compare_last_week = this.calc_time_unit(item.compare_last_week)

              this.items.push(item)
            }

          })
        ).catch((error)=>{
          this.loading = false
          this.error_flag = true
          this.child_to_parent(this.error_flag)
        })
    },
    child_to_parent(child_data){
      this.$emit('error_flag', child_data)
    },
    calc_work_time (arr) {
      let sum = 0
      for (let i = 0; i < arr.length; i++) {
        sum += arr[i]
      }
      sum = sum / 60000 // 시간을 구하기 위해 시간만큼 나눔

      return sum.toFixed(1)
    },
    date_parsing (date) {
      const result =
        date.getFullYear().toString() +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        date.getDate().toString().padStart(2, '0')
      return result
    },
    onResize_timebar_container(){
      let worktime_container_month = document.querySelector('.worktime_container_month');
      let worktime_container_overtime_check = document.querySelectorAll('.worktime_container_overtime_check');

      let scailing_width = Math.round(worktime_container_month.offsetWidth*0.168)+'px'

      worktime_container_overtime_check.forEach(x=>{x.style.right=scailing_width})
    }
  },
  mounted () {
    this.readDataAPI()
    // window.addEventListener('resize', this.onResize_timebar_container)
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.onResize_timebar_container)
  },
  watch: {
    this_month: {
      handler () {
        console.log(this.this_month)
        this.date = new Date(this.this_month)
        this.loading = true
        this.readDataAPI()
      }
    },
    showDeleted: {
      handler () {
        this.loading = true
        this.readDataAPI()
      }
    },
  }
}
</script>

<style>
@media (max-width: 450px) {
  .worktime_container_month{
    font-size: 11px !important;
  }
}
</style>
